import { CurrencyCode } from "@core";

// Mapping of currency ISO codes to their respective symbols
const currencySymbols: { [key in keyof typeof CurrencyCode]?: string } = {
  USD: "$",
  EUR: "€",
  DOP: "RD$",
  // Add more mappings as needed
};

export interface FormatOptions {
  locale?: string;
  precision?: number;
  useGrouping?: boolean;
}

/**
 * Formats a number according to the specified options.
 *
 * @param {number} value - The number to format.
 * @param {FormatOptions} options - Formatting options.
 * @returns {string} The formatted number.
 */
const number = (value: number, options: FormatOptions = {}): string => {
  const { precision, locale = "en-US", useGrouping = true } = options;

  return new Intl.NumberFormat(locale, {
    useGrouping,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(value);
};

export interface CurrencyFormatOptions extends FormatOptions {
  currencyIso?: keyof typeof CurrencyCode;
  symbolDisplay?: "code" | "name" | "symbol";
}

/**
 * Formats a number as a currency using the specified options.
 *
 * @param {number} value - The number to format.
 * @param {CurrencyFormatOptions} options - Formatting options.
 * @returns {string} The formatted currency.
 */
const currency = (
  value: number,
  options: CurrencyFormatOptions = {},
): string => {
  const {
    precision = 2,
    locale = "en-US",
    useGrouping = true,
    currencyIso = "USD",
    symbolDisplay = "symbol",
  } = options;

  // Format the currency using Intl.NumberFormat
  let formattedValue = new Intl.NumberFormat(locale, {
    useGrouping,
    style: "currency",
    currency: currencyIso,
    currencyDisplay: symbolDisplay,
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(value);

  // Replace the default currency symbol with the custom symbol if necessary
  if (symbolDisplay === "symbol" && currencySymbols[currencyIso]) {
    const defaultSymbol = new Intl.NumberFormat(locale, {
      style: "currency",
      currency: currencyIso,
      currencyDisplay: "symbol",
    })
      .formatToParts(0)
      .find((part) => part.type === "currency")?.value;

    if (defaultSymbol) {
      formattedValue = formattedValue.replace(
        defaultSymbol,
        currencySymbols[currencyIso]!,
      );
    }
  }

  return formattedValue;
};

/**
 * Formats a number as a percentage using the specified options.
 *
 * @param {number} value - The number to format (in decimal form, e.g., 0.1 for 10%).
 * @param {FormatOptions} options - Formatting options.
 * @returns {string} The formatted percentage.
 */
const percentage = (value: number, options: FormatOptions = {}): string => {
  const { precision = 0, locale = "en-US", useGrouping = true } = options;

  return new Intl.NumberFormat(locale, {
    useGrouping,
    style: "percent",
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  }).format(value / 100);
};

/**
 * Gets the currency symbol for a given currency ISO code.
 *
 * @param {keyof typeof CurrencyCode} currencyIso - The ISO 4217 currency code.
 * @returns {string} The currency symbol or the ISO code if no symbol is found.
 */
const getCurrencySymbol = (currencyIso: keyof typeof CurrencyCode): string => {
  return currencySymbols[currencyIso] || currencyIso;
};

/**
 * Formats a decimal number to a specified precision.
 *
 * @param {number} value - The number to format.
 * @param {number} [precision=2] - The number of decimal places.
 * @returns {string} The formatted number as a string.
 */
const decimal = (value: number, precision: number = 2): string => {
  return value.toFixed(precision);
};

export const FormatterUtils = {
  number,
  decimal,
  currency,
  percentage,
  getCurrencySymbol,
};
