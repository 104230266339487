import React from "react";
import { sort } from "radash";
import { useGlobalQuery } from "@hooks";
import { useNavigate } from "react-router-dom";
import { EmptyMessage, FiltersDrawer } from "@components";
import { Datejs, ArrayUtils, EventUtils } from "@nubeteck/utils";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { useLedgerQuery } from "../../hooks";
import { IExchangeRates } from "../../interfaces";
import { GeneralLedgerRoutes, GeneralLedgerRouteNames } from "../../routes";

const AREA_FILTER_DEFAULT = "fcMonedasTasasCambiosBuscar";

const CurrencyTab = () => {
  const navigate = useNavigate();

  const { data: filters } = useGlobalQuery(
    "Filters",
    "getByArea",
    AREA_FILTER_DEFAULT,
  );
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();

  const { refetch, data: exchangeRates } = useLedgerQuery(
    "ExchangeRates",
    "getAllByFilter",
    filterSelected,
    { enabled: !!filterSelected },
  );

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  const columns: IDataTableColumnsProps<IExchangeRates>[] = [
    {
      sortable: true,
      field: "PrecioCompra",
      header: "Precio de compra",
    },
    {
      sortable: true,
      field: "PrecioVenta",
      header: "Precio de venta",
    },
    {
      filter: true,
      sortable: true,
      field: "Fecha",
      header: "Fecha",
      dataType: "date",
      filterOperator: FilterOperator.AND,
      filterMatchMode: FilterMatchMode.DATE_IS,
      body: (rowData) => (
        <span title={Datejs(rowData.Fecha).format("LL")}>
          {Datejs(rowData.Fecha).format("DD/MM/YYYY")}
        </span>
      ),
    },
  ];

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay tasas de cambio" />;
  }, []);

  const value = React.useMemo(() => {
    return (
      exchangeRates?.map((rate) => ({
        ...rate,
        Fecha: new Date(rate.Fecha),
      })) ?? []
    );
  }, [exchangeRates]);

  const generateDetailsLink = React.useCallback((monedaId: string) => {
    const basePath =
      GeneralLedgerRoutes[GeneralLedgerRouteNames.DASHBOARD_PAGE].template();

    const currenciesPath =
      GeneralLedgerRoutes[GeneralLedgerRouteNames.CURRENCY_PAGE].template();

    const currencyDetailPath = GeneralLedgerRoutes[
      GeneralLedgerRouteNames.CURRENCY_DETAIL_PAGE
    ].create({ id: `${monedaId}` });

    return `${basePath}${currenciesPath}${currencyDetailPath}`;
  }, []);

  return (
    <div>
      <CardTable<IExchangeRates>
        type="data"
        removableSort
        resizableColumns
        headActions={[]}
        columns={columns}
        columnResizeMode="expand"
        onRefresh={() => refetch()}
        valueFilter={filterSelected}
        filterOptions={filtersOptions}
        emptyMessage={renderEmptyMessage()}
        value={sort(value, (item) => item.Fecha.getTime(), true)}
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        tableActions={(rowData) => [
          {
            label: "Detalle",
            icon: "list-details",
            iconClassName: "text-secondary",
            onClick: () => navigate(generateDetailsLink(`${rowData.MonedaId}`)),
          },
        ]}
      />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
    </div>
  );
};

export default CurrencyTab;
