import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import { IMovimientosBancarios } from "../interfaces";

export class MovimientosBancariosApi extends BaseApi {
  protected static api = API({ namespace: "MovimientoBancario" });
  protected static messages: Messages<typeof MovimientosBancariosApi> = {};

  public static async getAllNoConciliados(): Promise<IMovimientosBancarios[]> {
    const result = await this.api.get("GetAllNoConciliados");
    return result.data;
  }
}
