import React from "react";
import { sort } from "radash";
import { useGlobalQuery } from "@hooks";
import { IFilterByArea } from "@interfaces";
import { StatusTag, FiltersDrawer } from "@components";
import { Datejs, ArrayUtils, EventUtils } from "@nubeteck/utils";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

const AREA_FILTER_DEFAULT = "fcFiltrosBuscar";

const Home = () => {
  const { data: filters } = useGlobalQuery(
    "Filters",
    "getByArea",
    AREA_FILTER_DEFAULT,
  );
  const areas = useGlobalQuery("Filters", "getAreas");
  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();
  const filterCatalog = useGlobalQuery(
    "Filters",
    "getAllByFilter",
    filterSelected,
    {
      enabled: !!filterSelected,
    },
  );

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters, "Nombre", "FiltroId");
  }, [filters]);

  const filtersAreasOptions = React.useMemo(() => {
    if (!areas.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      areas.data,
      "FiltroAreaAlias",
      "FiltroAreaAlias",
    );
  }, [areas]);

  // TODO: Fetch from backend
  const statusesOptions = [
    { label: "Activo", value: "Activo" },
    { label: "Inactivo", value: "Inactivo" },
  ];

  const columns: IDataTableColumnsProps<IFilterByArea>[] = [
    {
      filter: true,
      sortable: true,
      field: "Nombre",
      dataType: "text",
      header: "Filtro",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
    },
    {
      filter: true,
      header: "Área",
      sortable: true,
      dataType: "select",
      field: "FiltroAreaAlias",
      filterOperator: FilterOperator.AND,
      filterOptions: filtersAreasOptions,
      filterMatchMode: FilterMatchMode.EQUALS,
    },
    {
      filter: true,
      sortable: true,
      dataType: "date",
      field: "FechaCreacion",
      header: "Fecha de creación",
      filterOperator: FilterOperator.AND,
      filterMatchMode: FilterMatchMode.DATE_IS,
      body: (rowData: IFilterByArea) => (
        <span title={Datejs(rowData.FechaCreacion).format("LLL")}>
          {Datejs(rowData.FechaCreacion).format("L")}
        </span>
      ),
    },
    {
      filter: true,
      header: "Estado",
      dataType: "select",
      field: "EstadoNombre",
      filterOptions: statusesOptions,
      filterOperator: FilterOperator.AND,
      filterMatchMode: FilterMatchMode.EQUALS,
      body: (rowData: IFilterByArea) => (
        <StatusTag status={rowData.EstadoNombre} />
      ),
    },
  ];

  const value = React.useMemo(() => {
    if (!filterCatalog.data) return [];

    return sort(
      filterCatalog.data.map((filter) => ({
        ...filter,
        FechaCreacion: new Date(filter.FechaCreacion),
      })),
      (item) => item.FechaCreacion.getTime(),
      true,
    );
  }, [filterCatalog.data]);

  return (
    <>
      <CardTable<IFilterByArea>
        type="data"
        value={value}
        title="Filtros"
        resizableColumns
        columns={columns}
        dataKey="FiltroId"
        removableSort={true}
        columnResizeMode="expand"
        subtitle="Todos los filtros"
        valueFilter={filterSelected}
        filterOptions={filtersOptions}
        loading={filterCatalog.isPending}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        tableActions={[
          {
            icon: "trash",
            label: "Borrar",
          },
          {
            icon: "pencil",
            label: "Editar",
          },
        ]}
      />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
    </>
  );
};

export default Home;
