import React from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { useGlobalMutation } from "@hooks";
import { SubmitHandler } from "react-hook-form";
import { Config, AppRoutes, AppRouteNames } from "@core";
import { Form, useForm, Password, InputText } from "@nubeteck/forms";

type FormValues = {
  email: string;
  password: string;
  rememberMe?: boolean;
};

const LoginPage = () => {
  const mutation = useGlobalMutation("Auth", "login");

  const form = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
  });

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    return mutation.mutateAsync(values);
  };

  return (
    <div className="flex justify-content-center h-screen w-screen">
      <div className="surface-section w-full w-full lg:w-6 p-4 md:p-8">
        <div className="mb-4">
          <img
            height={65}
            className="mb-2"
            src={Config.app_logo}
            alt={Config.app_description}
          />
          <div className="text-800 text-4xl font-semibold">Inicia Sesión</div>
          <span className="text-600 text-lg mr-2">
            Bienvenidos(as) a <b>{Config.app_title}</b>
          </span>
        </div>
        <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-column gap-2">
            <InputText
              type="text"
              name="email"
              className="w-full"
              fieldClassName="flex-1"
              label="Correo electrónico"
              placeholder="Correo electrónico"
              rules={{ required: "Campo requerido" }}
            />
            <Password
              name="password"
              type="password"
              feedback={false}
              label="Contraseña"
              className="w-full"
              fieldClassName="flex-1"
              placeholder="Contraseña"
              rules={{ required: "Campo requerido" }}
            />
            <div className="flex flex-wrap align-items-center justify-content-between">
              <Link
                to={AppRoutes[AppRouteNames.FORGOT_PASSWORD_PAGE].create({})}
                className="font-semibold no-underline text-blue-500 text-right cursor-pointer"
              >
                ¿Olvidaste la contraseña?
              </Link>
            </div>
            <Button type="submit" className="w-full" label="Iniciar Sesión" />
          </div>
        </Form>
      </div>
      <div
        className="hidden lg:block w-6 bg-no-repeat bg-cover"
        style={{
          backgroundImage:
            "url('https://coophillary.nube.com.do/login-bg.jpg')",
        }}
      ></div>
    </div>
  );
};

export default LoginPage;
