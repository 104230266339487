import { param, route } from "typesafe-react-router";

export enum GeneralLedgerRouteNames {
  DASHBOARD_PAGE = "DASHBOARD_PAGE",
  EXCHANGE_RATES = "EXCHANGE_RATES",
  RECONCILIATION_PAGE = "RECONCILIATION_PAGE",
  MONTHLY_CLOSING_PAGE = "MONTHLY_CLOSING_PAGE",
  ACCOUNT_CATALOG_PAGE = "ACCOUNT_CATALOG_PAGE",
  //ACCOUNT_CATALOG_SIMPLE_PAGE = "ACCOUNT_CATALOG_SIMPLE_PAGE",
  ACCOUNT_CATALOG_SIMPLE_DETAILS_PAGE = "ACCOUNT_CATALOG_SIMPLE_PAGE/:id",
  //ACCOUNT_CATALOG_CONSOLIDATE_PAGE = "ACCOUNT_CATALOG_CONSOLIDATE_PAGE",
  ACCOUNT_CATALOG_CONSOLIDATE_DETAILS_PAGE = "ACCOUNT_CATALOG_CONSOLIDATE_PAGE/:id",
  FINANCIAL_STATEMENT_PAGE = "FINANCIAL_STATEMENT_PAGE",
  ACCOUNT_CATALOG_SIMPLE_PAGE = "ACCOUNT_CATALOG_SIMPLE_PAGE",
  ACCOUNT_CATALOG_CONSOLIDATE_PAGE = "ACCOUNT_CATALOG_CONSOLIDATE_PAGE",
  DETAILS = "DETAILS",
  CREATE = "CREATE",
  EDIT = "EDIT",
  ACCOUNTING_MOVEMENTS = "ACCOUNTING_MOVEMENTS",
  ACCOUNTING_MOVEMENTS_DETAILS = `${ACCOUNTING_MOVEMENTS}/${DETAILS}`,
  FINANCIAL_STATEMENT_DIVISION_PAGE = "FINANCIAL_STATEMENT_DIVISION_PAGE",
  CURRENCY_PAGE = "CURRENCY_PAGE",
  CURRENCY_DETAIL_PAGE = "CURRENCY_DETAIL_PAGE",
}

export const GeneralLedgerRoutes = {
  [GeneralLedgerRouteNames.CREATE]: route("create"),
  [GeneralLedgerRouteNames.DETAILS]: route(param("id")),
  [GeneralLedgerRouteNames.DASHBOARD_PAGE]: route("ledger"),
  [GeneralLedgerRouteNames.EDIT]: route("edit", param("id")),
  [GeneralLedgerRouteNames.CURRENCY_PAGE]: route("currency"),
  [GeneralLedgerRouteNames.EXCHANGE_RATES]: route("exchange-rate"),
  [GeneralLedgerRouteNames.CURRENCY_DETAIL_PAGE]: route(param("id")),
  [GeneralLedgerRouteNames.RECONCILIATION_PAGE]: route("reconciliation"),
  [GeneralLedgerRouteNames.ACCOUNT_CATALOG_SIMPLE_PAGE]: route("simple"),
  [GeneralLedgerRouteNames.MONTHLY_CLOSING_PAGE]: route("monthly-closing"),
  [GeneralLedgerRouteNames.ACCOUNT_CATALOG_PAGE]: route("account-catalog"),
  [GeneralLedgerRouteNames.FINANCIAL_STATEMENT_PAGE]: route("financial-state"),
  [GeneralLedgerRouteNames.ACCOUNTING_MOVEMENTS]: route("accounting-movements"),
  [GeneralLedgerRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_PAGE]:
    route("consolidate"),
  [GeneralLedgerRouteNames.FINANCIAL_STATEMENT_DIVISION_PAGE]: route(
    "financial-state-division",
  ),
  //[GeneralLedgerRouteNames.ACCOUNT_CATALOG_SIMPLE_PAGE]: route("simple"),
  [GeneralLedgerRouteNames.ACCOUNT_CATALOG_SIMPLE_DETAILS_PAGE]: route(
    "simple",
    param("id"),
  ),
  [GeneralLedgerRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_DETAILS_PAGE]: route(
    "consolidate",
    param("id"),
  ),
};

export const EDIT_ROUTE = GeneralLedgerRoutes[GeneralLedgerRouteNames.EDIT]
  .template()
  .slice(1);

export const CREATE_ROUTE = GeneralLedgerRoutes[GeneralLedgerRouteNames.CREATE]
  .template()
  .slice(1);
