import React from "react";
import { Config } from "@core";
import { Icon } from "@nubeteck/icons";
import { Menu } from "primereact/menu";
import { Link } from "react-router-dom";
import { Ripple } from "primereact/ripple";
import { Avatar } from "primereact/avatar";
import { SettingsFeature } from "@features";
import { Divider } from "primereact/divider";
import { HeaderIcon } from "@nubeteck/prime";
import { MenuItem } from "primereact/menuitem";
import { StyleClass } from "primereact/styleclass";

import { AppsMenuOverlay } from "../apps-menu-overlay";

const UserMenuOptions = () => {
  const menuLeft = React.useRef<Menu>(null);

  const itemRenderer = React.useCallback((item: MenuItem) => {
    const className = "flex align-items-center p-menuitem-link";

    const renderContent = (
      <>
        <Icon size={20} name={item.icon} />
        <span className="mx-2">{item.label}</span>
      </>
    );

    return (
      <div className="p-menuitem-content">
        {item.url ? (
          <Link to={item.url} className={className}>
            {renderContent}
          </Link>
        ) : (
          <a className={className}>{renderContent}</a>
        )}
      </div>
    );
  }, []);

  const items: MenuItem[] = [
    {
      template: () => {
        return (
          <div className="w-full flex align-items-center px-2 text-color">
            <Avatar
              shape="circle"
              className="mr-2"
              image="/avatar-placeholder.webp"
            />
            <div className="flex flex-column align">
              <span className="font-semibold text-sm">Amy Elsner</span>
              <span className="text-xs">nubeteck@email.com</span>
            </div>
          </div>
        );
      },
    },
    {
      separator: true,
    },
    {
      icon: "settings",
      template: itemRenderer,
      label: "Configuraciones",
      url: SettingsFeature.Routes[SettingsFeature.RouteNames.SETTINGS].create(
        {},
      ),
    },
    {
      icon: "logout",
      label: "Cerrar sesión",
      template: itemRenderer,
    },
  ];

  return (
    <>
      <a
        aria-haspopup
        aria-controls="popup_menu_left"
        onClick={(event) => menuLeft.current?.toggle(event)}
        className="flex gap-2 align-items-center cursor-pointer"
      >
        <img height={30} alt="avatar-user" src="/avatar-placeholder.webp" />
      </a>
      <Menu popup model={items} ref={menuLeft} className="w-full md:w-15rem" />
    </>
  );
};

export interface IHeaderProps {
  title: string;
}

const Header = ({ title }: IHeaderProps) => {
  const sidebarRef = React.useRef(null);

  return (
    <header className="flex align-items-center gap-1 px-3 py-1 h-3rem surface-section surface-border border-bottom-1">
      <div className="flex lg:hidden align-items-center h-full">
        <StyleClass
          hideOnOutsideClick
          selector="#sidebar"
          nodeRef={sidebarRef}
          leaveToClassName="hidden"
          enterFromClassName="hidden"
          enterActiveClassName="fadeinleft"
          leaveActiveClassName="fadeoutleft"
        >
          <a
            ref={sidebarRef}
            style={{ height: 24 }}
            className="p-ripple text-primary-500 cursor-pointer"
          >
            <Icon name="menu-2" />
            <Ripple />
          </a>
        </StyleClass>
      </div>
      <div className="hidden lg:flex flex-1 text-sm text-primary font-medium">
        {`${Config.app_title} | ${title}`}
      </div>
      <div className="flex flex-1 justify-content-end">
        <div className="flex gap-2 align-items-center">
          <HeaderIcon
            name="settings"
            title="Configuraciones"
            to={SettingsFeature.Routes[
              SettingsFeature.RouteNames.SETTINGS
            ].create({})}
          />
          <AppsMenuOverlay />
        </div>
        <Divider layout="vertical" className="p-0 ml-2" />
        <div className="flex h-full justify-content-end">
          <UserMenuOptions />
        </div>
      </div>
    </header>
  );
};

export default Header;
