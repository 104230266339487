import React from "react";
import { select } from "radash";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { EmptyMessage } from "@components";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { ColumnProps } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { CardTable, ConfirmDialog } from "@nubeteck/prime";

import { filterNodes } from "../../../utils";
import { useLedgerQuery, useLedgerMutation } from "../../../hooks";
import { ISucursales, IAccountCatalog } from "../../../interfaces";

interface ICatalog {
  code: number;
  name: string;
}
interface ISelectedCity {
  code: number;
  name: string;
}

type TreeTableSelectionKey = {
  checked: boolean;
  partialChecked: boolean;
};

type TreeTableSelectionKeysType = {
  [key: string]: TreeTableSelectionKey;
};

interface IConsolidateTableDialogProps {
  visible: boolean;
  onHide: () => void;
  columns: ColumnProps[];
  filteredEquivalencias: number[];
  accountCatalogs: IAccountCatalog[];
  selectedNodeKey: null | string | undefined;
}

const ConsolidateTableDialog = ({
  onHide,
  visible,
  columns,
  accountCatalogs,
  selectedNodeKey,
  filteredEquivalencias,
}: IConsolidateTableDialogProps) => {
  const { id } = useParams();

  const [searchText, setSearchText] = React.useState("");
  const [catalog, setCatalog] = React.useState<ICatalog>();
  const [modalConsolidate, setModalConsolidate] = React.useState(false);
  const [selectedAccounts, setSelectedAccounts] =
    React.useState<TreeTableSelectionKeysType>({});
  const [selectedAccountIds, setSelectedAccountIds] =
    React.useState<number[]>();
  const [selectedCities, setSelectedCities] = React.useState<
    null | ISelectedCity[]
  >(null);

  const { data: accountCatalogData } = useLedgerQuery(
    "AccountCatalog",
    "getById",
    Number(id),
    {
      enabled: !!Number(id),
    },
  );
  const consolidar = useLedgerMutation("AccountCatalog", "consolidar");

  const selectedAccountDetails = (accountCatalogs || []).flatMap((catalog) =>
    (catalog.CatalogosCuentasDetalles || [])
      .filter((account) => account.CuentaId === Number(selectedNodeKey))
      .map((account) => ({
        CuentaNombre: account.CuentaNombre,
        CatalogoNombre:
          (
            accountCatalogs?.find(
              (cat) => cat.CatalogoId === account.CatalogoId,
            ) || {}
          ).Nombre || "Desconocido",
      })),
  )[0] || { CuentaNombre: "Desconocida", CatalogoNombre: "Desconocido" };

  const groupedAccounts = (accountCatalogs || [])
    .filter((catalog) => catalog && catalog.CatalogosCuentasDetalles)
    .flatMap((catalog) =>
      (catalog.CatalogosCuentasDetalles || [])
        .filter((account) => selectedAccountIds?.includes(account.CuentaId))
        .map((account) => ({
          ...account,
          CatalogoNombre:
            (
              accountCatalogs?.find(
                (cat) => cat.CatalogoId === account.CatalogoId,
              ) || {}
            ).Nombre || "Desconocido",
        })),
    )
    .reduce(
      (acc, account) => {
        if (!acc[account.CatalogoNombre]) {
          acc[account.CatalogoNombre] = [];
        }
        acc[account.CatalogoNombre].push(account.CuentaNombre);
        return acc;
      },
      {} as Record<string, string[]>,
    );

  const catalogEntries = Object.entries(groupedAccounts);
  const catalogMessages = catalogEntries
    .map(([catalogName, accounts], index) => {
      const accountList = accounts.join(", ");

      if (index === catalogEntries.length - 1) {
        return `${accountList.includes(", ") ? "las cuentas" : "la cuenta"} ${accountList} del catalogo ${catalogName}`;
      } else {
        return `${accountList.includes(", ") ? "las cuentas" : "la cuenta"} ${accountList} del catalogo ${catalogName}`;
      }
    })
    .join(", ")
    .replace(/, ([^,]*)$/, " y $1");

  React.useEffect(() => {
    if (selectedCities) {
      setCatalog(undefined);
    }
  }, [selectedCities, setCatalog]);

  const sucursalOptions = React.useMemo(() => {
    if (!accountCatalogData?.SucursalesSelect?.length) return [];
    return accountCatalogData?.SucursalesSelect.map(
      (sucursal: ISucursales) => ({
        SucursalId: sucursal.SucursalId,
        SucursalNombre: sucursal.SucursalNombre,
      }),
    );
  }, [accountCatalogData?.SucursalesSelect]);

  const catalogsOptions = React.useMemo(() => {
    if (!accountCatalogs?.length) return [];

    const filteredCatalogos = select(
      accountCatalogs,
      (catalog) => ({
        name: catalog.Nombre,
        code: catalog.CatalogoId,
      }),
      (catalog) => {
        const isSimple = catalog.CatalogoTipoId === 1;
        const hasSelectedCities = (selectedCities?.length ?? 0) > 0;
        const cityExistsInSucursales =
          catalog.Sucursales?.some((sucursal) =>
            selectedCities?.some((city) => city.code === sucursal.SucursalId),
          ) ?? false;

        return isSimple && (hasSelectedCities || cityExistsInSucursales);
      },
    );

    return filteredCatalogos;
  }, [accountCatalogs, selectedCities]);

  const extractAccountIds = (accounts: TreeTableSelectionKeysType) => {
    return Object.keys(accounts)
      .filter((key) => accounts[key].checked)
      .map((key) => Number(key));
  };

  React.useEffect(() => {
    const ids = extractAccountIds(selectedAccounts);
    setSelectedAccountIds(ids);
  }, [selectedAccounts]);

  const dataConsolidar = React.useMemo(() => {
    const catalogo1 = accountCatalogs?.find(
      (catalogo1) => catalogo1.CatalogoId === catalog?.code,
    );

    const mappedDetails =
      catalogo1?.CatalogosCuentasDetalles?.map((detalle) => ({
        key: detalle.CuentaId?.toString() || "",
        data: {
          Cuenta: detalle.Cuenta,
          CuentaId: detalle.CuentaId,
          EstadoId: detalle.EstadoId,
          Nombre: detalle.CuentaNombre,
          Descripcion: detalle.Descripcion,
          CuentaPadreId: detalle.CuentaPadreId,
        },
      })) || [];

    const nodesMap = new Map();
    mappedDetails.forEach((node) =>
      nodesMap.set(node.key, { ...node, children: [] }),
    );

    mappedDetails.forEach((node) => {
      const parentNode = nodesMap.get(node.data.CuentaPadreId?.toString());
      if (parentNode) {
        parentNode.children.push(nodesMap.get(node.key));
      }
    });

    const filteredNodes = Array.from(nodesMap.values())
      .filter((node) => !node.data.CuentaPadreId)
      .filter((node) => !filteredEquivalencias.includes(Number(node.key)));

    return filteredNodes;
  }, [accountCatalogs, catalog?.code, filteredEquivalencias]);

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="Seleccione un catálogo" />;
  }, []);

  return (
    <Dialog
      className="w-11"
      visible={visible}
      contentClassName="p-0"
      style={{ maxWidth: 1000 }}
      header="Consolidación con catálogos de cuenta"
      headerClassName="p-3 border-bottom-1 surface-border"
      onHide={() => {
        onHide();
        setCatalog(undefined);
        setSelectedAccountIds([]);
        setSelectedCities([]);
      }}
      footer={
        <Button
          label="Consolidar"
          onClick={() => setModalConsolidate(true)}
          disabled={!selectedAccountIds || selectedAccountIds.length === 0}
        />
      }
    >
      <CardTable
        type="tree"
        hideBorderStyles
        hideSelectFilter
        columns={columns}
        tableActions={[]}
        hideFilterSettingsIcon
        metaKeySelection={false}
        selectionMode="checkbox"
        selectionKeys={selectedAccounts}
        emptyMessage={renderEmptyMessage}
        onSearch={(value) => setSearchText(value)}
        value={filterNodes(dataConsolidar, searchText)}
        onSelectionChange={(e) => {
          setSelectedAccounts(e.value as TreeTableSelectionKeysType);
        }}
        title={
          (
            <Icon
              filled
              name="squares"
              touchable={false}
              className="text-primary"
            />
          ) as never
        }
        headActions={[
          <MultiSelect
            key={1}
            name="Sucursales"
            value={selectedCities}
            options={sucursalOptions}
            style={{ width: "16rem" }}
            className="p-multiselect-sm"
            optionLabel="SucursalNombre"
            placeholder="Seleccionar sucursal(es)"
            onChange={(e) => setSelectedCities(e.value)}
          />,
          <Dropdown
            filter
            key={2}
            value={catalog}
            name="Catálogos"
            optionLabel="name"
            options={catalogsOptions}
            style={{ width: "16rem" }}
            className="p-inputtext-sm"
            disabled={!selectedCities?.length}
            placeholder="Seleccione un catálogo"
            onChange={(e) => setCatalog(e.value)}
          />,
        ]}
      />
      <ConfirmDialog
        style={{ maxWidth: 600 }}
        visible={modalConsolidate}
        header="Confirme la consolidación"
        onHide={() => setModalConsolidate(false)}
        reject={() => {
          setModalConsolidate(false);
        }}
        message={`Se fusionarán ${catalogMessages} en la cuenta "${selectedAccountDetails.CuentaNombre}" (Catálogo: "${selectedAccountDetails.CatalogoNombre}"). ¿Desea proceder?`}
        accept={() =>
          consolidar.mutateAsync(
            {
              CuentaId: Number(selectedNodeKey),
              CuentasIds: selectedAccountIds ?? [],
            },
            {
              onSuccess: () => {
                onHide();
                setModalConsolidate(false);
                setSelectedAccounts({});
                setCatalog(undefined);
                setSelectedCities([]);
              },
            },
          )
        }
      />
    </Dialog>
  );
};

export default ConsolidateTableDialog;
