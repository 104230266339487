import { BaseApi } from "@api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
  ReturnType,
  Parameters,
  StaticMethods,
  RemoveApiSuffix,
} from "@interfaces";

type QueryOptions<T> = Omit<UseQueryOptions<T>, "queryFn" | "queryKey">;

interface IUseConfigQueryProps<T extends Record<string, typeof BaseApi>> {
  apis: T;
}

const useConfigQuery = <T extends Record<string, typeof BaseApi>>({
  apis,
}: IUseConfigQueryProps<T>) => {
  type Keys = keyof typeof apis;

  type ApiMethods = {
    [K in Keys as RemoveApiSuffix<K>]: StaticMethods<(typeof apis)[K]>;
  };

  return function Query<
    K extends keyof ApiMethods,
    F extends keyof ApiMethods[K],
  >(
    key: K,
    fn: F,
    variables?: Parameters<ApiMethods[K][F]>[0],
    options?: QueryOptions<Awaited<ReturnType<ApiMethods[K][F]>>>,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apiClass = (apis as any)[`${String(key)}Api`];
    return useQuery({
      ...options,
      queryKey: [key, fn, variables],
      queryFn: () => apiClass[fn]?.(variables),
    });
  };
};

export default useConfigQuery;
