/* eslint-disable perfectionist/sort-objects */
import { IAgeAnalysis, IDashboardSummary } from "../interfaces";

export const summary: IDashboardSummary = {
  dso: 47,
  cei: 85,
  totalCuentasVencidas: 4593,
  totalCuentasPorCobrar: 6822,
};

export const age_analysis: IAgeAnalysis = {
  categorias: {
    "0-30 días": 5000,
    "31-60 días": 3500,
    "61-90 días": 2000,
    "90+ días": 1500,
  },
  facturasAntiguas: [
    {
      monto: 500,
      diasVencido: 120,
      cliente: "Acme Corporation",
      numeroFactura: "FA123456",
    },
    {
      monto: 300,
      diasVencido: 60,
      cliente: "Global Tech Solutions",
      numeroFactura: "FA234567",
    },
    {
      monto: 300,
      diasVencido: 60,
      cliente: "Global Tech Solutions",
      numeroFactura: "FA234567",
    },
    {
      monto: 750,
      diasVencido: 95,
      cliente: "Sunshine Bakery",
      numeroFactura: "FA345678",
    },
    {
      monto: 1200,
      diasVencido: 150,
      cliente: "Evergreen Landscaping",
      numeroFactura: "FA456789",
    },
    {
      monto: 450,
      diasVencido: 75,
      cliente: "Rapid Logistics",
      numeroFactura: "FA567890",
    },
    {
      monto: 980,
      diasVencido: 110,
      cliente: "Stellar Electronics",
      numeroFactura: "FA678901",
    },
    {
      monto: 620,
      diasVencido: 85,
      cliente: "Harmony Health Foods",
      numeroFactura: "FA789012",
    },
    {
      monto: 1500,
      diasVencido: 180,
      cliente: "Precision Manufacturing",
      numeroFactura: "FA890123",
    },
    {
      monto: 350,
      diasVencido: 45,
      cliente: "Coastal Seafood Market",
      numeroFactura: "FA901234",
    },
  ],
};
