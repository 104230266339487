import clsx from "clsx";
import React from "react";
import { sort } from "radash";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { StatusTag, FiltersDrawer } from "@components";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import {
  CardTable,
  confirmDialog,
  ConfirmDialog,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

import { IAccountCatalog } from "../../interfaces";
import { AccountCatalogListFormDialog } from "../../components";
import { useLedgerQuery, useLedgerMutation } from "../../hooks";
import { GeneralLedgerRoutes, GeneralLedgerRouteNames } from "../../routes";

const AREA_FILTER_DEFAULT = "fcCatalogosCuentasBuscar";
enum AccountStatus {
  Active = 1,
  Inactive = 2,
}

const AccountCatalogPage = () => {
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();
  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const changeAccountCatalogState = useLedgerMutation(
    "AccountCatalog",
    "changeState",
  );
  const accountCatalogs = useLedgerQuery(
    "AccountCatalog",
    "getAllByFilter",
    filterSelected,
    { enabled: !!filterSelected },
  );
  const { data: filters } = useGlobalQuery(
    "Filters",
    "getByArea",
    AREA_FILTER_DEFAULT,
  );

  // TODO: Fetch from backend
  const statusesOptions = React.useMemo(
    () => [
      { label: "Activo", value: "Activo" },
      { label: "Inactivo", value: "Inactivo" },
    ],
    [],
  );

  // TODO: Fetch from backend
  const typeOptions = React.useMemo(
    () => [
      { label: "Simple", value: "Simple" },
      { label: "Consolidado", value: "Consolidado" },
    ],
    [],
  );

  const columns = React.useMemo<IDataTableColumnsProps<IAccountCatalog>[]>(
    () => [
      {
        sortable: true,
        field: "Nombre",
        header: "Nombre",
        body: (rowData) => {
          const isSimple = rowData.CatalogoTipoId !== 2;
          const routeName = isSimple
            ? GeneralLedgerRouteNames.ACCOUNT_CATALOG_SIMPLE_DETAILS_PAGE
            : GeneralLedgerRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_DETAILS_PAGE;

          return (
            <Link
              className="no-underline text-blue-500"
              to={`.${GeneralLedgerRoutes[routeName].create({ id: `${rowData.CatalogoId}` })}`}
            >
              {rowData.Nombre}
            </Link>
          );
        },
      },
      {
        filter: true,
        header: "Tipo",
        dataType: "select",
        filterOptions: typeOptions,
        field: "CatalogoTipoNombre",
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.EQUALS,
      },
      {
        filter: true,
        header: "Estado",
        dataType: "select",
        field: "EstadoNombre",
        filterOptions: statusesOptions,
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.EQUALS,
        body: (rowData: IAccountCatalog) => (
          <StatusTag status={rowData.EstadoNombre} />
        ),
      },
    ],
    [statusesOptions, typeOptions],
  );

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const tableActions = React.useCallback(
    (rowData: IAccountCatalog) => {
      const isActive = rowData.EstadoId === AccountStatus.Active;

      return [
        {
          icon: "pencil",
          label: "Editar",
          onClick: EventUtils.callEvent(dispatch, {
            type: "OPEN_DIALOG",
            payload: Number(rowData.CatalogoId),
          }),
        },
        {
          icon: isActive ? "circle-x" : "plug",
          label: isActive ? "Anular" : "Activar",
          iconClassName: isActive ? "text-red-600" : "text-green-600",
          onClick: () => {
            confirmDialog({
              rejectLabel: "Cancelar",
              message: "Esta acción no podrá deshacerse.",
              acceptLabel: isActive ? "Anular" : "Activar",
              header: `¿Estás seguro(a) de ${isActive ? "anular" : "activar"} ${rowData.Nombre}?`,
              acceptClassName: clsx(
                isActive ? "p-button-danger" : "p-button-primary",
                "p-button-sm",
              ),
              accept: () =>
                changeAccountCatalogState.mutateAsync({
                  CatalogoId: Number(rowData.CatalogoId),
                  EstadoId: isActive
                    ? AccountStatus.Inactive
                    : AccountStatus.Active,
                }),
            });
          },
        },
      ];
    },
    [changeAccountCatalogState],
  );

  return (
    <>
      <CardTable<IAccountCatalog>
        type="data"
        removableSort
        resizableColumns
        columns={columns}
        dataKey="CatalogoId"
        columnResizeMode="expand"
        tableActions={tableActions}
        valueFilter={filterSelected}
        title="Catálogos de cuentas"
        filterOptions={filtersOptions}
        loading={accountCatalogs.isPending}
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        value={sort(
          accountCatalogs.data ?? [],
          (item) => item.CatalogoId,
          true,
        )}
        headActions={[
          <Button
            size="small"
            key="NewCatalog"
            label="Nuevo catalogo de cuentas"
            icon={<Icon size={20} name="plus" className="mr-1" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
      />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
      <AccountCatalogListFormDialog
        open={state.open}
        cuentaId={state.id}
        onClose={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <ConfirmDialog />
    </>
  );
};

export default AccountCatalogPage;
