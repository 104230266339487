import React from "react";
import { Bar } from "react-chartjs-2";
import { Card } from "@nubeteck/prime";
import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  ChartOptions,
  CategoryScale,
  Chart as ChartJS,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface BarChartProps {
  title: string;
  data: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
    }[];
  };
}

const BarChart = ({ data, title }: BarChartProps) => {
  const options: ChartOptions<"bar"> = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        text: title,
        display: true,
      },
    },
  };

  return (
    <Card title={title} className="flex-1">
      <div className="flex justify-content-center">
        <Bar data={data} options={options} className="w-full" />
      </div>
    </Card>
  );
};

export default BarChart;
