import { capitalize } from "radash";
import { faker } from "@faker-js/faker";
import { Datejs } from "@nubeteck/utils";
import { IStatsCardProps } from "@components";

const getArrayOf = <T extends object>(
  fn: (_: unknown, i: number) => T,
  length = 5,
) => Array.from({ length }).map(fn);

const generateDescendingYears = (startYear: number, count: number) => {
  return Array.from({ length: count }, (_, index) => startYear - index);
};

const createYearGenerator = () => {
  const currentYear = new Date().getFullYear();
  const years = generateDescendingYears(currentYear, 50);
  let index = 0;

  return () => {
    if (index >= years.length) {
      index = 0;
    }
    return years[index++];
  };
};

const getNextYear = createYearGenerator();

let isFirstEntry = true;

export const getMonthlyClosing = (id: number) => {
  const year = getNextYear();
  const date = Datejs(`${year}-${faker.date.month()}-01`);
  const estado = isFirstEntry ? "Abierto" : "Cerrado";
  isFirstEntry = false;

  return {
    id,
    Anio: year,
    EstadoNombre: estado,
    Mes: capitalize(date.format("MMMM")),
  };
};

export const getMonthlyClosingPage = () => {
  return {
    data: getArrayOf((_, i) => getMonthlyClosing(i), 20),
  };
};

export const getStats = (id: number): IStatsCardProps & { id: number } => {
  return {
    id,
    type: "currency",
    description: faker.lorem.words(3),
    title: capitalize(faker.lorem.words(2)),
    currencyIso: faker.helpers.arrayElement(["DOP", "USD", "EUR"]),
    value: parseInt(faker.finance.amount({ min: 10000, max: 1000000 }), 10),
    iconName: faker.helpers.arrayElement(["building-bank", "coin", "scale"]),
  };
};

export const getDashboard = () => {
  return {
    stats: getArrayOf((_, i) => getStats(i), 4),
  };
};

// REFERENCE DOCUMENTS

const statuses = [
  { id: 1, nombre: "Activo" },
  { id: 2, nombre: "Inactivo" },
  { id: 3, nombre: "Eliminado" },
  { id: 4, nombre: "Incompleto" },
  { id: 5, nombre: "Anulado" },
];

export const getOneReferenceDocument = (id: number) => {
  const selectedStatus = faker.helpers.arrayElement(statuses);
  return {
    TipoDocumentoId: id,
    Fecha: faker.date.past(),
    Notas: faker.lorem.lines(1),
    EstadoId: selectedStatus.id,
    DocumentoEnlace: `documents/${id}`,
    EstadoNombre: selectedStatus.nombre,
    DocumentoId: faker.number.int(1000000),
    Secuencia: "B" + faker.string.numeric(11),
    TipoDocumentoNombre: faker.helpers.arrayElement([
      "Cotización",
      "Factura de venta",
      "Nota de crédito",
      "Nota de débito",
      "Conduce",
      "Factura de compra",
      "Orden de compra",
      "Movimiento de inventario",
      "Depósito",
      "Cheque",
      "Transferencia",
      "Pago de financiamiento",
    ]),
  };
};

export const getByAccountingMovementId = (_id: number) => {
  return {
    data: getArrayOf((_, i) => getOneReferenceDocument(i), 4),
  };
};

// REFERENCE DOCUMENTS - END
