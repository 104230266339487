import React from "react";

interface IAuthContextProps {
  user: null;
  isLogged: boolean;
  logout: () => void;
  verifyToken: () => void;
}

const AuthContext = React.createContext<IAuthContextProps>({
  user: null,
  isLogged: false,
  logout: () => {},
  verifyToken: () => {},
});

export default AuthContext;
