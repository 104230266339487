import axios, { CreateAxiosDefaults } from "axios";

const SERVER_URL = process.env.NUBETECK_SERVER_URL ?? "";

if (!SERVER_URL) {
  throw new Error("Please add your Server URL to environment variables.");
}

type Options = Omit<CreateAxiosDefaults, "baseUrl" | "headers"> & {
  namespace?: string;
};

const API = ({ namespace, ...options }: Options) => {
  const token = localStorage.getItem("token");

  const api = axios.create({
    ...options,
    baseURL: namespace ? `${SERVER_URL}/${namespace}/` : SERVER_URL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  // Interceptors...

  return api;
};

export default API;
