import clsx from "clsx";
import React from "react";
import { getSeverity } from "@utils";
import { Tag, TagProps } from "primereact/tag";

/**
 * Props for the StatusTag component.
 */
interface StatusTagProps {
  /** The status string to be displayed in the tag. */
  status?: string;
}

/**
 * StatusTag component displays a colored tag based on the provided status.
 * It uses predefined severities or custom colors.
 *
 * @param {StatusTagProps} props - The props for the StatusTag component.
 * @returns {JSX.Element} A Tag component with appropriate styling based on the status.
 */
const StatusTag = ({ status }: StatusTagProps): React.JSX.Element => {
  const severity = getSeverity(status);

  /**
   * Checks if the given severity is a predefined PrimeReact severity.
   *
   * @param {string} sev - The severity to check.
   * @returns {boolean} True if the severity is predefined, false otherwise.
   */
  const isPredefinedSeverity = (
    sev: null | string | undefined,
  ): sev is NonNullable<TagProps["severity"]> =>
    sev !== null &&
    sev !== undefined &&
    ["info", "danger", "success", "warning"].includes(sev);

  return (
    <Tag
      value={status}
      severity={isPredefinedSeverity(severity) ? severity : null}
      className={clsx(
        !isPredefinedSeverity(severity) ? severity : undefined,
        "align-self-center",
      )}
    />
  );
};

export default StatusTag;
