/* eslint-disable @typescript-eslint/no-unused-vars */
import clsx from "clsx";
import { capitalize } from "radash";
import { Icon } from "@nubeteck/icons";
import { Dialog } from "@nubeteck/prime";
import { Datejs } from "@nubeteck/utils";
import { Column } from "primereact/column";
import { Message } from "primereact/message";
import { IChangeLogEntry } from "@interfaces";
import { Timeline } from "primereact/timeline";
import { DialogProps } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { FakeData } from "ClientApp/features/general-ledger/core";

export interface IChangeLogDialogProps extends DialogProps {
  entityId: number;
  entityName: string;
  entityId2?: number;
  entityName2?: string;
}

interface TimelineEvent {
  date: string;
  data: IChangeLogEntry[];
}

// Procedimiento LogsGetLogsByEntityAndIdTEST en CoopHillary

const ChangeLogDialog = ({
  entityId,
  entityId2,
  entityName,
  entityName2,
  ...props
}: IChangeLogDialogProps) => {
  //   const { data: logs } = useGlobalQuery(
  //     "Logs",
  //     "getLogsByEntityAndId",
  //     {
  //       Id: entityId,
  //       Entity: entityName,
  //     },
  //     {
  //       enabled: !!entityId,
  //     },
  //   );

  const logs = FakeData.Logs;

  const timelineEvents: TimelineEvent[] = Object.entries(logs).map(
    ([date, data]) => ({
      date,
      data,
    }),
  );

  const customizedContent = (item: TimelineEvent) => (
    <CustomContent item={item} />
  );

  const customizedMarker = () => (
    <div className="py-1">
      <div className="p-1 bg-primary border-circle" />
    </div>
  );

  return (
    <Dialog
      {...props}
      className="w-11"
      title="Historial de cambios"
      contentClassName="pt-4 pl-4 pb-0 pr-0"
      style={{ maxWidth: 1024, maxHeight: 500 }}
    >
      <Timeline
        value={timelineEvents}
        marker={customizedMarker}
        content={customizedContent}
        className="change-log-timeline"
      />
    </Dialog>
  );
};

const formatDate = (date: string) => {
  const today = Datejs().startOf("day");
  const givenDate = Datejs(date).startOf("day");
  const currentYear = Datejs().year();
  const dateYear = Datejs(date).year();

  const formatters = {
    isToday: () => "Hoy a las " + Datejs(date).format("h:mm a"),
    isYesterday: () => "Ayer a las " + Datejs(date).format("h:mm a"),
    default: () => capitalize(Datejs(date).format("dddd D [de] MMMM, h:mm A")),
    isPreviousYear: () =>
      capitalize(Datejs(date).format("dddd D [de] MMMM [del] YYYY, h:mm A")),
  };

  const getFormatterKey = () => {
    if (givenDate.isSame(today, "day")) return "isToday";
    if (givenDate.isSame(today.subtract(1, "day"), "day")) return "isYesterday";
    if (dateYear < currentYear) return "isPreviousYear";
    return "default";
  };

  return formatters[getFormatterKey()]();
};

const ContentHeader = ({
  date,
  modifiedBy,
}: {
  date: string;
  modifiedBy: string;
}) => (
  <div className="flex justify-content-between align-items-center gap-2 mb-3 font-medium">
    <span className="line-height-1">{formatDate(date)}</span>
    <span className="line-height-1">
      Modificado por:{" "}
      <a className="text-primary-600 cursor-pointer">{modifiedBy}</a>
    </span>
  </div>
);

const ValueDisplay = ({
  value,
  isOriginal,
}: {
  value: string;
  isOriginal: boolean;
}) => (
  <Message
    text={value}
    style={{ minWidth: 250 }}
    severity={isOriginal ? "error" : "success"}
    className="w-full justify-content-between text-gray-600"
    icon={
      <Icon
        size={16}
        touchable={false}
        name={isOriginal ? "minus" : "plus"}
        className={clsx(
          isOriginal ? "bg-red-100" : "bg-green-100",
          "border-round-lg p-1",
        )}
      />
    }
  />
);

const CustomContent = ({ item }: { item: TimelineEvent }) => {
  const modifiedBy = item.data[0].ModificadoPor;

  return (
    <div className="flex flex-column mb-5">
      <ContentHeader date={item.date} modifiedBy={modifiedBy} />
      <DataTable
        size="small"
        value={item.data}
        className="border-round-md border-1 surface-border changelog-datatable"
      >
        <Column field="Propiedad" header="Propiedad" />
        <Column
          field="ValorOriginal"
          header="Valor original"
          body={(rowData: IChangeLogEntry) => (
            <ValueDisplay isOriginal={true} value={rowData.ValorOriginal} />
          )}
        />
        <Column
          field="ValorModificado"
          header="Valor modificado"
          body={(rowData: IChangeLogEntry) => (
            <ValueDisplay isOriginal={false} value={rowData.ValorModificado} />
          )}
        />
      </DataTable>
    </div>
  );
};

export default ChangeLogDialog;
