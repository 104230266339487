import { StatsCard, IStatsCardProps } from "@components";

interface StatisticGroupProps {
  stats: IStatsCardProps[];
}

const StatisticGroup = ({ stats }: StatisticGroupProps) => {
  const midPoint = Math.ceil(stats.length / 2);

  return (
    <div className="flex gap-3 flex-column sm:flex-row">
      {[stats.slice(0, midPoint), stats.slice(midPoint)].map(
        (group, groupIndex) => (
          <div
            key={`group-${groupIndex}`}
            className="flex-1 flex flex-column xl:flex-row gap-3"
          >
            {group.map((stat, index) => (
              <StatsCard key={`${groupIndex}-${index}`} {...stat} />
            ))}
          </div>
        ),
      )}
    </div>
  );
};

export default StatisticGroup;
