import React from "react";
import { toast } from "sonner";
import { Statuses } from "@core";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { sort, select, unique } from "radash";
import { Skeleton } from "primereact/skeleton";
import { FilterMatchMode } from "primereact/api";
import { useNavigate, generatePath } from "react-router-dom";
import { Datejs, ArrayUtils, EventUtils } from "@nubeteck/utils";
import { convertToDate, FormatterUtils, matchesSearchText } from "@utils";
import {
  CardTable,
  confirmDialog,
  ConfirmDialog,
  IDataTableColumnsProps,
} from "@nubeteck/prime";
import {
  StatusTag,
  EmptyMessage,
  FiltersDrawer,
  dateFilterTemplate,
  multiSelectFilterTemplate,
} from "@components";

import { useLedgerQuery } from "../../hooks";
import { IAccountingMovement } from "../../interfaces";
import { EDIT_ROUTE, CREATE_ROUTE } from "../../routes";

const AREA_FILTER_DEFAULT = "fcMovimientosContablesBuscar";

const AccountingMovements = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = React.useState("");
  const onSearch = (value: string) => setSearchText(value);

  const { data: filters } = useGlobalQuery(
    "Filters",
    "getByArea",
    AREA_FILTER_DEFAULT,
  );
  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();

  const accountingMovements = useLedgerQuery(
    "AccountingMovements",
    "getAllByFilter",
    filterSelected,
    { enabled: !!filterSelected },
  );

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  const accountingMovementsTypes = React.useMemo(() => {
    if (!accountingMovements.data?.length) return [];
    return unique(
      accountingMovements.data.map(
        (accountingMovement) => accountingMovement.TipoAsientoNombre,
      ),
    );
  }, [accountingMovements]);

  const statusesTypes = React.useMemo(() => {
    if (!accountingMovements.data?.length) return [];
    return unique(
      accountingMovements.data.map(
        (accountingMovement) => accountingMovement.EstadoNombre,
      ),
    );
  }, [accountingMovements]);

  const columns = React.useMemo<IDataTableColumnsProps<IAccountingMovement>[]>(
    () => [
      {
        filter: true,
        sortable: true,
        dataType: "select",
        header: "Tipo de Asiento",
        field: "TipoAsientoNombre",
        showFilterMenuOptions: false,
        filterMenuClassName: "custom",
        filterMatchMode: FilterMatchMode.CONTAINS,
        filterElement: (options) =>
          multiSelectFilterTemplate(options, accountingMovementsTypes),
      },
      {
        sortable: true,
        field: "Secuencia",
        header: "Secuencia",
      },
      {
        filter: true,
        sortable: true,
        field: "Fecha",
        header: "Fecha",
        dataType: "date",
        showAddButton: false,
        showFilterOperator: false,
        filterMenuClassName: "custom",
        filterElement: dateFilterTemplate,
        filterMatchMode: FilterMatchMode.DATE_IS,
        body: (rowData) => (
          <span title={Datejs(rowData.Fecha).format("LL")}>
            {Datejs(rowData.Fecha).format("DD/MM/YYYY")}
          </span>
        ),
      },
      {
        sortable: true,
        field: "Total",
        header: "Total",
        body: (rowData) =>
          FormatterUtils.currency(rowData.Total ?? 0, {
            currencyIso: rowData.MonedaNombreISO ?? "DOP",
          }),
      },
      {
        sortable: true,
        field: "Referencia",
        header: "Referencia",
      },
      {
        filter: true,
        sortable: true,
        header: "Estado",
        dataType: "text",
        field: "EstadoNombre",
        showFilterMenuOptions: false,
        filterMenuClassName: "custom",
        filterMatchMode: FilterMatchMode.CONTAINS,
        body: (rowData) => <StatusTag status={rowData.EstadoNombre} />,
        filterElement: (options) =>
          multiSelectFilterTemplate(options, statusesTypes),
      },
      {
        sortable: true,
        field: "Observaciones",
        header: "Observaciones",
      },
    ],
    [accountingMovementsTypes, statusesTypes],
  );

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay movimientos" />;
  }, []);

  const filteredAccountingMovements = React.useMemo(() => {
    if (!accountingMovements.data?.length) return [];

    return select(
      accountingMovements.data ?? [],
      (item) => ({ ...item, Fecha: convertToDate(item.Fecha?.toString()) }),
      (item) => {
        const fields = [
          item.TipoAsientoNombre,
          item.Secuencia,
          Datejs(item.Fecha).format("DD/MM/YYYY"),
          Datejs(item.Fecha).format("LLLL"),
          `${item.Total}`,
          item.Referencia,
          item.EstadoNombre,
          item.Observaciones,
        ];

        return fields.some((field) => matchesSearchText(searchText, field));
      },
    );
  }, [accountingMovements, searchText]);

  return (
    <>
      {accountingMovements.isPending && <Skeleton width="100%" height="100%" />}
      {!accountingMovements.isPending && (
        <CardTable<IAccountingMovement>
          rows={10}
          type="data"
          resizableColumns
          columns={columns}
          className="flex-1"
          onSearch={onSearch}
          dataKey="AsientoId"
          removableSort={true}
          hideSelectFilter={false}
          columnResizeMode="expand"
          valueFilter={filterSelected}
          title="Movimientos contables"
          filterOptions={filtersOptions}
          emptyMessage={renderEmptyMessage()}
          loading={accountingMovements.isPending}
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          onRefresh={() => accountingMovements.refetch()}
          paginator={filteredAccountingMovements.length > 10}
          onSelectFilterOption={(value) => setFilterSelected(value)}
          onClickFilterSettings={EventUtils.callEvent(
            setFilterSettingsVisible,
            true,
          )}
          value={sort(
            filteredAccountingMovements,
            (item) => item.AsientoId,
            true,
          )}
          headActions={[
            <Button
              size="small"
              key="ADD_BTN"
              label="Agregar movimiento contable"
              loading={accountingMovements.isFetching}
              icon={<Icon name="replace" className="mr-2" />}
              onClick={() => navigate(CREATE_ROUTE, { relative: "route" })}
            />,
          ]}
          tableActions={(rowData) => [
            {
              label: "Detalles",
              icon: "list-details",
              onClick: () =>
                navigate(`${rowData.AsientoId}`, { relative: "route" }),
            },
            {
              icon: "edit",
              label: "Editar",
              disabled: rowData.EstadoId !== Statuses.DRAFT_CODE,
              onClick: () =>
                navigate(
                  generatePath(EDIT_ROUTE, { id: `${rowData.AsientoId}` }),
                  {
                    relative: "route",
                  },
                ),
            },
            {
              icon: "trash",
              label: "Anular",
              disabled: rowData.EstadoId === Statuses.NULLIFIED_CODE,
              onClick: () => {
                confirmDialog({
                  acceptLabel: "Anular",
                  rejectLabel: "Cancelar",
                  message: "Esta acción no podrá deshacerse.",
                  header: `¿Estás seguro(a) de anular el asiento ${rowData.Secuencia}?`,
                  accept: () =>
                    toast.success(
                      `El asiento ${rowData.Secuencia} ha sido anulado.`,
                      {
                        dismissible: true,
                        closeButton: true,
                        style: { fontSize: 14 },
                      },
                    ),
                });
              },
            },
          ]}
        />
      )}
      <ConfirmDialog />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
    </>
  );
};

export default AccountingMovements;
