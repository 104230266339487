import React from "react";
import { Icon } from "@nubeteck/icons";
import { ConfirmPopup } from "primereact/confirmpopup";
import { Button, ButtonProps } from "primereact/button";

type ResetButton = Omit<
  ButtonProps,
  "icon" | "title" | "label" | "onClick" | "outlined" | "severity"
> & { accept: () => void };

const ResetButton = ({ accept, ...props }: ResetButton) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const button = React.useRef<Button>(null);

  return (
    <>
      <Button
        {...props}
        outlined
        ref={button}
        size="small"
        label="Restablecer"
        className={props.className}
        title="Restablecer formulario"
        onClick={() => setVisible(true)}
        icon={<Icon size={20} name="reload" className="md:mr-2" />}
      />
      <ConfirmPopup
        accept={accept}
        visible={visible}
        onHide={() => setVisible(false)}
        target={button.current as never}
        message="¿Confirma restablecer el formulario?"
        icon={<Icon className="text-red-500" name="exclamation-circle" />}
      />
    </>
  );
};

export default ResetButton;
