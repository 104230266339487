import API from "@lib/api";
import { BaseApi } from "@api";
import { FakerUtils } from "@utils";
import { Messages } from "@interfaces";

import { IReferenceDocument } from "../interfaces";

export class ReferenceDocumentApi extends BaseApi {
  protected static api = API({ namespace: "Documentos" });
  protected static messages: Messages<typeof ReferenceDocumentApi> = {};

  public static async getByAccountingMovementId(
    id: number,
  ): Promise<IReferenceDocument[]> {
    const result = FakerUtils.getByAccountingMovementId(id);
    return result.data;
  }
}
