import React from "react";
import { IRouter } from "@interfaces";
import { useNavigation } from "@hooks";
import { NavLink, INavLinkProps } from "@nubeteck/prime";

const Navbar = () => {
  const { features } = useNavigation();

  const formatNavbarItems = React.useCallback(
    (route: IRouter, parentPath: string): undefined | INavLinkProps => {
      if (!route?.sidebar) return;

      const path = `${parentPath}${route.path}`;

      const items = route.children?.reduce<INavLinkProps[]>((acc, child) => {
        const item = formatNavbarItems(child, path);
        if (item) return [...acc, item];
        return acc;
      }, []);

      return {
        to: path,
        items: items ?? [],
        title: route.title,
        icon: route.iconName ?? "error-404",
      };
    },
    [],
  );

  const items = React.useMemo(() => {
    return features?.reduce<INavLinkProps[]>((acc, route) => {
      const routeFormatted = formatNavbarItems(route, "");
      if (routeFormatted) return [...acc, routeFormatted];
      return acc;
    }, []);
  }, [features, formatNavbarItems]);

  return (
    <nav id="sidebar-navbar" className="flex flex-column w-full gap-1">
      <ul className="p-0 m-0 list-none">
        <NavLink to="/" items={[]} icon="home" title="Inicio" />
        {!!items?.length &&
          items?.map((item) => <NavLink key={item.title} {...item} />)}
      </ul>
    </nav>
  );
};

export default Navbar;
