import React from "react";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { ButtonGroup } from "primereact/buttongroup";
import { Card, useHeaderActions } from "@nubeteck/prime";

const TemplatesPage = () => {
  const { dispatch } = useHeaderActions();

  React.useEffect(() => {
    dispatch({
      type: "SET_ACTIONS",
      payload: [
        <ButtonGroup key="hello">
          <Button
            text
            label="Save"
            size="small"
            icon={<Icon size={20} name="check" className="mr-1" />}
          />
          <Button
            text
            size="small"
            label="Delete"
            icon={<Icon size={18} name="trash" className="mr-1" />}
          />
          <Button text size="small" label="Cancel" />
        </ButtonGroup>,
      ],
    });
    return () => dispatch({ type: "CLEAR_ACTIONS" });
  }, [dispatch]);

  return (
    <Card
      title="Plantillas"
      subtitle="Plantillas de la aplicación"
      actions={[
        <Button
          size="small"
          key="Button1"
          label="Settings"
          className="p-button-outlined mr-2"
        />,
        <Button size="small" key="Button2" label="Compose" />,
      ]}
    >
      <div className="card flex flex-wrap align-items-center justify-content-center gap-2">
        <Button size="small" label="Small" />
        <Button label="Normal" />
        <Button size="large" label="Large" />
      </div>
    </Card>
  );
};

export default TemplatesPage;
