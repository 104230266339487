import { FakeData } from "../../core";
import { Summary, AgeAnalysis } from "../../components";

const Dashboard = () => {
  return (
    <div className="py-2 flex flex-column gap-3 h-full">
      <Summary summary={FakeData.summary} />
      <AgeAnalysis ageAnalysis={FakeData.age_analysis} />
      {/* Clients */}
      {/* Invoices */}
      {/* Payments */}
      {/* Actions */}
    </div>
  );
};

export default Dashboard;
