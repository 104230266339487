import React from "react";
import { TabView, TabPanel } from "primereact/tabview";

import {
  CurrencyTab,
  ReconciliationTab,
  BankingTransactionsTab,
} from "../../components";

interface TabItem {
  title: string;
  content: React.ReactNode;
}

const ReconciliationPage = () => {
  const tabs: TabItem[] = [
    {
      title: "Asientos pendientes",
      content: <ReconciliationTab />,
    },
    {
      content: <CurrencyTab />,
      title: "Historial de tasas",
    },
    {
      title: "Movimientos bancarios",
      content: <BankingTransactionsTab />,
    },
  ];

  return (
    <TabView scrollable panelContainerClassName="p-0">
      {tabs.map(({ title, content }) => (
        <TabPanel key={title} header={title}>
          {content}
        </TabPanel>
      ))}
    </TabView>
  );
};

export default ReconciliationPage;
