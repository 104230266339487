import clsx from "clsx";
import { Icon } from "@nubeteck/icons";
import { renderTooltip } from "@utils";
import { Button, ButtonProps } from "primereact/button";

type AttachmentButton = Omit<
  ButtonProps,
  "icon" | "title" | "label" | "outlined" | "severity"
>;

const AttachmentButton = ({ ...props }: AttachmentButton) => {
  return (
    <>
      {renderTooltip("Adjuntar archivos", "attachment_btn")}
      <Button
        {...props}
        text
        size="small"
        className={clsx(
          props.className,
          "p-0 align-self-center attachment_btn",
        )}
      >
        <Icon size={24} name="paperclip" />
      </Button>
    </>
  );
};

export default AttachmentButton;
