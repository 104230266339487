import clsx from "clsx";
import React from "react";
import { select } from "radash";
import { IFilter } from "@interfaces";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { useGlobalQuery, useGlobalMutation } from "@hooks";
import { Dialog, HeaderIcon, IDialogProps } from "@nubeteck/prime";
import {
  Form,
  useForm,
  Dropdown,
  InputText,
  InputSwitch,
  SelectButton,
  useFieldArray,
} from "@nubeteck/forms";

interface IFiltersFormDialogProps extends IDialogProps {
  area: string;
  filterId?: number;
}

type FormValues = Pick<
  IFilter,
  | "Nombre"
  | "FiltroId"
  | "EstadoId"
  | "FiltroAreaId"
  | "EsPorDefecto"
  | "FiltrosParametros"
>;

const model = {
  from: (data: IFilter): FormValues => {
    return data;
  },
  to: (data: FormValues): IFilter => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      FiltroId: data.FiltroId,
      EsPorDefecto: data.EsPorDefecto,
      FiltroAreaId: data.FiltroAreaId,
      FiltrosParametros: data.FiltrosParametros?.map((param, index) => ({
        Orden: index,
        ...param,
        OperadorLogico: index === 0 ? null : param.OperadorLogico,
      })),
    };
  },
};

const FiltersFormDialog = ({
  area,
  filterId,
  ...props
}: IFiltersFormDialogProps) => {
  const getCreate = useGlobalQuery("Filters", "getCreate");
  const createFilter = useGlobalMutation("Filters", "create");
  const updateFilter = useGlobalMutation("Filters", "update");
  const properties = useGlobalQuery("Filters", "propertiesByArea", area, {
    enabled: !!area,
  });
  const { data: filter, isFetching: isFilterFetching } = useGlobalQuery(
    "Filters",
    "getById",
    filterId,
    { enabled: !!filterId },
  );

  const form = useForm<FormValues>({
    defaultValues: {
      Nombre: "",
      EstadoId: 1,
      EsPorDefecto: false,
      FiltroAreaId: undefined,
      FiltrosParametros: [
        {
          Campo: "",
          Valor1: "",
          Valor2: undefined,
          OperadorLogico: "AND",
          FiltroCondicionId: undefined,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "FiltrosParametros",
  });

  React.useEffect(() => {
    if (filter) {
      form.reset(model.from(filter), { keepDefaultValues: true });
    }
  }, [form, filter]);

  React.useEffect(() => {
    if (getCreate.data?.FiltrosAreasSelect?.length) {
      form.setValue(
        "FiltroAreaId",
        select(
          getCreate.data?.FiltrosAreasSelect ?? [],
          (item) => item.FiltroAreaId,
          (item) => item.FiltroAreaNombre === area,
        )[0],
      );
    }
  }, [area, getCreate.data?.FiltrosAreasSelect, form]);

  const handleOnHide = () => {
    form.reset();
    props.onHide();
  };

  const onSubmit = (values: FormValues) => {
    if (filterId) {
      return updateFilter.mutateAsync(model.to(values), {
        onSuccess: handleOnHide,
      });
    }

    return createFilter.mutateAsync(model.to(values), {
      onSuccess: handleOnHide,
    });
  };

  const renderSection = (index: number) => {
    const name = `FiltrosParametros.${index}`;
    const condition = form.watch(
      `FiltrosParametros.${index}.FiltroCondicionId`,
    );

    return (
      <div className="flex flex-1 align-items-center gap-2">
        {index !== 0 && (
          <SelectButton
            label="Operador"
            options={["AND", "OR"]}
            name={`${name}.OperadorLogico`}
          />
        )}
        <Dropdown
          label="Campo"
          name={`${name}.Campo`}
          fieldClassName="flex-1"
          className="p-inputtext-sm"
          options={propertiesOptions}
          loading={properties.isPending}
          placeholder="Selecciona el campo"
          rules={getCreate.data?.ValidacionesFiltrosParametros?.["Campo"]}
        />
        <Dropdown
          label="Condición"
          fieldClassName="flex-1"
          className="p-inputtext-sm"
          options={conditionsOptions}
          loading={getCreate.isPending}
          name={`${name}.FiltroCondicionId`}
          placeholder="Selecciona la condición"
          rules={{ required: "Campo requerido" }}
        />
        <InputText
          label="Valor 1"
          name={`${name}.Valor1`}
          fieldClassName="flex-1"
          className="p-inputtext-sm"
          placeholder="Ingresa un valor"
          rules={getCreate.data?.ValidacionesFiltrosParametros?.["Valor1"]}
        />
        {condition === 7 && (
          <InputText
            label="Valor 2"
            name={`${name}.Valor2`}
            fieldClassName="flex-1"
            className="p-inputtext-sm"
            placeholder="Ingresa un valor"
            rules={getCreate.data?.ValidacionesFiltrosParametros?.["Valor2"]}
            {...(condition === 7 && {
              rules: { required: "Campo requerido" },
            })}
          />
        )}
        {fields.length >= 2 && (
          <div className="flex mt-2">
            <HeaderIcon
              name="trash"
              className="text-red-500"
              onClick={EventUtils.callEvent(remove, index)}
            />
          </div>
        )}
      </div>
    );
  };

  const conditionsOptions = React.useMemo(() => {
    if (!getCreate.data?.FiltrosCondicionesSelect?.length) return [];
    return ArrayUtils.selectLabelValue(
      getCreate.data?.FiltrosCondicionesSelect,
      (condition) =>
        `${condition.FiltroCondicionNombre} (${condition.FiltroCondicionSigno})`,
      "FiltroCondicionId",
    );
  }, [getCreate.data?.FiltrosCondicionesSelect]);

  const propertiesOptions = React.useMemo(() => {
    if (!properties.data?.length) return [];
    return properties.data.map((prop) => ({ label: prop, value: prop }));
  }, [properties.data]);

  return (
    <Dialog
      {...props}
      style={{ width: "75%" }}
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (filterId) form.reset();
        props.onHide();
      }}
      title={
        filterId ? `Editando filtro '${filter?.Nombre ?? ""}'` : "Nuevo filtro"
      }
      footer={
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={handleOnHide}
          />
          <Button
            size="small"
            loading={isFilterFetching}
            onClick={form.handleSubmit(onSubmit)}
            label={filterId ? "Guardar cambios" : "Crear filtro"}
          />
        </div>
      }
    >
      {isFilterFetching && filterId ? (
        <div>
          <Skeleton className="mb-2" />
          <Skeleton height="2.3rem" className="mb-2" />
          <Skeleton height="12rem" />
        </div>
      ) : (
        <Form form={form}>
          <div className="flex flex-column md:flex-row md:gap-3">
            <InputText
              name="Nombre"
              label="Nombre"
              fieldClassName="flex-1"
              className="p-inputtext-sm"
              placeholder="Ingresa el nombre del filtro"
              rules={getCreate.data?.Validaciones?.["Nombre"]}
            />
            <InputSwitch
              name="EsPorDefecto"
              label="Por defecto"
              fieldClassName="flex-1"
            />
          </div>
          <div className={clsx(fields.length ? "" : "hidden")}>
            {fields.map((field, index) => {
              return (
                <React.Fragment key={`${field.id}${index}`}>
                  {renderSection(index)}
                </React.Fragment>
              );
            })}
          </div>
          <div className="flex gap-3">
            <Button
              size="small"
              type="button"
              className="flex w-auto"
              label="Agregar parámetro"
              onClick={() =>
                append({ OperadorLogico: "AND" } as never, {
                  shouldFocus: true,
                })
              }
            />
          </div>
        </Form>
      )}
    </Dialog>
  );
};

export default FiltersFormDialog;
