import { IAccountingMovementDetailForm } from "../interfaces";

export const defaultMovementDetail: IAccountingMovementDetailForm = {
  Debito: 0,
  Credito: 0,
  AsientoId: 0,
  DetalleAsientoId: 0,
  ProyectoId: undefined,
  EmpleadoId: undefined,
  Descripcion: undefined,
  CentroCostoId: undefined,
  CentroGananciaId: undefined,
  CuentaContableId: undefined,
};
