import clsx from "clsx";
import React from "react";
import { CurrencyCode } from "@core";
import { Icon } from "@nubeteck/icons";
import { FormatterUtils } from "@utils";
import { Button } from "primereact/button";
import { ConfirmPopup } from "primereact/confirmpopup";

export type AccountingSummary = {
  totalDebits: number;
  totalCredits: number;
  difference: number;
  currencyIso: keyof typeof CurrencyCode;
};

interface ITabsFooterProps {
  addDetail: () => void;
  showRemoveAll: boolean;
  isFormLoading?: boolean;
  removeDetails: () => void;
  summary: AccountingSummary;
}

const TabsFooter = ({
  summary,
  addDetail,
  showRemoveAll,
  removeDetails,
  isFormLoading = false,
}: ITabsFooterProps) => {
  const [confirmVisible, setConfirmVisible] = React.useState<boolean>(false);
  const button = React.useRef<Button>(null);
  return (
    <div className="flex justify-content-between align-items-start py-3 lg:py-0">
      <div className="flex-1 flex flex-column lg:flex-row gap-3 align-items-center">
        <Summary summary={summary} />
        <div className="flex gap-2">
          {showRemoveAll && (
            <Button
              outlined
              size="small"
              type="button"
              severity="danger"
              className="w-fit"
              loading={isFormLoading}
              onClick={() => setConfirmVisible(true)}
              icon={<Icon name="copy-minus" className="md:mr-2" />}
            >
              <span className="font-semibold hidden md:block">
                Eliminar todas las líneas
              </span>
            </Button>
          )}
          <Button
            outlined
            ref={button}
            size="small"
            type="button"
            className="w-fit"
            onClick={addDetail}
            loading={isFormLoading}
            icon={<Icon name="cube-plus" className="md:mr-2" />}
          >
            <span className="font-semibold hidden md:block">
              Agregar otro detalle
            </span>
          </Button>
        </div>
      </div>
      <ConfirmPopup
        accept={removeDetails}
        visible={confirmVisible}
        target={button.current as never}
        onHide={() => setConfirmVisible(false)}
        message="¿Confirma eliminar todas las líneas?"
        icon={<Icon color="#e74c3c" name="exclamation-circle" />}
      />
    </div>
  );
};

const SummaryItem = ({
  value,
  label,
  currencyIso,
}: {
  value: number;
  label: string;
  currencyIso: keyof typeof CurrencyCode;
}) => (
  <div>
    <span className="font-medium">{label}: </span>
    <span>{FormatterUtils.currency(value, { currencyIso })}</span>
  </div>
);

const Summary = ({
  summary: { difference, totalDebits, currencyIso, totalCredits },
}: {
  summary: AccountingSummary;
}) => (
  <div className="flex-1 flex flex-column md:flex-row align-items-center gap-3">
    <SummaryItem
      value={totalDebits}
      label="Total débitos"
      currencyIso={currencyIso}
    />
    <SummaryItem
      value={totalCredits}
      label="Total créditos"
      currencyIso={currencyIso}
    />
    <div className={clsx(difference !== 0 ? "text-red-500" : "text-green-600")}>
      <SummaryItem
        value={difference}
        label="Diferencia"
        currencyIso={currencyIso}
      />
    </div>
  </div>
);

export default TabsFooter;
