import React from "react";
import {
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
  PieChart as RePieChart,
} from "recharts";

export interface IPieChartProps {
  legendTitle?: string;
  data: { label: string; value: string | number; color?: string }[];
}

const PieChart = ({ data = [] }: IPieChartProps) => {
  const documentStyle = getComputedStyle(document.documentElement);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RePieChart>
        <Tooltip />
        <Legend />
        <Pie data={data} nameKey="label" dataKey="value">
          {data.map((entry) => (
            <Cell
              key={entry.value}
              fill={documentStyle.getPropertyValue(
                entry.color ?? "--primary-500",
              )}
            />
          ))}
        </Pie>
      </RePieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
