import React from "react";
import { StatisticGroup, IStatsCardProps } from "@components";

import { IDashboardSummary } from "../../interfaces";

interface SummaryProps {
  summary: IDashboardSummary;
}

const Summary = ({ summary }: SummaryProps) => {
  const { cei, dso, totalCuentasVencidas, totalCuentasPorCobrar } = summary;

  const stats: IStatsCardProps[] = React.useMemo(
    () => [
      {
        icon: "cash",
        value: totalCuentasPorCobrar,
        title: "Total de cuentas por cobrar",
        description: "Facturas pendientes de pago.",
      },
      {
        icon: "alert-circle",
        value: totalCuentasVencidas,
        title: "Total de cuentas vencidas",
        description: "Facturas vencidas sin pagar.",
      },
      {
        value: dso,
        icon: "clock",
        title: "Días promedio de cobro",
        description: "Velocidad del cobro.",
      },
      {
        value: cei,
        icon: "chart-bar",
        type: "percentage",
        title: "Índice de efectividad de cobro",
        description: "Eficiencia de la recaudación.",
      },
    ],
    [cei, dso, totalCuentasPorCobrar, totalCuentasVencidas],
  );

  return <StatisticGroup stats={stats} />;
};

export default Summary;
