import { IRouter } from "@interfaces";
import { Outlet } from "react-router-dom";

import { DashboardPage } from "./pages/dashboard";
import {
  AccountsReceivableRoutes,
  AccountsReceivableRouteNames,
} from "./routes";

const router: IRouter = {
  sidebar: true,
  element: <Outlet />,
  iconName: "file-dollar",
  title: "Cuentas por cobrar",
  id: "accounts-receivable-root",
  path: AccountsReceivableRoutes[
    AccountsReceivableRouteNames.DASHBOARD_PAGE
  ].template(),
  children: [
    {
      path: "",
      index: true,
      sidebar: true,
      iconName: "dashboard",
      title: "Panel de control",
      element: <DashboardPage />,
      id: "accounts-receivable-page",
    },
  ],
};

export default router;
