import React from "react";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import {
  useNavigate,
  useRouteError,
  isRouteErrorResponse,
} from "react-router-dom";

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const subtitle = React.useMemo(() => {
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return "Esta pagina a la que intenta acceder no existe o ya no esta disponible.";
      }

      return "Hubo un error en la aplicación, reinicie o navegue hasta la pantalla principal.";
    }

    return "Ha ocurrido un error desconocido.";
  }, [error]);

  return (
    <div className="flex flex-1 flex-column h-screen align-content-center justify-content-center">
      <div
        className="text-center"
        style={{
          background:
            "radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%)",
        }}
      >
        <span className="surface-ground text-pink-500 font-bold text-4xl inline-block px-3">
          {(isRouteErrorResponse(error) && error?.status) || 400}
        </span>
      </div>
      <div className="mt-4 mb-2 font-bold text-6xl text-900 text-center">
        Hubo un error en la aplicación
      </div>
      <p className="text-700 text-xl mt-0 mb-4 text-center">{subtitle}</p>
      <div className="flex justify-content-center gap-2 text-center">
        <Button
          text
          label="Volver"
          onClick={() => navigate(-1)}
          icon={<Icon size={20} className="mr-1" name="arrow-left" />}
        />
        <Button
          onClick={() => navigate("/")}
          label="Ir a la pagina principal"
          icon={<Icon size={20} name="home" className="mr-1" />}
        />
      </div>
    </div>
  );
};

export default ErrorBoundary;
