import { route } from "typesafe-react-router";

export enum SettingsRouteNames {
  SETTINGS = "SETTINGS",
  TEMPLATES = "TEMPLATES",
}

export const SettingsRoutes = {
  [SettingsRouteNames.SETTINGS]: route("settings"),
  [SettingsRouteNames.TEMPLATES]: route("templates"),
};
