import API from "@lib/api";
import { Messages } from "@interfaces";

export class BaseApi {
  protected static api = API({});
  protected static messages: Messages<typeof BaseApi> = {};

  static getMessage(method: string) {
    return this.messages[method as keyof typeof this.messages];
  }
}
