import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  BarChart as BarChartRe,
} from "recharts";

export interface IBarChartProps {
  legendTitle?: string;
  data: { label: string; value: string | number; color?: string }[];
}

const BarChart = ({ data }: IBarChartProps) => {
  // const documentStyle = getComputedStyle(document.documentElement);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChartRe data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar fill="#8884d8" dataKey="value" />
        <Bar fill="#82ca9d" dataKey="value" />
      </BarChartRe>
    </ResponsiveContainer>
  );
};

export default BarChart;
