import { IRouter } from "@interfaces";

import { SettingsLayout } from "./components";
import { SettingsPage, TemplatesPage } from "./pages";
import { SettingsRoutes, SettingsRouteNames } from "./routes";

const router: IRouter = {
  iconName: "settings",
  element: <SettingsLayout />,
  id: "feature-settings-root",
  title: "Configuraciones Generales",
  path: SettingsRoutes[SettingsRouteNames.SETTINGS].template(),
  children: [
    {
      path: "",
      index: true,
      iconName: "settings",
      element: <SettingsPage />,
      id: "feature-settings-page",
      title: "Configuraciones Generales",
    },
    {
      iconName: "plus",
      title: "Plantillas",
      element: <TemplatesPage />,
      id: "feature-settings-templates-page",
      path: SettingsRoutes[SettingsRouteNames.TEMPLATES].template(),
    },
  ],
};

export default router;
