import React from "react";
import { Card } from "@nubeteck/prime";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { PieChart, BarChart, StatsCard, LineChart } from "@components";

import { useLedgerQuery } from "../../hooks";

interface IChartCardProps {
  title: string;
  data: React.ComponentProps<typeof PieChart>["data"];
}

const ChartCard = ({ data, title }: IChartCardProps) => {
  const [chartType, setChartType] = React.useState("pie");

  const options = [
    {
      value: "pie",
      label: "Gráfico pastel",
    },
    {
      value: "bar",
      label: "Gráfico de barra",
    },
    {
      value: "line",
      label: "Gráfico de linea",
    },
  ];

  return (
    <Card
      title={title}
      actions={[
        <Dropdown
          value={chartType}
          options={options}
          key="DropdownChartType"
          placeholder="Selecciona"
          className="p-inputtext-sm"
          onChange={(e) => setChartType(e.value)}
        />,
      ]}
    >
      <div key={chartType} style={{ width: "100%", minWidth: "18rem" }}>
        {chartType === "pie" && <PieChart data={data} />}
        {chartType === "bar" && <BarChart data={data} />}
        {chartType === "line" && <LineChart data={data} />}
      </div>
    </Card>
  );
};

const DashboardPage = () => {
  const stats = useLedgerQuery("Dashboard", "getStats");

  const data = stats.data?.map((stat) => ({
    value: stat.value,
    label: stat.title,
    color: "--primary-500",
  }));

  return (
    <div className="flex flex-column gap-3 py-2">
      <div className="flex flex-column gap-2">
        <div className="flex flex-column sm:flex-row sm:justify-content-between sm:align-items-center gap-2">
          <h1 className="text-xl font-semibold m-0">Estado de situación</h1>
          <Calendar
            locale="es"
            readOnlyInput
            hideOnRangeSelection
            selectionMode="range"
            placeholder="Seleccione un rango de fecha"
            className="w-full sm:w-20rem p-inputtext-sm"
          />
        </div>
        <div className="grid">
          {stats.data?.map((stat) => (
            <div key={stat.title} className="col-12 md:col-6 lg:col-3">
              <StatsCard {...stat} />
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-column flex-wrap lg:flex-row gap-3">
        <ChartCard data={data ?? []} title="Estado de resultado" />
        <ChartCard data={data ?? []} title="Tareas pendiente" />
      </div>
    </div>
  );
};

export default DashboardPage;
