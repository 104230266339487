import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useParams } from "react-router-dom";
import { PickNullable } from "ClientApp/interfaces/helpers";
import {
  Form,
  useForm,
  useWatch,
  InputText,
  MultiSelect,
  InputTextarea,
} from "@nubeteck/forms";

import { ICatalogoCuentaDetalles } from "../../interfaces";
import { useLedgerQuery, useLedgerMutation } from "../../hooks";

interface IFinancialStatementDivision {
  Codigo: string;
  Nombre: string;
  EstadoFinancieroId: number;
  EstadoFinancieroDivisionId: number;
}

interface IFinancialStatement {
  Nombre: string;
  EstadoFinancieroId: number;
  TipoEstadoFinancieroId: number;
  EstadoFinancieroDivisiones: IFinancialStatementDivision[];
}

interface IAccountCatalogFormProps {
  edit: boolean;
  open: boolean;
  onClose?: () => void;
  cuentaPadreId?: number;
}

type FormValues = PickNullable<
  ICatalogoCuentaDetalles,
  | "Nivel"
  | "Activa"
  | "Cuenta"
  | "CuentaId"
  | "EstadoId"
  | "EsControl"
  | "CatalogoId"
  | "Descripcion"
  | "CuentaNombre"
  | "NaturalezaId"
  | "CuentaPadreId"
  | "Clasificacion"
  | "DivisionesIds"
  | "PermiteCambios"
>;

const model = {
  to: (data: FormValues): ICatalogoCuentaDetalles => {
    return {
      Nivel: 0,
      EstadoId: 1,
      Activa: true,
      EsControl: true,
      PermiteCambios: true,
      Cuenta: data.Cuenta ?? "",
      CuentaId: data.CuentaId ?? 0,
      CatalogoId: data.CatalogoId ?? 0,
      Descripcion: data.Descripcion ?? "",
      CuentaNombre: data.CuentaNombre ?? "",
      CuentaPadreId: data.CuentaPadreId ?? 0,
      DivisionesIds: data.DivisionesIds ?? [],
    };
  },
  from: (data: ICatalogoCuentaDetalles): FormValues => {
    return {
      Nivel: data?.Nivel ?? 0,
      CuentaId: data?.CuentaId,
      EstadoId: data?.EstadoId,
      Cuenta: data?.Cuenta ?? "",
      EsControl: data?.EsControl,
      Activa: data?.Activa ?? true,
      NaturalezaId: data?.NaturalezaId,
      CatalogoId: data?.CatalogoId ?? 0,
      Clasificacion: data?.Clasificacion,
      DivisionesIds: data?.DivisionesIds,
      PermiteCambios: data?.PermiteCambios,
      Descripcion: data?.Descripcion ?? "",
      CuentaNombre: data?.CuentaNombre ?? "",
      CuentaPadreId: data?.CuentaPadreId ?? 0,
    };
  },
};

const AccountCatalogForm = ({
  edit,
  open,
  onClose,
  cuentaPadreId,
}: IAccountCatalogFormProps) => {
  const { id } = useParams();
  const form = useForm<FormValues>({
    defaultValues: {
      CatalogoId: Number(id ?? 0),
    },
  });
  const createCatalog = useLedgerMutation("AccountCatalog", "createDetails");
  const updateCatalog = useLedgerMutation("AccountCatalog", "updateDetails");
  const selectedDivisions = useWatch({
    name: "DivisionesIds",
    control: form.control,
  });

  const {
    data: accountCatalogDetail,
    isFetching: accountCatalogDetailFetching,
  } = useLedgerQuery("AccountCatalog", "getUpdateDetalle", cuentaPadreId, {
    enabled: !!cuentaPadreId,
  });

  const { data: parentAccountName } = useLedgerQuery(
    "AccountCatalog",
    "getUpdateDetalle",
    accountCatalogDetail?.CuentaPadreId,
    {
      enabled: !!accountCatalogDetail?.CuentaPadreId,
    },
  );

  const divisionOptions = React.useMemo(() => {
    if (!accountCatalogDetail?.DivisionesSelect?.length) {
      return [];
    }

    const selectedDivisionsSet = new Set(selectedDivisions);

    return accountCatalogDetail.DivisionesSelect.map(
      (financialStatement: IFinancialStatement) => {
        const hasSelectedDivisions =
          financialStatement.EstadoFinancieroDivisiones.some((division) =>
            selectedDivisionsSet.has(division.EstadoFinancieroDivisionId),
          );

        return {
          label: financialStatement.Nombre,
          items: financialStatement.EstadoFinancieroDivisiones.map(
            (division) => ({
              label: division.Codigo,
              value: division.EstadoFinancieroDivisionId,
              disabled:
                hasSelectedDivisions &&
                !selectedDivisionsSet.has(division.EstadoFinancieroDivisionId),
            }),
          ),
        };
      },
    );
  }, [accountCatalogDetail?.DivisionesSelect, selectedDivisions]);

  React.useEffect(() => {
    const resetForm = (data?: ICatalogoCuentaDetalles) => {
      const formData = data ? model.from(data) : undefined;
      form.reset(formData, { keepDefaultValues: true });
    };

    if (edit && accountCatalogDetail) {
      resetForm({
        ...accountCatalogDetail,
        DivisionesIds: accountCatalogDetail.DivisionesIds,
      });
    } else if (!edit) {
      resetForm();
    }
  }, [accountCatalogDetail, edit, form]);

  const onSubmit = (values: FormValues) => {
    const mutateFunction = edit ? updateCatalog : createCatalog;
    const mutateData = edit
      ? { ...values, DivisionesIds: selectedDivisions }
      : { ...values, CuentaPadreId: Number(cuentaPadreId) };

    return mutateFunction.mutateAsync(model.to(mutateData), {
      onSuccess: handleOnClose,
    });
  };

  const handleOnClose = React.useCallback(() => {
    form.reset();
    onClose?.();
  }, [form, onClose]);

  return (
    <Dialog
      visible={open}
      onHide={handleOnClose}
      contentClassName="py-0"
      className="w-11 max-w-26rem"
      header={edit ? "Editar cuenta" : "Crear cuenta"}
      headerClassName={accountCatalogDetail?.CuentaPadreId ? "pb-0" : "pb-2"}
      footer={
        <Button size="small" onClick={form.handleSubmit(onSubmit)}>
          Guardar
        </Button>
      }
    >
      {accountCatalogDetail?.CuentaPadreId != null && (
        <div className="flex align-items-center flex-row">
          <p>Cuenta padre:</p>
          {parentAccountName && (
            <h4 className="pl-2 m-0">{parentAccountName.CuentaNombre}</h4>
          )}
        </div>
      )}
      <Form form={form}>
        <InputText
          label="Nombre"
          name="CuentaNombre"
          placeholder="Nombre"
          className="p-inputtext-sm"
          disabled={accountCatalogDetailFetching}
          rules={{ required: "Este campo es necesario" }}
        />
        <InputTextarea
          name="Descripcion"
          label="Descripción"
          placeholder="Descripción"
          className="p-inputtext-sm"
          disabled={accountCatalogDetailFetching}
          rules={{ required: "Este campo es necesario" }}
        />
        <MultiSelect
          label="Divisiones"
          name="DivisionesIds"
          optionGroupLabel="label"
          options={divisionOptions}
          optionGroupChildren="items"
          className="p-multiselect-sm"
          placeholder="Selecciona una división"
          loading={accountCatalogDetailFetching}
          rules={{ required: "Este campo es necesario" }}
        />
      </Form>
    </Dialog>
  );
};

export default AccountCatalogForm;
