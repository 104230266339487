import API from "@lib/api";
import { BaseApi } from "@api";
import {
  Messages,
  IExchangeRates,
  IExchangeRatesCreate,
  IExchangeRatesDateRangeParams,
} from "@interfaces";

import { TASA_CAMBIO_DOLLAR } from "../core/constants/constants";

export class ExchangeRatesApi extends BaseApi {
  protected static api = API({ namespace: "TasaCambio" });
  protected static readonly filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof ExchangeRatesApi> = {
    create: {
      loading: "Creando tasa de cambio...",
      success: "Tasa de cambio creada correctamente.",
      error: (error) => `Hubo un error al crear la tasa de cambio: ${error}`,
    },
    update: {
      loading: "Actualizando tasa de cambio...",
      success: "Tasa de cambio actualizada correctamente.",
      error: (error) =>
        `Hubo un error al actualizar la tasa de cambio: ${error}`,
    },
  };

  public static async getAll(): Promise<IExchangeRates[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getAllByFilter(
    filterId: number,
  ): Promise<IExchangeRates[]> {
    const result = await this.filterApi.get<IExchangeRates[]>(
      `FiltroPorArea/${filterId}`,
    );
    return result.data;
  }

  public static async create(data: IExchangeRates): Promise<IExchangeRates> {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async getCreate(): Promise<IExchangeRatesCreate> {
    const result = await this.api.get("Create");
    return result.data;
  }

  public static async update(data: IExchangeRates): Promise<IExchangeRates> {
    const result = await this.api.put(`Update/${data.TasaCambioId}`, data);
    return result.data;
  }

  public static async getById(id: number): Promise<IExchangeRates> {
    const result = await this.api.get(`Update/${id}`);
    return result.data;
  }

  public static async getDollarRate() {
    const result = await this.api.get(`${TASA_CAMBIO_DOLLAR}`);
    return result.data;
  }

  public static async getByDateRange(
    data: IExchangeRatesDateRangeParams,
  ): Promise<IExchangeRates[]> {
    const result = await this.api.get(
      `GetTasaCambioRangoFeha?Fecha1=${data.fechaDesde}&Fecha2=${data.fechaHasta}&MonedaId=${data.monedaId}`,
    );
    return result.data;
  }
}
