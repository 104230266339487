import clsx from "clsx";
import React from "react";
import { last } from "radash";
import { Config } from "@core";
import nProgress from "nprogress";
import { template } from "@utils";
import { Icon } from "@nubeteck/icons";
import { ScrollTop } from "primereact/scrolltop";
import { BreadCrumb } from "primereact/breadcrumb";
import { Outlet, useParams, useMatches } from "react-router-dom";
import { useBreadcrumb, useHeaderActions } from "@nubeteck/prime";

import { Header } from "../header";
import { Sidebar } from "../sidebar";

import "./main-layout.css";

const MainLayout = () => {
  const params = useParams();
  const matches = useMatches();
  const { items } = useBreadcrumb();
  const { actions, variables } = useHeaderActions();

  React.useMemo(() => {
    nProgress.start();
  }, []);

  React.useEffect(() => {
    nProgress.done();
  }, []);

  const title = React.useMemo(() => {
    return template(last(matches)?.handle?.title ?? "", {
      ...params,
      ...variables,
    });
  }, [matches, params, variables]);

  React.useEffect(() => {
    document.title = `${Config.app_title} | ${title}`;
  }, [title]);

  const showBreadcrumbSection = !!items.length || !!actions.length;

  return (
    <div
      id="main-layout"
      className="w-screen h-screen flex flex-row surface-ground"
    >
      <Sidebar />
      <div className="w-5rem hidden lg:block p-0 m-0" />
      <div
        id="side-content"
        className="flex flex-1 flex-column h-full overflow-hidden"
      >
        <Header title={title} />
        <section
          className={clsx(
            "flex-column lg:flex-row w-full lg:align-items-center justify-content-between px-3",
            actions.length ? "py-1" : "py-2",
            showBreadcrumbSection ? "flex" : "hidden",
          )}
        >
          <BreadCrumb
            className={clsx(!items.length && "hidden")}
            home={{
              url: "/",
              icon: <Icon size={20} name="home" className="text-primary" />,
            }}
            model={items.map((item) => ({
              ...item,
              label: template(item.label ?? "", {
                ...params,
                ...variables,
              }),
            }))}
          />
          <div className={actions.length ? "flex" : "hidden"}>
            {actions.map((action) => (
              <div key={action.key} className="flex pl-2 border-round">
                {action}
              </div>
            ))}
          </div>
        </section>
        <div
          className={clsx(
            "flex-1 overflow-y-auto",
            showBreadcrumbSection ? "px-3 pb-3 pt-1" : "p-3",
          )}
        >
          <Outlet />
          <ScrollTop
            target="parent"
            style={{ width: 40, height: 40 }}
            icon={<Icon name="chevron-up" />}
            className="bg-primary opacity-80 text-white absolute"
          />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
