import React from "react";
import { sort } from "radash";
import { useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Card, Description } from "@nubeteck/prime";
import { TabView, TabPanel } from "primereact/tabview";
import Chart, { ChartWrapperOptions } from "react-google-charts";

import { useLedgerQuery } from "../../hooks";
import { ExchangeRatesTab } from "../../components";

const CurrencyDetailPage = () => {
  const { id } = useParams();

  const currencyId = parseInt(`${id}`, 10);

  const currency = useLedgerQuery("Currency", "getById", currencyId, {
    enabled: !!currencyId,
  });

  const { data: exchangeRate } = useLedgerQuery(
    "ExchangeRates",
    "getByDateRange",
    {
      fechaDesde: "",
      fechaHasta: "",
      monedaId: Number(id),
    },
    {
      enabled: !!Number(id),
    },
  );

  const value = React.useMemo(() => {
    return (
      exchangeRate?.map((rate) => ({
        ...rate,
        Fecha: new Date(rate.Fecha),
      })) ?? []
    );
  }, [exchangeRate]);

  const chartData = React.useMemo(() => {
    const sortedData = sort(value, (item) => item.Fecha.getTime());
    return [
      ["Fecha", "Precio de compra", "Precio de venta"],
      ...sortedData.map((rate) => [
        rate.Fecha,
        rate.PrecioCompra,
        rate.PrecioVenta,
      ]),
    ];
  }, [value]);

  const chartOptions: ChartWrapperOptions["options"] = {
    curveType: "function",
    legend: { position: "bottom" },
    vAxis: {
      title: "Precio",
    },
    hAxis: {
      title: "Fecha",
      format: "dd/MM/yyyy",
    },
  };

  const isPeso = currency?.data?.MonedaId === 1;

  const tabs = [
    {
      title: "Tasas de cambio",
      content: <ExchangeRatesTab />,
    },
  ];

  const items = [
    {
      label: "ISO",
      value: currency.data?.NombreISO ?? "",
    },
    {
      label: "Símbolo",
      value: currency.data?.Simbolo ?? "",
    },
    {
      label: "Locale",
      value: currency.data?.Locale ?? "",
    },
    {
      label: "Es principal",
      value: currency.data?.EsPrincipal ? "Sí" : "No",
    },
  ];

  if (currency.isPending) {
    return (
      <>
        <Skeleton height="5rem" className="mb-2" />
        <Skeleton height="20rem" />
      </>
    );
  }

  return (
    <div className="flex flex-column gap-2 w-full">
      <Description
        columns={2}
        items={items}
        title={currency.data?.MonedaNombre ?? ""}
      />
      {!isPeso && (
        <>
          <TabView scrollable panelContainerClassName="p-0">
            {tabs.map((tab) => (
              <TabPanel key={tab.title} header={tab.title}>
                {tab.content}
              </TabPanel>
            ))}
          </TabView>
          {chartData.length > 1 && (
            <Card>
              <div className="w-full h-full flex flex-column justify-content-center align-items-center">
                <span className="font-bold text-lg text-800">Histórico</span>
                <Chart
                  width="100%"
                  height="100%"
                  data={chartData}
                  chartType="LineChart"
                  options={chartOptions}
                />
              </div>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default CurrencyDetailPage;
