import React from "react";
import { Divider } from "primereact/divider";

import { Navbar } from "../navbar";

import "./sidebar.css";

const Sidebar = () => {
  return (
    <aside
      id="sidebar"
      className="hidden lg:block absolute shadow-1 px-3 top-1 bottom-1 left-1 h-full surface-section transition-duration-500"
    >
      <div className="flex h-3rem justify-content-center align-items-center">
        <img height={30} alt="uce-logo" src="/logo-uce.png" />
      </div>
      <Divider className="m-0 mb-3" />
      <Navbar />
    </aside>
  );
};

export default Sidebar;
