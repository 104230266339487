import { AxiosError } from "axios";
import { QueryClient } from "@tanstack/react-query";
import { QueryMeta, MutationMeta } from "@interfaces";

import "@tanstack/react-query";

declare module "@tanstack/react-query" {
  interface Register {
    queryMeta: QueryMeta;
    defaultError: AxiosError;
    mutationMeta: MutationMeta;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      gcTime: 25 * 60 * 1000, // 25 min.
      staleTime: 10 * 60 * 1000, // 10 min.
      refetchOnWindowFocus: false,
    },
  },
});

export default queryClient;
