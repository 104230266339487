import clsx from "clsx";
import React from "react";
import { Icon } from "@nubeteck/icons";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import { HeaderIcon } from "@nubeteck/prime";
import { OverlayPanel } from "primereact/overlaypanel";

const AppsMenuOverlay = () => {
  const overlayPanel = React.useRef<OverlayPanel>(null);

  const apps = [{ id: "app", title: "App", iconName: "plus" }];

  return (
    <>
      <HeaderIcon
        name="grid-dots"
        title="Aplicaciones"
        onClick={(e) => overlayPanel.current?.toggle(e)}
      />
      <OverlayPanel ref={overlayPanel}>
        <Divider className="m-0">
          <span className="font-semibold text-sm">Aplicaciones</span>
        </Divider>
        {apps.length ? (
          <div className={clsx("grid m-0 p-0 w-18rem h-16rem overflow-auto")}>
            {[{ id: "app", title: "App", iconName: "plus" }].map((app) => {
              return (
                <div key={app.id} className="col-4 p-1 m-0 cursor-pointer">
                  <a className="p-ripple flex flex-column text-center border-round p-3 hover:surface-100">
                    <Icon size={22} name={app.iconName ?? "apps"} />
                    <span className="text-xs font-medium">{app.title}</span>
                    <Ripple />
                  </a>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex flex-column text-sm align-items-center justify-content-center m-0 p-0 w-18rem h-10rem">
            <Icon size={26} touchable={false} name="database-off" />
            No hay aplicaciones disponibles
          </div>
        )}
      </OverlayPanel>
    </>
  );
};

export default AppsMenuOverlay;
