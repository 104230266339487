import { ISvgIconProps } from "../../../interfaces";

const PhotoSvg = ({ width, height }: ISvgIconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <radialGradient
        r="22.942"
        cx="48.477"
        cy="36.475"
        id="nw2JPvgEzDKQpjWgMaZM5a"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bd8af5" />
        <stop offset=".137" stopColor="#b88bf5" />
        <stop offset=".309" stopColor="#a88ff3" />
        <stop offset=".499" stopColor="#8f96f2" />
        <stop offset=".702" stopColor="#6b9eef" />
        <stop offset=".913" stopColor="#3eaaec" />
        <stop offset="1" stopColor="#29afea" />
      </radialGradient>
      <path
        fill="url(#nw2JPvgEzDKQpjWgMaZM5a)"
        d="M40,6H8C6.895,6,6,6.895,6,8v32c0,1.105,0.895,2,2,2h32c1.105,0,2-0.895,2-2V8	C42,6.895,41.105,6,40,6z"
      />
      <path
        fill="#436dcd"
        d="M32.065,23.065c-1.149-1.149-3.005-1.174-4.185-0.057L18,32.368V42h22c1.105,0,2-0.895,2-2v-7	L32.065,23.065z"
      />
      <circle r="3.5" cx="30.5" cy="14.5" fill="#fff" />
      <linearGradient
        x1="23.91"
        x2="23.91"
        y1="18.133"
        y2="42.415"
        id="nw2JPvgEzDKQpjWgMaZM5b"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#124787" />
        <stop offset=".923" stopColor="#173b75" />
        <stop offset="1" stopColor="#173a73" />
      </linearGradient>
      <path
        fill="url(#nw2JPvgEzDKQpjWgMaZM5b)"
        d="M8,42h32c0.811,0,1.507-0.485,1.82-1.18L20.065,19.065c-1.149-1.149-3.005-1.174-4.185-0.057	L6,28.368V40C6,41.105,6.895,42,8,42z"
      />
    </svg>
  );
};

export default PhotoSvg;
