import { Icon } from "@nubeteck/icons";
import { Button, ButtonProps } from "primereact/button";

type CancelButton = Omit<
  ButtonProps,
  "icon" | "title" | "label" | "outlined" | "severity"
>;

const CancelButton = ({ ...props }: CancelButton) => {
  return (
    <Button
      {...props}
      outlined
      size="small"
      label="Cancelar"
      severity="secondary"
      title="Restablecer y volver a la vista principal"
      icon={<Icon size={20} className="md:mr-1" name="corner-up-left-double" />}
    />
  );
};

export default CancelButton;
