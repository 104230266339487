import { IExchangeRates } from "@interfaces";

/**
 * Finds the exchange rate for a given currency ID.
 * @param {number} monedaId - The ID of the currency to find the exchange rate for.
 * @param {IExchangeRates[]} rates - An array of exchange rates.
 * @returns {IExchangeRates | undefined} The matching exchange rate object, or undefined if not found.
 */
export const findExchangeRate = (
  monedaId: number,
  rates?: IExchangeRates[],
): undefined | IExchangeRates => rates?.find((er) => er.MonedaId === monedaId);
