import React from "react";
import { Datejs } from "@nubeteck/utils";
import { Dialog } from "@nubeteck/prime";
import { Button } from "primereact/button";
import { useParams } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { DialogProps } from "primereact/dialog";
import { useQueryClient } from "@tanstack/react-query";
import { PickNullable, IExchangeRates } from "@interfaces";
import { Form, useForm, Calendar, InputNumber } from "@nubeteck/forms";

import { useLedgerQuery, useLedgerMutation } from "../../hooks";

export interface IExchangeRatesDialogProps extends DialogProps {
  exchangeRateId?: number;
}

type FormValues = PickNullable<
  IExchangeRates,
  | "Fecha"
  | "MonedaId"
  | "EstadoId"
  | "PrecioVenta"
  | "PrecioCompra"
  | "TasaCambioId"
>;

const model = {
  from: (data: IExchangeRates): FormValues => {
    return {
      MonedaId: data.MonedaId,
      EstadoId: data.EstadoId,
      PrecioVenta: data.PrecioVenta,
      PrecioCompra: data.PrecioCompra,
      Fecha: Datejs(data.Fecha).toDate(),
      TasaCambioId: data.TasaCambioId ?? 0,
    };
  },
  to: (data: FormValues): IExchangeRates => {
    return {
      MonedaId: data.MonedaId ?? 0,
      EstadoId: data.EstadoId ?? 1,
      Fecha: Datejs(data.Fecha).toDate(),
      PrecioVenta: data.PrecioVenta ?? 0,
      TasaCambioId: data.TasaCambioId ?? 0,
      PrecioCompra: data.PrecioCompra ?? 0,
    };
  },
};

const ExchangeRatesDialog = ({
  exchangeRateId,
  ...props
}: IExchangeRatesDialogProps) => {
  const queryClient = useQueryClient();

  const { id } = useParams();

  const currencyId = parseInt(`${id}`, 10);

  const getDollarRate = useLedgerQuery("ExchangeRates", "getDollarRate");
  const createExchangeRate = useLedgerMutation("ExchangeRates", "create");
  const updateExchangeRate = useLedgerMutation("ExchangeRates", "update");
  const getCreateExchangeRate = useLedgerQuery("ExchangeRates", "getCreate");

  const { data: exchangeRate, isFetching: isExchangeRateFetching } =
    useLedgerQuery("ExchangeRates", "getById", exchangeRateId, {
      enabled: !!exchangeRateId,
    });

  const form = useForm<FormValues>({
    defaultValues: {
      EstadoId: 1,
      MonedaId: currencyId,
      Fecha: Datejs().toDate(),
      PrecioVenta: currencyId == 1 ? 1 : 0,
      PrecioCompra: currencyId == 1 ? 1 : 0,
    },
  });

  React.useEffect(() => {
    if (exchangeRate) {
      form.reset(model.from(exchangeRate), { keepDefaultValues: true });
    }
  }, [form, exchangeRate]);

  const onSubmit = (values: FormValues) => {
    if (exchangeRateId) {
      return updateExchangeRate.mutateAsync(model.to(values), {
        onSuccess: () => {
          form.reset();
          props.onHide();
          queryClient.invalidateQueries({ queryKey: ["Moneda"] });
        },
      });
    }

    return createExchangeRate.mutateAsync(model.to(values), {
      onSuccess: () => {
        form.reset();
        props.onHide();
        queryClient.invalidateQueries({ queryKey: ["Moneda"] });
      },
    });
  };
  const isDolar = getCreateExchangeRate.data?.MonedasSelect?.some(
    (moneda) =>
      moneda.MonedaNombre === "Dolar Americano" &&
      moneda.MonedaId == currencyId,
  );

  const handleObtenerTasa = React.useCallback(() => {
    if (isDolar) {
      form.setValue("PrecioCompra", getDollarRate.data?.dollarPurchase);
      form.setValue("PrecioVenta", getDollarRate.data?.dollarSelling);
    }
  }, [
    form,
    getDollarRate.data?.dollarPurchase,
    getDollarRate.data?.dollarSelling,
    isDolar,
  ]);

  return (
    <Dialog
      {...props}
      style={{ width: "50rem" }}
      subtitle="Rellena los datos del formulario"
      onHide={() => {
        if (exchangeRateId) form.reset();
        props.onHide();
      }}
      title={
        exchangeRateId
          ? `Editando tasa de cambio`
          : "Creando nueva tasa de cambio"
      }
      footer={(props) => (
        <div className="flex flex-1 justify-content-end gap-2">
          <Button
            size="small"
            label="Cancelar"
            severity="secondary"
            onClick={() => {
              form.reset();
              props.onHide();
            }}
          />
          <Button
            size="small"
            loading={isExchangeRateFetching}
            onClick={form.handleSubmit(onSubmit)}
            label={exchangeRateId ? "Guardar cambios" : "Crear tasa de cambio"}
          />
        </div>
      )}
    >
      {exchangeRateId && isExchangeRateFetching ? (
        <div>
          <Skeleton height="18rem" />
        </div>
      ) : (
        <Form form={form}>
          <Calendar
            name="Fecha"
            label="Fecha"
            baseZIndex={1102}
            dateFormat="dd/mm/yy"
            className="p-inputtext-sm"
            rules={{ required: "Este campo es necesario" }}
          />
          <Button
            size="small"
            type="button"
            severity="info"
            visible={isDolar}
            className="mb-2 w-auto"
            label="Obtener la tasa"
            onClick={handleObtenerTasa}
            loading={getDollarRate.isFetching}
            disabled={getDollarRate.isFetching}
          />
          <div className="flex gap-3">
            <InputNumber
              id="PrecioCompra"
              name="PrecioCompra"
              minFractionDigits={2}
              label="Tasas de compra"
              className="p-inputtext-sm"
              rules={{ required: "Este campo es necesario" }}
            />
            <InputNumber
              id="PrecioVenta"
              name="PrecioVenta"
              minFractionDigits={2}
              label="Tasas de venta"
              className="p-inputtext-sm"
              rules={{ required: "Este campo es necesario" }}
            />
          </div>
        </Form>
      )}
    </Dialog>
  );
};

export default ExchangeRatesDialog;
