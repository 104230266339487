import { matchesSearchText } from "@utils";

import { IAccountCatalogNode } from "../interfaces";

const filterNodes = (
  nodes: IAccountCatalogNode[],
  searchText: string,
): IAccountCatalogNode[] => {
  return nodes.reduce<IAccountCatalogNode[]>((filteredNodes, node) => {
    const filteredChildren = filterNodes(node.children, searchText);

    const EstadoNombre = node.data.EstadoId === 1 ? "Activo" : "Inactivo";

    const fields = [
      node.data.Cuenta,
      node.data.Nombre,
      node.data.Descripcion,
      EstadoNombre,
    ];

    const nodeMatchesSearch = fields.some((field) =>
      matchesSearchText(searchText, field),
    );

    if (nodeMatchesSearch || filteredChildren.length > 0) {
      filteredNodes.push({
        ...node,
        children: filteredChildren,
      });
    }

    return filteredNodes;
  }, []);
};

export default filterNodes;
