import React from "react";
import { IRouter } from "@interfaces";
import { RouteObject } from "react-router-dom";
import { AppRoutes, AppRouteNames } from "@core";
import { MainLayout, ErrorBoundary } from "@components";
import { HomePage, LoginPage, ForgotPasswordPage } from "@pages";
import {
  SettingsFeature,
  GeneralLedgerFeature,
  AccountsReceivableFeature,
} from "@features";

import NavigationContext from "./navigation-context";

const Provider = ({ children }: React.PropsWithChildren) => {
  const features = [
    SettingsFeature.Route,
    GeneralLedgerFeature.Route,
    AccountsReceivableFeature.Route,
  ];

  const routes: IRouter[] = [
    {
      sidebar: true,
      id: "app-root",
      title: "Inicio",
      iconName: "home",
      element: <MainLayout />,
      path: AppRoutes[AppRouteNames.HOME_PAGE].template(),
      children: [
        {
          path: "",
          index: true,
          id: "app-page",
          title: "Inicio",
          element: <HomePage />,
        },
        ...features,
      ],
    },
    {
      index: true,
      sidebar: false,
      id: "login-page",
      element: <LoginPage />,
      title: "Inicio de Sesión",
      path: AppRoutes[AppRouteNames.LOGIN_PAGE].template(),
    },
    {
      index: true,
      sidebar: false,
      id: "forgot-password-page",
      title: "Olvidar contraseña",
      element: <ForgotPasswordPage />,
      path: AppRoutes[AppRouteNames.FORGOT_PASSWORD_PAGE].template(),
    },
  ];

  const formatRoutes = React.useCallback(
    (route: IRouter, parentPath: string = "/"): Omit<RouteObject, "index"> => {
      const isRoot = route.id.includes("root");
      const path = `${parentPath}/${route.path}`.replace(/\/{2,}/gi, "/");

      const children = route.children?.length
        ? route.children.map((child) => formatRoutes(child, path))
        : undefined;

      return {
        ...route,
        path,
        children,
        errorElement: isRoot ? <ErrorBoundary /> : undefined,
        handle: {
          title: route.title,
          sidebar: route.sidebar,
        },
      };
    },
    [],
  );

  return (
    <NavigationContext.Provider
      value={{
        features,
        routes: routes.map((route) => formatRoutes(route, "/")),
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default Provider;
