import API from "@lib/api";
import {
  IFilter,
  Messages,
  IFilterArea,
  IFilterCreate,
  IFilterByArea,
  IFilterCondition,
} from "@interfaces";

import { BaseApi } from "./base-api";

export class FiltersApi extends BaseApi {
  protected static api = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof FiltersApi> = {
    create: {
      loading: "Creando un nuevo filtro...",
      success: "Filtro creado correctamente.",
      error: (error) => `Hubo un error al crear el filtro: ${error}`,
    },
    delete: {
      loading: "Eliminando filtro...",
      success: "Filtro eliminado correctamente.",
      error: (error) => `Hubo un error al eliminar el filtro: ${error}`,
    },
    update: {
      loading: "Actualizando filtro...",
      success: "Filtro actualizado correctamente.",
      error: (error) => `Hubo un error al actualizar el filtro: ${error}`,
    },
  };

  public static async getAll() {
    const filters = await this.api.get<IFilter[]>("GetAll");
    return filters.data;
  }

  public static async getAllByFilter(
    filterId: number,
  ): Promise<IFilterByArea[]> {
    const result = await this.api.get<IFilterByArea[]>(
      `FiltroPorArea/${filterId}`,
    );
    return result.data;
  }

  public static async getByArea(area: string) {
    const filters = await this.api.get<IFilterByArea[]>(`GetByArea/${area}`);
    return filters.data;
  }

  public static async getById(id: number) {
    const filter = await this.api.get<IFilter>(`GetById/${id}`);
    return filter.data;
  }

  public static async create(data: IFilter) {
    const filter = await this.api.post("Create", data);
    return filter.data;
  }

  public static async getCreate() {
    const result = await this.api.get<IFilterCreate>("GetForCreate");
    return result.data;
  }

  public static async update(data: Partial<IFilter>) {
    const filter = await this.api.put(`Update/${data.FiltroId}`, data);
    return filter.data;
  }

  public static async delete(id: number) {
    return await this.api.post("ChangeState", { EstadoId: 3, FiltroId: id });
  }

  public static async conditions() {
    const conditions = await this.api.get<IFilterCondition[]>(
      "GetAllFiltrosCondiciones",
    );
    return conditions.data;
  }

  public static async propertiesByArea(area: string): Promise<string[]> {
    const properties = await this.api.get(`GetPropertiesByArea/${area}`);
    return properties.data;
  }

  public static async getAreas(): Promise<IFilterArea[]> {
    const result = await this.api.get("GetAllFiltrosAreas");
    return result.data;
  }
}
