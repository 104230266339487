import React from "react";
import { InputTextarea } from "@nubeteck/forms";
import { TabView, TabPanel } from "primereact/tabview";
import { FileTable, IFileTableProps } from "@components";

import { TransactionDetailsFormTable } from "../transaction-details-form-table";

import { AccountingSummary } from "./footer";

type Props = {
  summary: AccountingSummary;
  fileProps: IFileTableProps;
  isFormLoading?: boolean;
};

const AccountingMovementsFormTabs = ({
  summary,
  fileProps,
  isFormLoading = false,
}: Props) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);

  return (
    <TabView
      activeIndex={activeTabIndex}
      panelContainerClassName="p-0"
      onTabChange={(e) => setActiveTabIndex(e.index)}
    >
      <TabPanel header="Detalles" contentClassName="overflow-hidden">
        <TransactionDetailsFormTable
          summary={summary}
          isFormLoading={isFormLoading}
        />
      </TabPanel>
      <TabPanel header="Observaciones">
        <InputTextarea
          label=""
          rows={5}
          name="Observaciones"
          fieldClassName="flex-1 my-2 mx-1"
          placeholder="Ingrese observaciones adicionales o notas importantes sobre este movimiento contable."
        />
      </TabPanel>
      <TabPanel header="Archivos adjuntos">
        <FileTable {...fileProps} />
      </TabPanel>
    </TabView>
  );
};

export default AccountingMovementsFormTabs;
