import clsx from "clsx";
import React from "react";
import { Reducers } from "@core";
import { StatusTag } from "@components";
import { EventUtils } from "@nubeteck/utils";
import { useParams } from "react-router-dom";
import { TreeNode } from "primereact/treenode";
import { ColumnProps } from "primereact/column";
import { CardTable, ConfirmDialog, confirmDialog } from "@nubeteck/prime";

import { filterNodes } from "../../utils";
import { IAccountCatalogNode } from "../../interfaces";
import { AccountCatalogFormDialog } from "../../components";
import { useLedgerQuery, useLedgerMutation } from "../../hooks";

enum AccountStatus {
  Active = 1,
  Inactive = 2,
}

const AccountCatalogSimplePage = () => {
  const { id } = useParams();
  const [searchText, setSearchText] = React.useState("");
  const [edit, setEdit] = React.useState(false);
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const accountCatalogs = useLedgerQuery("AccountCatalog", "getAll");
  const changeStatusCuentaDetalle = useLedgerMutation(
    "AccountCatalog",
    "changeStateDetalle",
  );

  const data = React.useMemo(() => {
    const selectedCatalog = (accountCatalogs.data || []).find(
      (catalogo) => catalogo.CatalogoId === Number(id),
    );

    if (!selectedCatalog || !selectedCatalog.CatalogosCuentasDetalles) {
      return [];
    }

    const mappedDetails: IAccountCatalogNode[] =
      selectedCatalog.CatalogosCuentasDetalles.map((detalle) => ({
        children: [],
        key: detalle.CuentaId,
        data: {
          CuentaId: detalle.CuentaId,
          EstadoId: detalle.EstadoId,
          Cuenta: detalle.Cuenta ?? "",
          Nombre: detalle.CuentaNombre,
          Descripcion: detalle.Descripcion,
          CuentaPadreId: detalle.CuentaPadreId,
        },
      }));

    const nodesMap = new Map<number, IAccountCatalogNode>(
      mappedDetails.map((node) => [node.key, node]),
    );

    const rootNodes: IAccountCatalogNode[] = [];

    mappedDetails.forEach((node) => {
      const parentKey = node.data.CuentaPadreId;

      if (
        parentKey !== null &&
        parentKey !== undefined &&
        nodesMap.has(parentKey)
      ) {
        const parentNode = nodesMap.get(parentKey);
        parentNode?.children.push(node);
      } else {
        rootNodes.push(node);
      }
    });

    return rootNodes;
  }, [accountCatalogs.data, id]);

  const columns = React.useMemo<ColumnProps[]>(() => {
    return [
      {
        expander: true,
        sortable: true,
        field: "Cuenta",
        header: "Cuenta",
      },
      {
        sortable: true,
        field: "Nombre",
        header: "Nombre",
      },
      {
        sortable: true,
        field: "Descripcion",
        header: "Descripción",
      },
      {
        header: "Estado",
        field: "EstadoId",
        body: ({ data }: { data: IAccountCatalogNode["data"] }) => {
          const EstadoNombre =
            data.EstadoId === AccountStatus.Active ? "Activo" : "Inactivo";
          return <StatusTag status={EstadoNombre} />;
        },
      },
    ];
  }, []);

  const tableActions = React.useCallback(
    (rowData: TreeNode) => {
      const isActive = rowData.data.EstadoId === AccountStatus.Active;

      const commonActions = [
        {
          icon: "plus",
          label: "Agregar cuenta",
          onClick: EventUtils.callEvent(dispatch, {
            type: "OPEN_DIALOG",
            payload: Number(rowData.key),
          }),
        },
      ];

      if (rowData.data.CuentaPadreId === null) {
        return commonActions;
      }

      return [
        ...commonActions,
        {
          icon: "pencil",
          label: "Editar",
          onClick: () => {
            setEdit(true);
            dispatch({
              type: "OPEN_DIALOG",
              payload: Number(rowData.key),
            });
          },
        },
        {
          icon: isActive ? "circle-x" : "plug",
          label: isActive ? "Anular" : "Activar",
          iconClassName: isActive ? "text-red-600" : "text-green-600",
          onClick: () => {
            confirmDialog({
              rejectLabel: "Cancelar",
              message: "Esta acción no podrá deshacerse.",
              acceptLabel: isActive ? "Anular" : "Activar",
              header: `¿Estás seguro(a) de ${isActive ? "anular" : "activar"} la cuenta ${rowData.data.Nombre}?`,
              acceptClassName: clsx(
                isActive ? "p-button-danger" : "p-button-primary",
                "p-button-sm",
              ),
              accept: () =>
                changeStatusCuentaDetalle.mutateAsync({
                  CuentaId: Number(rowData.data.CuentaId),
                  EstadoId: isActive
                    ? AccountStatus.Inactive
                    : AccountStatus.Active,
                }),
            });
          },
        },
      ];
    },
    [changeStatusCuentaDetalle],
  );

  return (
    <>
      <CardTable<TreeNode>
        type="tree"
        hideSelectFilter
        resizableColumns
        columns={columns}
        hideFilterSettingsIcon
        columnResizeMode="expand"
        tableActions={tableActions}
        loading={accountCatalogs.isPending}
        value={filterNodes(data, searchText)}
        onSearch={(value) => setSearchText(value)}
        onRefresh={() => accountCatalogs.refetch()}
        title={
          accountCatalogs.data?.find((x) => x.CatalogoId === Number(id))?.Nombre
        }
      />
      <AccountCatalogFormDialog
        edit={edit}
        open={state.open}
        cuentaPadreId={state.id}
        onClose={() => {
          setEdit(false);
          dispatch({ type: "CLOSE_DIALOG" });
        }}
      />
      <ConfirmDialog />
    </>
  );
};

export default AccountCatalogSimplePage;
