import { route } from "typesafe-react-router";

export enum AppRouteNames {
  HOME_PAGE = "HOME_PAGE",
  LOGIN_PAGE = "LOGIN_PAGE",
  FORGOT_PASSWORD_PAGE = "FORGOT_PASSWORD_PAGE",
}

export const AppRoutes = {
  [AppRouteNames.HOME_PAGE]: route(""),
  [AppRouteNames.LOGIN_PAGE]: route("login"),
  [AppRouteNames.FORGOT_PASSWORD_PAGE]: route("forgot-password"),
};
