import API from "@lib/api";
import { BaseApi } from "@api";
import { FakerUtils } from "@utils";
import { Messages } from "@interfaces";

export class DashboardApi extends BaseApi {
  protected static api = API({ namespace: "PanelControl" });
  protected static messages: Messages<typeof DashboardApi> = {};

  public static async getStats() {
    return FakerUtils.getDashboard().stats;
  }
}
