import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import {
  IAccountingMovement,
  IAccountingMovementForm,
  IAccountCatalogDetailSelect,
} from "../interfaces";

export class AccountingMovementsApi extends BaseApi {
  protected static readonly api = API({ namespace: "MovimientosContables" });
  protected static readonly filterApi = API({ namespace: "Filtro" });
  protected static readonly messages: Messages<typeof AccountingMovementsApi> =
    {
      create: {
        loading: "Creando un nuevo movimiento contable...",
        success: "Movimiento contable creado correctamente.",
        error: (error) =>
          `Hubo un error al crear el movimiento contable: ${error}`,
      },
      delete: {
        loading: "Eliminando movimiento contable...",
        success: "Movimiento contable eliminado correctamente.",
        error: (error) =>
          `Hubo un error al eliminar el movimiento contable: ${error}`,
      },
      update: {
        loading: "Actualizando movimiento contable...",
        success: "Movimiento contable actualizado correctamente.",
        error: (error) =>
          `Hubo un error al actualizar el movimiento contable: ${error}`,
      },
    };

  public static async getAll(): Promise<IAccountingMovement[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getAllByFilter(
    filterId: number,
  ): Promise<IAccountingMovement[]> {
    const result = await this.filterApi.get<IAccountingMovement[]>(
      `FiltroPorArea/${filterId}`,
    );
    return result.data;
  }

  public static async getById(id: number): Promise<IAccountingMovement> {
    const result = await this.api.get(`GetById/${id}`);
    return result.data;
  }

  public static async getByBorrador(): Promise<IAccountingMovement[]> {
    const result = await this.api.get("GetByBorrador");
    return result.data;
  }

  public static async getCreate(): Promise<IAccountingMovementForm> {
    const result = await this.api.get("Create");
    return result.data;
  }

  public static async getUpdate(id: number): Promise<IAccountingMovementForm> {
    const result = await this.api.get(`Update/${id}`);
    return result.data;
  }

  public static async getAccountCatalogDetailsSelect(
    id: number,
  ): Promise<IAccountCatalogDetailSelect[]> {
    const result = await this.api.get(`GetCatalogosCuentasDetalleSelect/${id}`);
    return result.data;
  }

  public static async create(
    data: IAccountingMovement,
  ): Promise<IAccountingMovement> {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async update(
    data: IAccountingMovement,
  ): Promise<IAccountingMovement> {
    const result = await this.api.put(`Update/${data.AsientoId}`, data);
    return result.data;
  }

  public static async delete(id: number): Promise<IAccountingMovement> {
    const result = await this.api.delete(`Delete/${id}`);
    return result.data;
  }
}
