import React from "react";
import { isArray } from "radash";
import { ICurrency } from "@interfaces";
import { ArrayUtils } from "@nubeteck/utils";
import { UseFormReturn } from "react-hook-form";
import { ColumnBodyOptions } from "primereact/column";
import { Card, CardTable, IDataTableColumnsProps } from "@nubeteck/prime";
import { DataTableSelectionSingleChangeEvent } from "primereact/datatable";
import {
  Dropdown,
  useWatch,
  InputText,
  InputNumber,
  useFieldArray,
  useFormContext,
} from "@nubeteck/forms";

import ExtraInfo from "../tabs/extra-info";
import { Defaults } from "../../../../core";
import TabsFooter, { AccountingSummary } from "../tabs/footer";
import {
  IAccountingMovement,
  IAccountingMovementDetail,
} from "../../../../interfaces";
import {
  DEBIT_KEY,
  CREDIT_KEY,
  useLedgerQuery,
  useAccountingMovementLogic,
} from "../../../../../general-ledger";

const MIN_DETAILS = 2;

type Props = {
  summary: AccountingSummary;
  isFormLoading?: boolean;
};

interface IAccountingInputNumberProps {
  isFormLoading: boolean;
  options: ColumnBodyOptions;
  fieldName: "Debito" | "Credito";
  selectedCurrency?: Partial<ICurrency>;
  rowData: IAccountingMovementDetail & { id: string };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<IAccountingMovement, any, undefined>;
  accountDirections: {
    [key: string]: typeof DEBIT_KEY | typeof CREDIT_KEY;
  };
}

const TransactionDetailsFormTable = ({
  summary,
  isFormLoading = false,
}: Props) => {
  const form = useFormContext<IAccountingMovement>();
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: "MovimientosContablesDetalles",
  });
  const [selectedDetail, setSelectedDetail] = React.useState<
    null | (IAccountingMovementDetail & { id: string })
  >(null);

  const selectedDetailIndex = React.useMemo(
    () => fields.findIndex((detail) => detail.id === selectedDetail?.id),
    [fields, selectedDetail],
  );
  const detailHasBeenSelected = selectedDetailIndex !== -1;

  const { getAccountDirections } = useAccountingMovementLogic(fields);
  const accountDirections = getAccountDirections(fields, form.watch);

  const catalogoId = useWatch({ name: "CatalogoId", control: form.control });
  const monedaId = useWatch({ name: "MonedaId", control: form.control });

  const accounts = useLedgerQuery(
    "AccountingMovements",
    "getAccountCatalogDetailsSelect",
    catalogoId,
    { enabled: !!catalogoId },
  );

  const currencies = useLedgerQuery("Currency", "getAll");

  const selectedCurrency = React.useMemo(() => {
    return currencies.data?.find((currency) => currency.MonedaId === monedaId);
  }, [currencies.data, monedaId]);

  const accountsOptions = React.useMemo(() => {
    if (!accounts.data?.length) return [];
    return ArrayUtils.selectLabelValue(
      accounts.data,
      "CuentaLabel",
      "CuentaId",
    );
  }, [accounts.data]);

  const addDetail = React.useCallback(() => {
    append(Defaults.defaultMovementDetail, {
      shouldFocus: false,
    });
  }, [append]);

  const removeDetails = React.useCallback(() => {
    if (fields.length > MIN_DETAILS) {
      const indexesToRemove = Array.from(
        { length: fields.length - MIN_DETAILS },
        (_, i) => i + MIN_DETAILS,
      );
      remove(indexesToRemove);
    }
  }, [fields.length, remove]);

  const AccountingInputNumber = React.useCallback(
    ({
      form,
      rowData,
      options,
      fieldName,
      isFormLoading,
      selectedCurrency,
      accountDirections,
    }: IAccountingInputNumberProps) => {
      const rowIndex = options.rowIndex;
      const accountId = form.watch(
        `MovimientosContablesDetalles.${rowIndex}.CuentaContableId`,
      );
      const oppositeFieldName = fieldName === "Debito" ? "Credito" : "Debito";
      const hasOppositeAmount =
        form.watch(
          `MovimientosContablesDetalles.${rowIndex}.${oppositeFieldName}`,
        ) > 0;

      const accountDirection = accountId
        ? accountDirections[accountId]
        : undefined;

      const isDisabled =
        (fieldName === "Debito"
          ? accountDirection === CREDIT_KEY
          : accountDirection === DEBIT_KEY) ||
        hasOppositeAmount ||
        isFormLoading;

      return (
        <InputNumber
          label={""}
          mode="currency"
          fieldClassName="m-0"
          minFractionDigits={2}
          className="p-inputtext-sm"
          disabledInput={isDisabled}
          onClick={() => setSelectedDetail(rowData)}
          locale={selectedCurrency?.Locale ?? "es-DO"}
          currency={selectedCurrency?.NombreISO ?? "DOP"}
          name={`MovimientosContablesDetalles.${options.rowIndex}.${fieldName}`}
        />
      );
    },
    [setSelectedDetail],
  );

  const columns: IDataTableColumnsProps<(typeof fields)[0]>[] = React.useMemo(
    () => [
      {
        field: "id",
        align: "center",
        selectionMode: "single",
      },
      {
        header: "#",
        align: "center",
        field: "AsientoId",
        body: (_rowData, options) => (
          <span className="font-bold">{options.rowIndex + 1}</span>
        ),
      },
      {
        header: "Cuenta",
        style: { width: "25%" },
        field: "CuentaContableId",
        body: (rowData, options) => (
          <Dropdown
            filter
            label={""}
            optionLabel="label"
            optionValue="value"
            fieldClassName="m-0"
            placeholder="Seleccione"
            className="p-inputtext-sm"
            filterInputAutoFocus={true}
            filterPlaceholder="Búsqueda..."
            options={isArray(accountsOptions) ? accountsOptions : []}
            name={`MovimientosContablesDetalles.${options.rowIndex}.CuentaContableId`}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedDetail(rowData);
            }}
            rules={{
              validate: (value: string) => {
                return !!value || "Cuenta Contable es obligatoria";
              },
            }}
          />
        ),
      },
      {
        field: "Debito",
        header: "Débito",
        body: (
          rowData: IAccountingMovementDetail & { id: string },
          options,
        ) => (
          <AccountingInputNumber
            form={form}
            rowData={rowData}
            options={options}
            fieldName="Debito"
            isFormLoading={isFormLoading}
            selectedCurrency={selectedCurrency}
            accountDirections={accountDirections}
          />
        ),
      },
      {
        field: "Credito",
        header: "Crédito",
        body: (
          rowData: IAccountingMovementDetail & { id: string },
          options,
        ) => (
          <AccountingInputNumber
            form={form}
            rowData={rowData}
            options={options}
            fieldName="Credito"
            isFormLoading={isFormLoading}
            selectedCurrency={selectedCurrency}
            accountDirections={accountDirections}
          />
        ),
      },
      {
        field: "Descripcion",
        header: "Descripción",
        style: { width: "30%" },
        body: (
          rowData: IAccountingMovementDetail & { id: string },
          options,
        ) => (
          <InputText
            label=""
            type="text"
            autoFocus={false}
            fieldClassName="m-0"
            className="p-inputtext-sm"
            placeholder="Describa el detalle"
            onClick={() => setSelectedDetail(rowData)}
            name={`MovimientosContablesDetalles.${options.rowIndex}.Descripcion`}
          />
        ),
      },
    ],
    [
      AccountingInputNumber,
      accountDirections,
      accountsOptions,
      form,
      isFormLoading,
      selectedCurrency,
    ],
  );

  const handleSelectionChange = React.useCallback(
    (e: DataTableSelectionSingleChangeEvent<typeof fields>) => {
      setSelectedDetail(e.value);
    },
    [setSelectedDetail],
  );

  return (
    <div className="flex flex-column gap-3">
      <CardTable
        type="data"
        dataKey="id"
        showGridlines
        value={fields}
        hideGlobalSearch
        hideSelectFilter
        hideBorderStyles
        scrollable={true}
        columns={columns}
        paginator={false}
        scrollHeight="20.5rem"
        hideFilterSettingsIcon
        selectionMode="single"
        metaKeySelection={false}
        selection={selectedDetail}
        onSelectionChange={handleSelectionChange}
        header={
          <TabsFooter
            summary={summary}
            addDetail={addDetail}
            removeDetails={removeDetails}
            showRemoveAll={fields.length > MIN_DETAILS}
          />
        }
        tableActions={(_rowData, options) => [
          {
            label: "",
            icon: "trash",
            iconClassName: "text-red-500",
            onClick: () => remove(options.rowIndex),
            disabled: options.rowIndex < MIN_DETAILS,
          },
        ]}
      />
      <Card
        hideContentPadding
        subtitle={
          !detailHasBeenSelected ? (
            "Seleccione un detalle para agregar información adicional"
          ) : (
            <span className="font-semibold">{`Información adicional #${selectedDetailIndex + 1}`}</span>
          )
        }
      >
        {detailHasBeenSelected && (
          <ExtraInfo
            key={selectedDetailIndex}
            index={selectedDetailIndex}
            isFormLoading={isFormLoading}
          />
        )}
      </Card>
    </div>
  );
};

export default TransactionDetailsFormTable;
