import React from "react";
import { Icon } from "@nubeteck/icons";
import { Card } from "@nubeteck/prime";

export interface IOverviewCardProps {
  /**
   * Title to display.
   */
  title: string;
  /**
   * Optional icon to display.
   */
  iconName?: string;
  /**
   * Optional description to display.
   */
  description?: string;
  /**
   * value to display.
   */
  value: React.ReactNode;
}

const OverviewCard = ({
  title,
  value,
  iconName,
  description,
}: IOverviewCardProps) => {
  return (
    <Card className="flex-1">
      <div className="flex justify-content-between align-items-center pb-1">
        <h3 className="text-lg font-medium m-0">{title}</h3>
        {iconName && <Icon name={iconName} touchable={false} />}
      </div>
      <div className="text-2xl font-bold">{value}</div>
      {description && <p className="m-0 text-700">{description}</p>}
    </Card>
  );
};

export default OverviewCard;
