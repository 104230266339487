import React from "react";
import { Reducers } from "@core";
import { Tag } from "primereact/tag";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { Button } from "primereact/button";
import { FiltersDrawer } from "@components";
import { FilterMatchMode } from "primereact/api";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import {
  CardTable,
  confirmDialog,
  ConfirmDialog,
  IDataTableColumnsProps,
} from "@nubeteck/prime";

import { IFinancialStatement } from "../../interfaces";
import { useLedgerQuery, useLedgerMutation } from "../../hooks";
import { FinancialStatementDivisionDrawer } from "../../components";

const AREA_FILTER_DEFAULT = "fcEstadoFinancierosBuscar";

const FinancialStatementDivisionPage = () => {
  const { data: filters } = useGlobalQuery(
    "Filters",
    "getByArea",
    AREA_FILTER_DEFAULT,
  );
  const deleteFinancialStatement = useLedgerMutation(
    "FinancialStatement",
    "delete",
  );

  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();

  const financialStatements = useLedgerQuery(
    "FinancialStatement",
    "getAllByFilter",
    filterSelected,
    { enabled: !!filterSelected },
  );

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const columns: IDataTableColumnsProps<IFinancialStatement>[] = [
    {
      header: "No.",
      sortable: true,
      field: "EstadoFinancieroId",
    },
    {
      filter: true,
      sortable: true,
      field: "Nombre",
      dataType: "text",
      header: "Nombre",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
    },
    {
      field: "TipoEstadoFinancieroNombre",
      header: "Tipo de estado financiero",
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: ({ EstadoNombre }: IFinancialStatement) => (
        <Tag>{EstadoNombre}</Tag>
      ),
    },
  ];

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  return (
    <>
      <CardTable<IFinancialStatement>
        type="data"
        columns={columns}
        hideSelectFilter={false}
        dataKey="EstadoFinancieroId"
        valueFilter={filterSelected}
        filterOptions={filtersOptions}
        value={financialStatements.data ?? []}
        loading={financialStatements.isPending}
        title="Divisiones de estados financieros"
        onRefresh={() => financialStatements.refetch()}
        emptyMessage="No hay divisiones de estados financieros"
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        headActions={[
          <Button
            size="small"
            label="Nuevo estado financiero"
            key="ButtonAddFinancialStateDivision"
            icon={<Icon size={20} name="plus" className="mr-1" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
        tableActions={(record) => [
          {
            icon: "pencil",
            label: "Editar",
            iconClassName: "text-primary",
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: record.EstadoFinancieroId,
            }),
          },
          {
            label: "Anular",
            icon: "circle-x",
            iconClassName: "text-red-500",
            onClick: () => {
              confirmDialog({
                reject: () => {},
                acceptLabel: "Anular",
                rejectLabel: "Cancelar",
                header: "Anular estado financiero",
                message:
                  "¿Está seguro de que desea anular este estado financiero?",
                accept: async () => {
                  await deleteFinancialStatement.mutateAsync(
                    record.EstadoFinancieroId ?? 0,
                  );
                },
              });
            },
          },
        ]}
      />
      <ConfirmDialog />
      <FinancialStatementDivisionDrawer
        id={state.id}
        open={state.open}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <FiltersDrawer
        area={AREA_FILTER_DEFAULT}
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
    </>
  );
};

export default FinancialStatementDivisionPage;
