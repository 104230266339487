import React from "react";
import { Statuses } from "@core";
import { Dialog } from "@nubeteck/prime";
import { PickNullable } from "@interfaces";
import { Button } from "primereact/button";
import { ArrayUtils } from "@nubeteck/utils";
import {
  Form,
  useForm,
  Dropdown,
  InputText,
  MultiSelect,
} from "@nubeteck/forms";

import { IAccountCatalogCreate } from "../../interfaces";
import { useLedgerQuery, useLedgerMutation } from "../../hooks";

interface IAccountCatalogListFormProps {
  open: boolean;
  cuentaId?: number;
  onClose?: () => void;
}

type FormValues = PickNullable<
  IAccountCatalogCreate,
  | "Nombre"
  | "EstadoId"
  | "CatalogoId"
  | "SucursalesIds"
  | "CatalogoTipoId"
  | "SucursalesSelect"
  | "CatalogosCuentasDetalles"
>;

const model = {
  from: (data: IAccountCatalogCreate): FormValues => {
    return {
      Nombre: data.Nombre,
      EstadoId: data.EstadoId,
      CatalogoId: data.CatalogoId,
      SucursalesIds: data.SucursalesIds,
      CatalogoTipoId: data.CatalogoTipoId,
    };
  },
  to: (data: FormValues): IAccountCatalogCreate => {
    return {
      EstadoId: 1,
      Nombre: data.Nombre || "",
      CatalogoId: data.CatalogoId ?? 0,
      SucursalesIds: data.SucursalesIds ?? [],
      CatalogoTipoId: data.CatalogoTipoId ?? 0,
    };
  },
};

const AccountCatalogListForm = ({
  open,
  onClose,
  cuentaId,
}: IAccountCatalogListFormProps) => {
  const form = useForm<FormValues>({
    defaultValues: {
      Nombre: "",
      SucursalesIds: [],
      CatalogoTipoId: undefined,
    },
  });
  const createCatalog = useLedgerMutation("AccountCatalog", "create");
  const updateCatalog = useLedgerMutation("AccountCatalog", "update");
  const getCreateAccountCatalog = useLedgerQuery("AccountCatalog", "getCreate");

  const { data: accountCatalogData, isFetching: isAccountCatalogFetching } =
    useLedgerQuery("AccountCatalog", "getById", cuentaId, {
      enabled: !!cuentaId,
    });

  const catalogTypeOptions = React.useMemo(() => {
    const catalogTypes =
      getCreateAccountCatalog.data?.CatalogosTiposSelect ?? [];
    return ArrayUtils.selectLabelValue(
      catalogTypes,
      "Nombre",
      "CatalogoTipoId",
    );
  }, [getCreateAccountCatalog.data?.CatalogosTiposSelect]);

  const sucursalOptions = React.useMemo(() => {
    const sucursales = getCreateAccountCatalog.data?.SucursalesSelect ?? [];
    return ArrayUtils.selectLabelValue(
      sucursales,
      "SucursalNombre",
      "SucursalId",
    );
  }, [getCreateAccountCatalog.data?.SucursalesSelect]);

  const resetFormUpdateValues = React.useCallback(() => {
    if (!cuentaId || !accountCatalogData) {
      form.reset(undefined, { keepDefaultValues: true });
      return;
    }

    const formData: Partial<FormValues> = {
      Nombre: accountCatalogData.Nombre,
      EstadoId: accountCatalogData.EstadoId,
      CatalogoId: accountCatalogData.CatalogoId,
      SucursalesIds: accountCatalogData.SucursalesIds,
      CatalogoTipoId: accountCatalogData.CatalogoTipoId ?? 0,
    };

    form.reset(formData, { keepDefaultValues: true });
  }, [cuentaId, accountCatalogData, form]);

  React.useEffect(() => {
    resetFormUpdateValues();
  }, [resetFormUpdateValues]);

  const handleOnClose = React.useCallback(() => {
    form.reset(undefined, {
      keepValues: false,
      keepDefaultValues: true,
    });
    onClose?.();
  }, [form, onClose]);

  const onSubmit = (values: FormValues) => {
    const isCreating = cuentaId === 0;
    const mutationFn = isCreating ? createCatalog : updateCatalog;

    const transformedValues = model.to(values);

    return mutationFn.mutateAsync(
      {
        ...transformedValues,
        EstadoId: isCreating ? Statuses.ACTIVE_CODE : (values.EstadoId ?? 0),
      },
      {
        onSuccess: handleOnClose,
      },
    );
  };

  return (
    <Dialog
      visible={open}
      onHide={handleOnClose}
      contentClassName="pt-3 pb-0"
      className="w-11 max-w-26rem"
      header={
        cuentaId === 0
          ? "Crear catálogo de cuenta"
          : "Editar catálogo de cuenta"
      }
      footer={
        <Button
          size="small"
          loading={isAccountCatalogFetching}
          onClick={form.handleSubmit(onSubmit)}
        >
          Guardar
        </Button>
      }
    >
      <Form form={form}>
        <InputText
          name="Nombre"
          label="Nombre"
          className="p-inputtext-sm"
          placeholder="Catálogo general"
          rules={{ required: "Este campo es necesario" }}
        />
        <Dropdown
          label="Tipo"
          name="CatalogoTipoId"
          className="p-inputtext-sm"
          options={catalogTypeOptions}
          placeholder="Selecciona un tipo"
          loading={getCreateAccountCatalog.isFetching}
          rules={{ required: "Este campo es necesario" }}
        />
        <MultiSelect
          label="Sucursales"
          name="SucursalesIds"
          options={sucursalOptions}
          className="p-multiselect-sm"
          loading={getCreateAccountCatalog.isFetching}
          placeholder="Seleccione al menos una sucursal"
          rules={{ required: "Este campo es necesario" }}
        />
      </Form>
    </Dialog>
  );
};

export default AccountCatalogListForm;
