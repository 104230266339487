import { Image } from "primereact/image";
const EmptyMessage = ({
  message,
  customWidth,
}: {
  message: string;
  customWidth?: string;
}) => {
  return (
    <div className="flex justify-content-center align-items-center h-full flex-column gap-4 w-full py-5">
      <Image
        alt="no data"
        src="/svgs/undraw_no_data_re_kwbl.svg"
        width={customWidth ? customWidth : "128px"}
      />
      <span className="text-700 font-medium text-center">{message}</span>
    </div>
  );
};

export default EmptyMessage;
