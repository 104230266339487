/**
 * Represents the possible severity levels for status indicators.
 * Can be a predefined severity level or a PrimeFlex color class.
 */
export type Severity = "info" | string | "danger" | "success" | "warning";

/**
 * Maps status strings to their corresponding severity levels.
 *
 * To specify a custom color using PrimeFlex, use the format:
 * "bg-{color}-{shade}" for background colors, or
 * "text-{color}-{shade}" for text colors.
 *
 * Example: "bg-blue-500" for a blue background, or "text-red-700" for dark red text.
 */
const statusColors: Record<string, Severity> = {
  Borrador: "info",
  Activo: "success",
  Anulado: "danger",
  Inactivo: "bg-gray-500",
  Incompleto: "bg-gray-300",
  Eliminado: "bg-orange-500",
};

/**
 * Retrieves the severity level based on the given entity status.
 *
 * @param {string} [entity] - The status of the entity.
 * @returns {Severity} The corresponding severity level or PrimeFlex color class.
 */
export const getSeverity = (entity?: string): Severity => {
  if (!entity) return "info";
  return statusColors[entity] ?? "info";
};
