import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import {
  IEquivalence,
  IAccountCatalog,
  IAccountCatalogCreate,
  ICatalogoCuentaDetalles,
} from "../interfaces";

interface Data {
  CuentaId: number;
  CuentasIds: number[];
}

interface IChangeState {
  EstadoId: number;
  CatalogoId: number;
}

interface IChangeStateDetalle {
  EstadoId: number;
  CuentaId: number;
}

export class AccountCatalogApi extends BaseApi {
  protected static api = API({ namespace: "CatalogosCuentas" });
  protected static filterApi = API({ namespace: "Filtro" });
  protected static messages: Messages<typeof AccountCatalogApi> = {
    changeStateDetalle: {
      loading: "...",
      success: "Operación exitosa.",
      error: (error) => `Ha ocurrido un error: ${error}`,
    },
    create: {
      loading: "Creando catálogo de cuenta simple...",
      success: "Catálogo de cuentas simple creado correctamente.",
      error: (error) =>
        `Hubo un error al crear el catálogo de cuentas: ${error}`,
    },
    changeState: {
      loading: "Anulando el catálogo de cuentas simple...",
      success: "Catálogo de cuentas anulado correctamente.",
      error: (error) =>
        `Hubo un error al anular el catálogo de cuentas: ${error}`,
    },
    consolidar: {
      loading: "Consolidando catálogo de cuentas...",
      success: "Catálogo de cuentas consolidado correctamente.",
      error: (error) =>
        `Hubo un error al consolidar el catalogo de cuentas: ${error}`,
    },
    update: {
      loading: "Actualizando el catalogo de cuentas simple...",
      success: "Catálogo de cuentas actualizado correctamente.",
      error: (error) =>
        `Hubo un error al actualizar el catálogo de cuentas: ${error}`,
    },
    updateDetails: {
      loading: "Actualizando el detalle de cuentas simple...",
      success: "Detalle de cuentas actualizado correctamente.",
      error: (error) =>
        `Hubo un error al actualizar el detalle de la cuenta: ${error}`,
    },
    createDetails: {
      loading: "Creando catálogo de cuenta simple detalle...",
      success: "Catalogo de cuentas simple detalle creado correctamente.",
      error: (error) =>
        `Hubo un error al crear el catálogo de cuentas detalle: ${error}`,
    },
  };

  public static async getAllByFilter(filterId: number) {
    const result = await this.filterApi.get(`FiltroPorArea/${filterId}`);
    return result.data;
  }

  public static async getAll(): Promise<IAccountCatalogCreate[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getAllCatalog(): Promise<IAccountCatalog[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async create(data: IAccountCatalogCreate) {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async createDetails(data: ICatalogoCuentaDetalles) {
    const result = await this.api.post("AddCatalogoCuentaDetalle", data);
    return result.data;
  }

  public static async getCreate(): Promise<IAccountCatalogCreate> {
    const result = await this.api.get("Create");
    return result.data;
  }

  public static async getEquivalencias(): Promise<IEquivalence[]> {
    const result = await this.api.get("GetEquivalencias");
    return result.data;
  }

  public static async consolidar(data: Data) {
    const result = await this.api.post("Consolidar", {
      CuentaId: data.CuentaId,
      CuentasIds: data.CuentasIds,
    });
    return result.data;
  }

  public static async updateDetails(data: ICatalogoCuentaDetalles) {
    const result = await this.api.put(
      `UpdateCatalogoCuentaDetalle/${data.CuentaId}`,
      data,
    );
    return result.data;
  }

  public static async update(data: Partial<IAccountCatalogCreate>) {
    const result = await this.api.put(`Update/${data.CatalogoId}`, data);
    return result.data;
  }

  public static async updateEstado(data: Partial<IAccountCatalogCreate>) {
    const result = await this.api.put(
      `UpdateCatalogoCuentaDetalle/${data.CatalogoId}`,
      {
        Cuenta: data.Cuenta,
        EstadoId: data.EstadoId,
        CuentaNombre: data.CuentaNombre,
        Clasificacion: data.Clasificacion,
      },
    );
    return result.data;
  }

  public static async getUpdateDetalle(id: number) {
    const result = await this.api.get(`GetUpdateCatalogoCuentaDetalle/${id}`);
    return result.data;
  }

  public static async getCreateDetalle(id: number) {
    const result = await this.api.get(`GetCreateCatalogoCuentaDetalle/${id}`);
    return result.data;
  }

  public static async getById(id: number): Promise<IAccountCatalogCreate> {
    const result = await this.api.get(`Update/${id}`);
    return result.data;
  }

  public static async changeState(data: IChangeState) {
    const result = await this.api.post("ChangeStateCatalogoCuenta", {
      EstadoId: data.EstadoId,
      CatalogoId: data.CatalogoId,
    });
    return result.data;
  }

  public static async changeStateDetalle(data: IChangeStateDetalle) {
    const result = await this.api.post("ChangeStateCatalogoCuentaDetalle", {
      EstadoId: data.EstadoId,
      CuentaId: data.CuentaId,
    });
    return result.data;
  }
}
