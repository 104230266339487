import React from "react";
import { CurrencyCode } from "@core";
import { sum, select } from "radash";
import { FormatterUtils } from "@utils";
import { FieldArrayWithId } from "react-hook-form";

import {
  IMonthEndClosing,
  IAccountingMovement,
  IAccountingMovementDetail,
} from "../interfaces";

export const CREDIT_KEY = "credit";
export const DEBIT_KEY = "debit";

const useAccountingMovementLogic = (
  details: IAccountingMovementDetail[],
  currencyIso?: keyof typeof CurrencyCode,
) => {
  const openMonth: IMonthEndClosing = {
    MesId: 9,
    Anio: 2024,
    EstadoId: 27, // Abierto
    CierreMensualId: 1,
  };

  const validateAccountingDate = (value: Date): true | string => {
    const selectedMonth = value.getMonth() + 1; // JS months are 0-indexed
    const selectedYear = value.getFullYear();

    if (selectedMonth !== openMonth.MesId || selectedYear !== openMonth.Anio) {
      return "Debe seleccionar un mes abierto.";
    }

    return true;
  };

  const totalDebits = React.useMemo(
    () => sum(details, (item) => item.Debito),
    [details],
  );

  const totalCredits = React.useMemo(
    () => sum(details, (item) => item.Credito),
    [details],
  );

  const difference = React.useMemo(
    () => totalDebits - totalCredits,
    [totalCredits, totalDebits],
  );

  const accountingSummary = React.useMemo(
    () => ({
      difference,
      totalDebits,
      totalCredits,
      currencyIso: currencyIso ?? "DOP",
    }),
    [difference, totalDebits, totalCredits, currencyIso],
  );

  const accountingDiscrepancies = React.useMemo(
    () =>
      select(
        [
          {
            condition: () => difference !== 0,
            message: () =>
              `Los débitos y créditos no están balanceados. Diferencia actual: ${FormatterUtils.currency(difference, { currencyIso })}.`,
          },
          {
            condition: () => !details || details.length < 2,
            message: () => "Debe haber al menos dos movimientos contables.",
          },
          {
            condition: () => totalCredits <= 0,
            message: () => "El total de créditos debe ser mayor que cero.",
          },
          {
            condition: () => totalDebits <= 0,
            message: () => "El total de débitos debe ser mayor que cero.",
          },
        ],
        (check) => check.message(),
        (check) => check.condition(),
      ),
    [currencyIso, details, difference, totalCredits, totalDebits],
  );

  const getAccountDirections = React.useCallback(
    (
      detailFields: FieldArrayWithId<
        IAccountingMovement,
        "MovimientosContablesDetalles",
        "id"
      >[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      watch: any,
    ) => {
      const accountDirections: {
        [key: string]: typeof DEBIT_KEY | typeof CREDIT_KEY;
      } = {};

      detailFields.forEach((_field, index) => {
        const accountId = watch(
          `MovimientosContablesDetalles.${index}.CuentaContableId`,
        );
        const debit = watch(`MovimientosContablesDetalles.${index}.Debito`);
        const credit = watch(`MovimientosContablesDetalles.${index}.Credito`);

        if (accountId) {
          if (debit > 0) {
            accountDirections[accountId] = DEBIT_KEY;
          } else if (credit > 0) {
            accountDirections[accountId] = CREDIT_KEY;
          }
        }
      });

      return accountDirections;
    },
    [],
  );

  return {
    /**
     * Generates a summary of the accounting movements.
     */
    accountingSummary,

    /**
     * Retrieves the directions (debit or credit) for each account based on the provided detail fields.
     *
     * @param detailFields - Array of accounting movement detail fields.
     * @param watch - Function to watch the values of the form fields.
     * @returns An object mapping account IDs to their respective directions (debit or credit).
     */
    getAccountDirections,

    /**
     * Validates if the given date is within the open accounting month.
     *
     * @param value - The date to validate.
     * @returns True if valid, or an error message string if invalid.
     */
    validateAccountingDate,

    /**
     * Checks for discrepancies in the accounting movements and generates appropriate messages.
     */
    accountingDiscrepancies,
  };
};

export default useAccountingMovementLogic;
