import { IRouter } from "@interfaces";
import { Outlet } from "react-router-dom";

import { GeneralLedgerRoutes, GeneralLedgerRouteNames } from "./routes";
import {
  CurrencyPage,
  DashboardPage,
  AccountCatalogPage,
  CurrencyDetailPage,
  MonthlyClosingPage,
  ReconciliationPage,
  FinancialStatementPage,
  AccountingMovementsPage,
  AccountCatalogSimplePage,
  AccountingMovementsEditPage,
  AccountCatalogConsolidatePage,
  AccountingMovementsCreatePage,
  AccountingMovementsDetailsPage,
  FinancialStatementDivisionPage,
} from "./pages";

const router: IRouter = {
  sidebar: true,
  iconName: "book",
  element: <Outlet />,
  title: "Libro Mayor",
  id: "general-ledger-settings-root",
  path: GeneralLedgerRoutes[GeneralLedgerRouteNames.DASHBOARD_PAGE].template(),
  children: [
    {
      path: "",
      index: true,
      sidebar: true,
      iconName: "dashboard",
      title: "Panel de control",
      element: <DashboardPage />,
      id: "general-ledger-settings-page",
    },
    {
      sidebar: true,
      iconName: "clock-cancel",
      title: "Cierres mensuales",
      element: <MonthlyClosingPage />,
      id: "general-ledger-monthly-closing-page",
      path: GeneralLedgerRoutes[
        GeneralLedgerRouteNames.MONTHLY_CLOSING_PAGE
      ].template(),
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "calculator",
      title: "Catálogos de cuentas",
      id: "general-ledger-account-catalog-root",
      path: GeneralLedgerRoutes[
        GeneralLedgerRouteNames.ACCOUNT_CATALOG_PAGE
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: false,
          title: "Catálogos de cuentas",
          element: <AccountCatalogPage />,
          id: "general-ledger-account-catalog-page",
        },
        {
          sidebar: false,
          title: "Catálogo de cuentas (Simple)",
          element: <AccountCatalogSimplePage />,
          id: "general-ledger-account-catalog-simple-page",
          path: GeneralLedgerRoutes[
            GeneralLedgerRouteNames.ACCOUNT_CATALOG_SIMPLE_DETAILS_PAGE
          ].template(),
        },
        {
          sidebar: false,
          element: <AccountCatalogConsolidatePage />,
          title: "Catálogo de cuentas (Consolidado)",
          id: "general-ledger-account-catalog-consolidate-page",
          path: GeneralLedgerRoutes[
            GeneralLedgerRouteNames.ACCOUNT_CATALOG_CONSOLIDATE_DETAILS_PAGE
          ].template(),
        },
      ],
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "replace",
      title: "Movimientos contables",
      id: "general-ledger-accounting-movements-root",
      path: GeneralLedgerRoutes[
        GeneralLedgerRouteNames.ACCOUNTING_MOVEMENTS
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: false,
          title: "Movimientos contables",
          element: <AccountingMovementsPage />,
          id: "general-ledger-accounting-movements-page",
        },
        {
          sidebar: false,
          title: "Movimiento contable #{sec}",
          element: <AccountingMovementsDetailsPage />,
          id: "general-ledger-accounting-movements-details-page",
          path: GeneralLedgerRoutes[GeneralLedgerRouteNames.DETAILS].template(),
        },
        {
          sidebar: false,
          title: "Nuevo movimiento contable",
          element: <AccountingMovementsCreatePage />,
          id: "general-ledger-accounting-movements-create-page",
          path: GeneralLedgerRoutes[GeneralLedgerRouteNames.CREATE].template(),
        },
        {
          sidebar: false,
          element: <AccountingMovementsEditPage />,
          title: "Editando movimiento contable #{sec}",
          id: "general-ledger-accounting-movements-edit-page",
          path: GeneralLedgerRoutes[GeneralLedgerRouteNames.EDIT].template(),
        },
      ],
    },
    {
      sidebar: true,
      iconName: "abacus",
      title: "Reconciliación",
      element: <ReconciliationPage />,
      id: "general-ledger-reconciliation-page",
      path: GeneralLedgerRoutes[
        GeneralLedgerRouteNames.RECONCILIATION_PAGE
      ].template(),
    },
    {
      sidebar: true,
      iconName: "zoom-money",
      title: "Divisiones de estados financieros",
      element: <FinancialStatementDivisionPage />,
      id: "general-ledger-financial-statement-divisions-page",
      path: GeneralLedgerRoutes[
        GeneralLedgerRouteNames.FINANCIAL_STATEMENT_DIVISION_PAGE
      ].template(),
    },
    {
      sidebar: true,
      element: <Outlet />,
      iconName: "file-analytics",
      title: "Estados financieros",
      id: "general-ledger-financial-statement-root",
      path: GeneralLedgerRoutes[
        GeneralLedgerRouteNames.FINANCIAL_STATEMENT_PAGE
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: true,
          iconName: "file-analytics",
          title: "Estados de situación",
          element: <FinancialStatementPage />,
          id: "general-ledger-financial-statement-situation-page",
        },
        {
          sidebar: true,
          path: "/resultado",
          iconName: "file-analytics",
          title: "Estados de resultados",
          element: <FinancialStatementPage />,
          id: "general-ledger-financial-statement-result-page",
        },
      ],
    },
    {
      sidebar: true,
      iconName: "coin",
      title: "Monedas",
      element: <Outlet />,
      id: "general-ledger-currency-root",
      path: GeneralLedgerRoutes[
        GeneralLedgerRouteNames.CURRENCY_PAGE
      ].template(),
      children: [
        {
          path: "",
          index: true,
          sidebar: false,
          title: "Monedas",
          element: <CurrencyPage />,
          id: "general-ledger-currency-page",
        },
        {
          sidebar: false,
          title: "#{id}",
          element: <CurrencyDetailPage />,
          id: "general-ledger-currency-detail-page",
          path: GeneralLedgerRoutes[
            GeneralLedgerRouteNames.CURRENCY_DETAIL_PAGE
          ].template(),
        },
      ],
    },
  ],
};

export default router;
