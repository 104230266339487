import React from "react";
import millify from "millify";
import CountUp from "react-countup";
import { CurrencyCode } from "@core";
import { FormatterUtils } from "@utils";

import { OverviewCard, IOverviewCardProps } from "../overview-card";

export interface IStatsCardProps extends IOverviewCardProps {
  /**
   * Numeric value to display.
   */
  value: number;
  /**
   * Optional locale for formatting the value.
   *
   * @defaultValue "en-US"
   */
  locale?: string;
  /**
   * Optional number of decimal places for the value.
   */
  precision?: number;
  /**
   * Optional boolean to determine if the count should animate.
   *
   * @defaultValue true
   */
  animated?: boolean;
  /**
   * Optional boolean to determine if large numbers should be abbreviated.
   *
   * @defaultValue false
   */
  abbreviateNumbers?: boolean;
  /**
   * Optional ISO currency code for currency formatting.
   *
   * @defaultValue "USD"
   */
  currencyIso?: keyof typeof CurrencyCode;
  /**
   * Optional type of value to display: "number", "currency", or "percentage".
   *
   * @defaultValue "number"
   */
  type?: "number" | "currency" | "percentage";
}

const StatsCard = ({
  title,
  value,
  iconName,
  precision,
  description,
  type = "number",
  animated = true,
  locale = "en-US",
  currencyIso = "USD",
  abbreviateNumbers = false,
}: IStatsCardProps): React.JSX.Element => {
  const formattingFn = React.useMemo(() => {
    return (count: number) => {
      const formatter = FormatterUtils[type] || FormatterUtils.number;
      let formattedValue = formatter(count, { locale, precision, currencyIso });

      if (abbreviateNumbers) {
        if (type === "currency") {
          const numericValue = parseFloat(
            formattedValue.replace(/[^0-9.-]+/g, ""),
          );
          const millifiedValue = millify(numericValue, {
            precision: precision || 2,
          });
          formattedValue = formattedValue.replace(/[0-9.,]+/, millifiedValue);
        } else {
          formattedValue = millify(count, { precision: precision || 2 });
        }
      }

      return formattedValue;
    };
  }, [type, locale, precision, currencyIso, abbreviateNumbers]);

  const formatWithoutAbbreviation = React.useMemo(() => {
    return (count: number) => {
      const formatter = FormatterUtils[type] || FormatterUtils.number;
      return formatter(count, { locale, precision, currencyIso });
    };
  }, [type, locale, precision, currencyIso]);

  return (
    <OverviewCard
      title={title}
      iconName={iconName}
      description={description}
      value={
        <span title={formatWithoutAbbreviation(value)}>
          {animated ? (
            <CountUp end={value} duration={2} formattingFn={formattingFn} />
          ) : (
            formattingFn(value)
          )}
        </span>
      }
    />
  );
};

export default StatsCard;
