import { localeES } from "@core";
import { Toaster } from "sonner";
import { useNavigation } from "@hooks";
import { AuthProvider } from "@contexts";
import { Datejs } from "@nubeteck/utils";
import queryClient from "@lib/query-client";
import { IconsProvider } from "@nubeteck/icons";
import { QueryClientProvider } from "@tanstack/react-query";
import { addLocale, PrimeReactProvider } from "primereact/api";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  HeaderActionsProvider,
  addLocale as addLocaleNubeteck,
  PrimeReactProvider as NubeteckPrimeProvider,
} from "@nubeteck/prime";

import "react-splitter-layout/lib/index.css";

Datejs.locale("es");
addLocaleNubeteck("es", localeES); // nubeteck locale
addLocale("es", localeES); // primereact locale

const AppProviders = () => {
  const { routes } = useNavigation();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <NubeteckPrimeProvider value={{ ripple: true, locale: "es" }}>
          <PrimeReactProvider value={{ ripple: true, locale: "es" }}>
            <HeaderActionsProvider>
              <RouterProvider router={createBrowserRouter(routes)} />
            </HeaderActionsProvider>
            <Toaster richColors />
            <IconsProvider />
          </PrimeReactProvider>
        </NubeteckPrimeProvider>
      </AuthProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
};

export default AppProviders;
