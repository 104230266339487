import React from "react";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { useGlobalQuery } from "@hooks";
import { ICurrency } from "@interfaces";
import { Button } from "primereact/button";
import { FiltersDrawer } from "@components";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { ArrayUtils, EventUtils } from "@nubeteck/utils";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { useLedgerQuery } from "../../hooks";
import { CurrencyDialog } from "../../components";
import { GeneralLedgerRoutes, GeneralLedgerRouteNames } from "../../routes";

const CurrencyPage = () => {
  const navigate = useNavigate();

  const { data: filters } = useGlobalQuery("Filters", "getByArea", "Monedas");

  const [filterSelected, setFilterSelected] = React.useState<
    number | undefined
  >();

  const currencies = useLedgerQuery(
    "Currency",
    "getAllByFilter",
    filterSelected,
    { enabled: !!filterSelected },
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const [filterSettingsVisible, setFilterSettingsVisible] =
    React.useState(false);

  React.useEffect(() => {
    const defaultFilterId = filters?.find(
      (filter) => filter.EsPorDefecto,
    )?.FiltroId;

    setFilterSelected(defaultFilterId);
  }, [filters]);

  const columns: IDataTableColumnsProps<ICurrency>[] = [
    {
      filter: true,
      sortable: true,
      header: "Nombre",
      dataType: "text",
      field: "MonedaNombre",
      filterMatchMode: FilterMatchMode.STARTS_WITH,
    },
    {
      header: "ISO",
      sortable: true,
      field: "NombreISO",
    },
    {
      field: "Simbolo",
      header: "Símbolo",
    },
    {
      sortable: true,
      field: "Locale",
      header: "Locale",
    },
  ];

  const filtersOptions = React.useMemo(() => {
    if (!filters?.length) return [];
    return ArrayUtils.selectLabelValue(filters ?? [], "Nombre", "FiltroId");
  }, [filters]);

  return (
    <>
      <CardTable<ICurrency>
        type="data"
        title="Monedas"
        columns={columns}
        dataKey="MonedaId"
        hideSelectFilter={false}
        valueFilter={filterSelected}
        value={currencies.data ?? []}
        filterOptions={filtersOptions}
        loading={currencies.isPending}
        onRefresh={() => currencies.refetch()}
        emptyMessage="No hay monedas disponibles."
        onSelectFilterOption={(value) => setFilterSelected(value)}
        onClickFilterSettings={EventUtils.callEvent(
          setFilterSettingsVisible,
          true,
        )}
        headActions={[
          <Button
            size="small"
            key="ButtonNewRate"
            label="Nueva moneda"
            icon={<Icon size={20} name="plus" className="mr-1" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
        tableActions={(rowData) => [
          {
            icon: "pencil",
            label: "Editar",
            iconClassName: "text-primary",
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: rowData.MonedaId ?? 0,
            }),
          },
          {
            label: "Detalle",
            icon: "list-details",
            disabled: rowData.MonedaId == 1,
            iconClassName: "text-secondary",
            onClick: () => {
              navigate(
                `.${GeneralLedgerRoutes[
                  GeneralLedgerRouteNames.CURRENCY_DETAIL_PAGE
                ].create({ id: `${rowData.MonedaId}` })}`,
              );
            },
          },
        ]}
      />
      <CurrencyDialog
        visible={state.open}
        currencyId={state.id}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      <FiltersDrawer
        area="Monedas"
        visible={filterSettingsVisible}
        onHide={EventUtils.callEvent(setFilterSettingsVisible, false)}
      />
    </>
  );
};

export default CurrencyPage;
