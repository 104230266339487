import { Dropdown } from "@nubeteck/forms";
import { ArrayUtils } from "@nubeteck/utils";
import { TabView, TabPanel } from "primereact/tabview"; // Assuming you're using PrimeReact
import React from "react";

import { FakeData } from "../../../../core";

type Props = {
  index: number;
  isFormLoading?: boolean;
};

const ExtraInfo = ({ index, isFormLoading = false }: Props) => {
  const profitCentersOptions = React.useMemo(() => {
    if (!FakeData.profitCenters?.length) return [];

    return ArrayUtils.selectLabelValue(
      FakeData.profitCenters,
      "Nombre",
      "CentroGananciaId",
    );
  }, []);

  const costCentersOptions = React.useMemo(() => {
    if (!FakeData.costCenters?.length) return [];

    return ArrayUtils.selectLabelValue(
      FakeData.costCenters,
      "Nombre",
      "CentroCostoId",
    );
  }, []);

  const employeesOptions = React.useMemo(() => {
    if (!FakeData.employees?.length) return [];

    return ArrayUtils.selectLabelValue(
      FakeData.employees,
      "Nombre",
      "EmpleadoId",
    );
  }, []);

  const projectsOptions = React.useMemo(() => {
    if (!FakeData.projects?.length) return [];

    return ArrayUtils.selectLabelValue(
      FakeData.projects,
      "Nombre",
      "ProyectoId",
    );
  }, []);

  return (
    <TabView>
      <TabPanel header="Centro de ganancia">
        <Dropdown
          filterInputAutoFocus
          loading={isFormLoading}
          fieldClassName="w-16rem"
          placeholder="Seleccione"
          label="Centro de ganancia"
          className="p-inputtext-sm"
          options={profitCentersOptions}
          filter={profitCentersOptions.length > 5}
          name={`MovimientosContablesDetalles.${index}.CentroGananciaId`}
        />
      </TabPanel>
      <TabPanel header="Centro de costo">
        <Dropdown
          filterInputAutoFocus
          label="Centro de costo"
          loading={isFormLoading}
          placeholder="Seleccione"
          fieldClassName="w-16rem"
          className="p-inputtext-sm"
          options={costCentersOptions}
          filter={costCentersOptions.length > 5}
          name={`MovimientosContablesDetalles.${index}.CentroCostoId`}
        />
      </TabPanel>
      <TabPanel header="Empleado">
        <Dropdown
          label="Empleado"
          filterInputAutoFocus
          loading={isFormLoading}
          fieldClassName="w-16rem"
          placeholder="Seleccione"
          className="p-inputtext-sm"
          options={employeesOptions}
          filter={employeesOptions.length > 5}
          name={`MovimientosContablesDetalles.${index}.EmpleadoId`}
        />
      </TabPanel>
      <TabPanel header="Proyecto">
        <Dropdown
          label="Proyecto"
          filterInputAutoFocus
          loading={isFormLoading}
          placeholder="Seleccione"
          fieldClassName="w-16rem"
          options={projectsOptions}
          className="p-inputtext-sm"
          filter={projectsOptions.length > 5}
          name={`MovimientosContablesDetalles.${index}.ProyectoId`}
        />
      </TabPanel>
    </TabView>
  );
};

export default ExtraInfo;
