import React from "react";
import { Skeleton } from "primereact/skeleton";
import { Datejs, ArrayUtils } from "@nubeteck/utils";
import { StatusTag, EmptyMessage } from "@components";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import {
  useLedgerQuery,
  IReferenceDocument,
} from "../../../features/general-ledger";

interface IReferenceDocumentsTableProps {
  id: number;
}

const ReferenceDocumentsTable = ({ id }: IReferenceDocumentsTableProps) => {
  const { data: referenceDocuments, isPending: referenceDocumentsPending } =
    useLedgerQuery("ReferenceDocument", "getByAccountingMovementId", id);

  const documentTypeOptions = React.useMemo(() => {
    if (!referenceDocuments?.length) return [];

    return ArrayUtils.selectLabelValue(
      referenceDocuments,
      "TipoDocumentoNombre",
      "TipoDocumentoId",
    );
  }, [referenceDocuments]);

  const statusesOptions = React.useMemo(() => {
    if (!referenceDocuments?.length) return [];

    return ArrayUtils.selectLabelValue(
      referenceDocuments,
      "EstadoNombre",
      "EstadoId",
    );
  }, [referenceDocuments]);

  const columns = React.useMemo<IDataTableColumnsProps<IReferenceDocument>[]>(
    () => [
      {
        filter: true,
        sortable: true,
        dataType: "text",
        field: "Secuencia",
        header: "Secuencia",
        filterMatchMode: FilterMatchMode.STARTS_WITH,
        body: (rowData) => (
          <a
            target="_blank"
            rel="noreferrer"
            href={rowData.DocumentoEnlace}
            className="no-underline text-blue-500"
          >
            {rowData.Secuencia}
          </a>
        ),
      },
      {
        filter: true,
        sortable: true,
        dataType: "select",
        field: "TipoDocumentoId",
        header: "Tipo de documento",
        filterOperator: FilterOperator.AND,
        filterOptions: documentTypeOptions,
        filterMatchMode: FilterMatchMode.EQUALS,
        body: (rowData) => rowData.TipoDocumentoNombre,
      },
      {
        filter: true,
        sortable: true,
        field: "Fecha",
        header: "Fecha",
        dataType: "date",
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.DATE_IS,
        body: (rowData) => Datejs(rowData.Fecha).format("DD/MM/YYYY"),
      },
      {
        filter: true,
        sortable: true,
        field: "Notas",
        header: "Notas",
        dataType: "text",
        filterMatchMode: FilterMatchMode.STARTS_WITH,
      },
      {
        filter: true,
        sortable: true,
        header: "Estado",
        field: "EstadoId",
        dataType: "select",
        filterOptions: statusesOptions,
        filterOperator: FilterOperator.AND,
        filterMatchMode: FilterMatchMode.EQUALS,
        body: (rowData) => <StatusTag status={rowData.EstadoNombre} />,
      },
    ],
    [documentTypeOptions, statusesOptions],
  );

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay documentos de referencia" />;
  }, []);

  return (
    <>
      {referenceDocumentsPending && <Skeleton width="100%" height="20rem" />}
      {!referenceDocumentsPending && (
        <CardTable
          type="data"
          hideSelectFilter
          hideBorderStyles
          columns={columns}
          tableActions={[]}
          scrollable={true}
          paginatorLeft={true}
          removableSort={true}
          dataKey="DocumentoId"
          hideFilterSettingsIcon
          value={referenceDocuments}
          // title="Documentos de referencia"
          emptyMessage={renderEmptyMessage}
          rowsPerPageOptions={[4, 8, 16, 32, 64]}
          paginator={(referenceDocuments?.length ?? 0) > 4}
        />
      )}
    </>
  );
};

export default ReferenceDocumentsTable;
