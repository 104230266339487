import React from "react";
import { sort } from "radash";
import { Tag } from "primereact/tag";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { useLedgerQuery } from "../../hooks";
import { IMonthlyClosing } from "../../interfaces";

const MonthlyClosingPage = () => {
  const monthlyClosings = useLedgerQuery("MonthlyClosing", "getAll");

  const columns: IDataTableColumnsProps<IMonthlyClosing>[] = [
    {
      field: "Anio",
      header: "Año",
      sortable: true,
    },
    {
      field: "Mes",
      header: "Mes",
      sortable: true,
    },
    {
      header: "Estado",
      field: "EstadoNombre",
      body: ({ EstadoNombre }) => (
        <Tag severity={EstadoNombre == "Abierto" ? "success" : "danger"}>
          {EstadoNombre}
        </Tag>
      ),
    },
  ];

  return (
    <div>
      <CardTable<IMonthlyClosing>
        type="data"
        dataKey="id"
        hideGlobalSearch
        hideSelectFilter
        columns={columns}
        hideFilterSettingsIcon
        title="Cierres mensuales"
        loading={monthlyClosings.isPending}
        value={sort(monthlyClosings.data ?? [], (item) => item.Anio, true)}
        headActions={[
          <Calendar
            view="year"
            dateFormat="yy"
            key="CalendarInput"
            placeholder="Selecciona un año"
            className="p-inputtext-sm w-14rem"
          />,
          <Button
            size="small"
            label="Abrir mes"
            key="ButtonOpenMonth"
            icon={<Icon size={20} name="plus" />}
          />,
        ]}
        tableActions={(rowData) => [
          {
            label:
              rowData.EstadoNombre === "Abierto" ? "Cerrar mes" : "Reabrir mes",
            icon:
              rowData.EstadoNombre === "Abierto"
                ? "calendar-x"
                : "calendar-stats",
            iconClassName:
              rowData.EstadoNombre === "Abierto"
                ? "text-red-500"
                : "text-primary",
          },
        ]}
      />
      {/* <ConfirmDialog
        visible={visible}
        onHide={() => {
          if (!visible) return;
          setVisible(false);
          setNewRowData(undefined);
        }}
        header={
          newRowData === undefined
            ? "Abrir nuevo mes"
            : newRowData.estado === "Abierto"
              ? "Operación de cierre del mes"
              : "Operación de reabrir el mes"
        }
        message={
          newRowData === undefined
            ? "¿Esta seguro que desea abrir un nuevo mes?"
            : newRowData.estado === "Abierto"
              ? "¿Está seguro de que desea cerrar el mes?"
              : "¿Está seguro de que desea reabrir el mes?"
        }
      /> */}
    </div>
  );
};

export default MonthlyClosingPage;
