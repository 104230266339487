/**
 * Converts a date string to a Date object.
 *
 * @param {string} dateString - The date string to convert.
 * @returns {Date} A new Date object representing the input date string.
 *
 * @example
 * const date = convertToDate('2023-04-15');
 * console.log(date); // Sat Apr 15 2023 00:00:00 GMT+0000 (Coordinated Universal Time)
 */
export const convertToDate = (dateString?: Date | string): Date => {
  return new Date(dateString ?? "");
};
