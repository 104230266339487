import React from "react";
import { IRouter } from "@interfaces";
import { RouteObject } from "react-router-dom";

interface INavigationContextProps {
  features: IRouter[];
  routes: RouteObject[];
}

const NavigationContext = React.createContext<INavigationContextProps>({
  routes: [],
  features: [],
});

export default NavigationContext;
