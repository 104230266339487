import React from "react";
import ReactDOM from "react-dom/client";
import { NavigationProvider } from "@contexts";

import AppProviders from "./providers";

import "./index.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <NavigationProvider>
      <AppProviders />
    </NavigationProvider>
  </React.StrictMode>,
);
