import React from "react";

import {
  PdfSvg,
  WordSvg,
  ExcelSvg,
  PhotoSvg,
  FileTwoTone,
  FileTextTwoTone,
} from "./svg";

const SIZE = 35;

type IconName =
  | "pdf"
  | "doc"
  | "xls"
  | "jpg"
  | "png"
  | "svg"
  | "txt"
  | "docx"
  | "xlsx"
  | "jpeg"
  | "msword";

const iconComponents: Record<IconName, React.ReactElement> = {
  pdf: <PdfSvg width={SIZE} height={SIZE} />,
  doc: <WordSvg width={SIZE} height={SIZE} />,
  docx: <WordSvg width={SIZE} height={SIZE} />,
  xls: <ExcelSvg width={SIZE} height={SIZE} />,
  jpg: <PhotoSvg width={SIZE} height={SIZE} />,
  png: <PhotoSvg width={SIZE} height={SIZE} />,
  svg: <PhotoSvg width={SIZE} height={SIZE} />,
  xlsx: <ExcelSvg width={SIZE} height={SIZE} />,
  jpeg: <PhotoSvg width={SIZE} height={SIZE} />,
  msword: <WordSvg width={SIZE} height={SIZE} />,
  txt: <FileTextTwoTone width={SIZE} height={SIZE} />,
};

interface IIconFileProps {
  name: string;
}

const IconFile = ({ name }: IIconFileProps) => {
  const iconComponent = iconComponents[name as IconName];
  return iconComponent ?? <FileTwoTone width={SIZE} height={SIZE} />;
};

export default IconFile;
