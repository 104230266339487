import API from "@lib/api";
import { BaseApi } from "@api";
import { Messages } from "@interfaces";

import {
  IFinancialStatement,
  IFinancialStatementCreate,
  IFinancialStatementUpdate,
} from "../interfaces";

export class FinancialStatementApi extends BaseApi {
  protected static filterApi = API({ namespace: "Filtro" });
  protected static api = API({ namespace: "EstadosFinancieros" });
  protected static messages: Messages<typeof FinancialStatementApi> = {
    create: {
      loading: "Creando un nuevo estado financiero...",
      success: "Estado financiero creado correctamente.",
      error: (error) => `Hubo un error al crear el estado financiero: ${error}`,
    },
    delete: {
      loading: "Eliminando estado financiero...",
      success: "Estado financiero eliminado correctamente.",
      error: (error) =>
        `Hubo un error al eliminar el estado financiero: ${error}`,
    },
    update: {
      loading: "Actualizando estado financiero...",
      success: "Estado financiero actualizado correctamente.",
      error: (error) =>
        `Hubo un error al actualizar el estado financiero: ${error}`,
    },
  };

  public static async getAll(): Promise<IFinancialStatement[]> {
    const result = await this.api.get("GetAll");
    return result.data;
  }

  public static async getAllByFilter(filterId: number) {
    const result = await this.filterApi.get<IFinancialStatement[]>(
      `FiltroPorArea/${filterId}`,
    );
    return result.data;
  }

  public static async create(
    data: IFinancialStatement,
  ): Promise<IFinancialStatement> {
    const result = await this.api.post("Create", data);
    return result.data;
  }

  public static async getCreate(): Promise<IFinancialStatementCreate> {
    const result = await this.api.get("GetForCreate");
    return result.data;
  }

  public static async update(
    data: IFinancialStatement,
  ): Promise<IFinancialStatement> {
    const result = await this.api.put(
      `Update/${data.EstadoFinancieroId}`,
      data,
    );
    return result.data;
  }

  public static async getUpdate(
    id: number,
  ): Promise<IFinancialStatementUpdate> {
    const result = await this.api.get(`GetForUpdate/${id}`);
    return result.data;
  }

  public static async delete(id: number): Promise<IFinancialStatement> {
    const result = await this.api.delete(`Delete/${id}`);
    return result.data;
  }
}
