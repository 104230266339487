import React from "react";
import { EmptyMessage } from "@components";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { IAgeAnalysis } from "../../interfaces";

import { BarChart } from "./charts";

interface IAgeAnalysisPros {
  ageAnalysis: IAgeAnalysis;
}

const AgeAnalysis = ({ ageAnalysis }: IAgeAnalysisPros) => {
  const chartData = {
    labels: Object.keys(ageAnalysis.categorias),
    datasets: [
      {
        label: "Monto Total",
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        data: Object.values(ageAnalysis.categorias),
      },
    ],
  };

  const columns = React.useMemo<
    IDataTableColumnsProps<IAgeAnalysis["facturasAntiguas"][0]>[]
  >(
    () => [
      {
        sortable: true,
        header: "Factura",
        field: "numeroFactura",
      },
      {
        sortable: true,
        field: "cliente",
        header: "Cliente",
      },
      {
        sortable: true,
        field: "diasVencido",
        header: "Días vencido",
      },
      {
        sortable: true,
        field: "monto",
        header: "Monto",
      },
    ],
    [],
  );

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay facturas" />;
  }, []);

  return (
    <div className="flex gap-3 flex-column lg:flex-row">
      <BarChart data={chartData} title="Cuentas por antigüedad" />
      <CardTable<IAgeAnalysis["facturasAntiguas"][0]>
        rows={6}
        type="data"
        hideSelectFilter
        columns={columns}
        tableActions={[]}
        className="flex-1"
        removableSort={true}
        hideFilterSettingsIcon
        // loading={isPending}
        rowsPerPageOptions={[6, 10]}
        title="Facturas más antiguas"
        emptyMessage={renderEmptyMessage()}
        value={ageAnalysis.facturasAntiguas}
        paginator={ageAnalysis.facturasAntiguas.length > 6}
      />
    </div>
  );
};

export default AgeAnalysis;
