import React from "react";
import { IExchangeRates } from "@interfaces";
import { convertToDate, findExchangeRate } from "@utils";
import { FieldPath, FieldValues, UseFormReturn } from "react-hook-form";
/**
 * A custom hook to manage exchange rates in a form.
 * @template T
 * @param {number} monedaId - The ID of the currency.
 * @param {IExchangeRates[]} rates - An array of exchange rates.
 * @param {UseFormReturn<T>} form - The form object from react-hook-form.
 * @returns {{ getCurrentExchangeRate: () => void }} An object containing the getCurrentExchangeRate function.
 */
const useExchangeRate = <T extends FieldValues>(
  monedaId: number,
  rates?: IExchangeRates[],
  form?: UseFormReturn<T>,
): { getCurrentExchangeRate: () => void } => {
  /**
   * Updates the form with the current exchange rate.
   */
  const getCurrentExchangeRate = React.useCallback(() => {
    const currentExchangeRate = findExchangeRate(monedaId, rates);
    form?.setValue(
      "Tasa" as FieldPath<T>,
      currentExchangeRate?.PrecioVenta as never,
    );
    form?.setValue(
      "TasaCambioFecha" as FieldPath<T>,
      convertToDate(currentExchangeRate?.Fecha ?? "") as never,
    );
  }, [form, monedaId, rates]);

  React.useEffect(() => {
    getCurrentExchangeRate();
  }, [getCurrentExchangeRate]);

  return { getCurrentExchangeRate };
};

export default useExchangeRate;
