import { useMediaQuery } from "usehooks-ts";

const sizeMap = new Map([
  [320, undefined],
  [375, undefined],
  [425, undefined],
  [768, 500],
  [1024, 500],
  [1440, 500],
  [2560, 1000],
]);

/**
 * A custom hook that returns the secondary size based on the current viewport width.
 *
 * @returns {number | undefined} The secondary size corresponding to the current viewport width,
 *                               or undefined for smaller viewport sizes.
 *
 * @example
 * const secondarySize = useSecondarySize();
 * console.log(secondarySize); // Outputs: 400 (for a 1024px wide viewport)
 */
export const useSecondarySize = (): number | undefined => {
  const is320 = useMediaQuery("(min-width: 320px)");
  const is375 = useMediaQuery("(min-width: 375px)");
  const is425 = useMediaQuery("(min-width: 425px)");
  const is768 = useMediaQuery("(min-width: 768px)");
  const is1024 = useMediaQuery("(min-width: 1024px)");
  const is1440 = useMediaQuery("(min-width: 1440px)");
  const is2560 = useMediaQuery("(min-width: 2560px)");

  if (is2560) return sizeMap.get(2560);
  if (is1440) return sizeMap.get(1440);
  if (is1024) return sizeMap.get(1024);
  if (is768) return sizeMap.get(768);
  if (is425) return sizeMap.get(425);
  if (is375) return sizeMap.get(375);
  if (is320) return sizeMap.get(320);
  return sizeMap.get(320); // Default to smallest size
};
