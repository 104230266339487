import React from "react";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { EmptyMessage } from "@components";
import { DataTable } from "@nubeteck/prime";

interface EquivalenceTableItem {
  nombreCatalogoSimple: string;
  cuentaCatalogoSimple: string;
  codigoCatalogoConsolidado: number;
  nombreCatalogoConsolidado: string;
  cuentaCatalogoConsolidado: string;
  codigoCuentaCatalogoSimple: string;
  codigoCuentaCatalogoConsolidado: number;
}

interface EquivalenceTableDialogProps {
  visible: boolean;
  onHide: () => void;
  value: EquivalenceTableItem[];
}

const EquivalenceTableDialog: React.FC<EquivalenceTableDialogProps> = ({
  value,
  onHide,
  visible,
}) => {
  const columns: Array<{
    field: keyof EquivalenceTableItem;
    header: string;
  }> = [
    {
      header: "Catálogo consolidado",
      field: "nombreCatalogoConsolidado",
    },
    {
      header: "Código cuenta consolidado",
      field: "codigoCuentaCatalogoConsolidado",
    },
    {
      header: "Cuenta consolidado",
      field: "cuentaCatalogoConsolidado",
    },
    { header: "Catálogo simple", field: "nombreCatalogoSimple" },
    {
      header: "Código cuenta simple",
      field: "codigoCuentaCatalogoSimple",
    },
    {
      field: "cuentaCatalogoSimple",
      header: "Cuenta catálogo simple",
    },
  ];

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay datos de equivalencia disponibles." />;
  }, []);

  return (
    <Dialog
      onHide={onHide}
      className="w-11"
      visible={visible}
      headerClassName="p-3"
      contentClassName="p-0"
      style={{ minHeight: 300 }}
      header="Ventana de equivalencia"
    >
      <DataTable
        scrollable
        size="small"
        value={value}
        showGridlines
        sortOrder={1}
        sortMode="single"
        scrollHeight="30rem"
        rowGroupMode="rowspan"
        emptyMessage={renderEmptyMessage}
        sortField="codigoCatalogoConsolidado"
        groupRowsBy="nombreCatalogoConsolidado"
      >
        {columns.map(({ field, header }) => (
          <Column key={field} field={field} header={header} />
        ))}
      </DataTable>
    </Dialog>
  );
};

export default EquivalenceTableDialog;
