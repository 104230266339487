import clsx from "clsx";
import React from "react";
import { toast } from "sonner";
import { capitalize } from "radash";
import { Icon } from "@nubeteck/icons";
import { renderTooltip } from "@utils";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import SplitterLayout from "react-splitter-layout";
import { useHeaderActions } from "@nubeteck/prime";
import { ButtonGroup } from "primereact/buttongroup";
import { SplitButton } from "primereact/splitbutton";
import { Reducers, Statuses, LogEntities } from "@core";
import { useParams, useNavigate } from "react-router-dom";
import { useExchangeRate, useSecondarySize } from "@hooks";
import { Datejs, ArrayUtils, EventUtils } from "@nubeteck/utils";
import {
  Form,
  useForm,
  Calendar,
  Dropdown,
  useWatch,
  InputText,
  InputNumber,
} from "@nubeteck/forms";
import {
  FormRow,
  ResetButton,
  CancelButton,
  FormContainer,
  ChangeLogDialog,
  AttachmentButton,
  toastDiscrepanciesTemplate,
} from "@components";

import { AccountingMovementModel } from "../../../models";
import { IAccountingMovementForm } from "../../../interfaces";
import {
  useLedgerQuery,
  useLedgerMutation,
  useAccountingMovementLogic,
} from "../../../hooks";

import { AccountingMovementsFormTabs } from "./tabs";

const AccountingMovementsForm = () => {
  const navigate = useNavigate();
  const { dispatch } = useHeaderActions();
  const { id } = useParams();
  const secondarySize = useSecondarySize();
  const [disableTooltip, setDisableTooltip] = React.useState(false);
  const createFormValues = useLedgerQuery("AccountingMovements", "getCreate");
  const updateFormValues = useLedgerQuery(
    "AccountingMovements",
    "getUpdate",
    Number(id ?? 0),
    { enabled: !!id },
  );
  const { isPending: createPending, mutateAsync: createAccountingMovement } =
    useLedgerMutation("AccountingMovements", "create");
  const { isPending: updatePending, mutateAsync: updateAccountingMovement } =
    useLedgerMutation("AccountingMovements", "update");
  const { data: exchangeRates } = useLedgerQuery("ExchangeRates", "getAll");
  const initialValues = React.useMemo(() => {
    if (id || !createFormValues.data) return;
    return AccountingMovementModel.from?.(createFormValues.data) ?? undefined;
  }, [id, createFormValues.data]);
  const options = createFormValues.data?.SelectOptions;

  const [changeLogState, changeLogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    {
      id: 0,
      open: false,
    },
  );
  const [archivos, setArchivos] = React.useState<number[]>([7]); //temp: remove
  const form = useForm<Omit<IAccountingMovementForm, "SelectOptions">>({
    values: initialValues,
  });
  const details = useWatch({
    control: form.control,
    name: "MovimientosContablesDetalles",
  });

  const monedaId = useWatch({ name: "MonedaId", control: form.control });
  const sucursalId = useWatch({ name: "SucursalId", control: form.control });

  const selectedCurrency = React.useMemo(() => {
    return options?.MonedasSelect.find(
      (currency) => currency.MonedaId === monedaId,
    );
  }, [monedaId, options?.MonedasSelect]);

  useExchangeRate(monedaId, exchangeRates, form);
  const { accountingSummary, validateAccountingDate, accountingDiscrepancies } =
    useAccountingMovementLogic(details, selectedCurrency?.NombreISO as never);

  const isAccountingMovementPending = createPending || updatePending;

  const accountCatalogsOptions = React.useMemo(() => {
    if (!options?.CatalogosCuentasSelect?.length) return [];
    const filteredAccountCatalogs = options?.CatalogosCuentasSelect.filter(
      (item) => item.SucursalId === sucursalId,
    );

    return ArrayUtils.selectLabelValue(
      filteredAccountCatalogs,
      "Nombre",
      "CatalogoId",
    );
  }, [options?.CatalogosCuentasSelect, sucursalId]);

  const branchesOptions = React.useMemo(() => {
    if (!options?.SucursalesSelect?.length) return [];

    return ArrayUtils.selectLabelValue(
      options?.SucursalesSelect,
      "SucursalNombre",
      "SucursalId",
    );
  }, [options?.SucursalesSelect]);

  const currenciesOptions = React.useMemo(() => {
    if (!options?.MonedasSelect?.length) return [];

    return ArrayUtils.selectLabelValue(
      options?.MonedasSelect,
      "MonedaNombre",
      "MonedaId",
    );
  }, [options?.MonedasSelect]);

  const resetAccountIds = () => {
    const currentDetails = form.getValues("MovimientosContablesDetalles");

    currentDetails.forEach((_, index) => {
      form.setValue(
        `MovimientosContablesDetalles.${index}.CuentaContableId`,
        0,
        {
          shouldValidate: true,
        },
      );
    });
  };

  const resetFormWithUpdateValues = React.useCallback(() => {
    if (!updateFormValues.data) return;
    const updateValues = AccountingMovementModel.from?.(updateFormValues.data);
    if (!updateValues) return;
    form.reset(updateValues, { keepDefaultValues: true });
  }, [updateFormValues.data, form]);

  React.useEffect(() => {
    resetFormWithUpdateValues();
  }, [resetFormWithUpdateValues]);

  const onSubmit = React.useCallback(
    (values: IAccountingMovementForm, estadoId?: number) => {
      const modeledValues = AccountingMovementModel.to?.({
        ...values,
        EstadoId: estadoId ? estadoId : values.EstadoId,
        MovimientosContablesAdjuntos: archivos.map((file) => file),
      });
      if (!modeledValues) return;

      if (
        accountingDiscrepancies.length > 0 &&
        modeledValues.EstadoId !== Statuses.DRAFT_CODE
      ) {
        toast.error(
          toastDiscrepanciesTemplate(
            "El asiento no puede ser guardado:",
            accountingDiscrepancies,
          ),
          {
            duration: 5000,
            dismissible: true,
            closeButton: true,
            position: "top-right",
          },
        );
        return;
      }

      if (id) {
        return updateAccountingMovement(modeledValues);
      }

      return createAccountingMovement(modeledValues, {
        onSuccess: () => {
          form.reset(initialValues);
        },
      });
    },
    [
      archivos,
      accountingDiscrepancies,
      id,
      createAccountingMovement,
      updateAccountingMovement,
      form,
      initialValues,
    ],
  );

  React.useEffect(() => {
    const validateAndSubmit = async (submitFunction: () => void) => {
      const isValid = await form.trigger();
      if (!isValid) {
        toast.error("Algunos campos necesitan ser revisados", {
          duration: 1500,
          position: "top-center",
          style: { fontSize: 16 },
        });
        return;
      }
      submitFunction();
    };

    const handleSave = () =>
      validateAndSubmit(() =>
        form.handleSubmit((values) => onSubmit(values, Statuses.ACTIVE_CODE))(),
      );

    const handleSaveAsDraft = () =>
      validateAndSubmit(() =>
        form.handleSubmit((values) => onSubmit(values, Statuses.DRAFT_CODE))(),
      );

    const handleUpdate = () =>
      validateAndSubmit(() =>
        form.handleSubmit((values) => onSubmit(values))(),
      );

    const handleCancel = () => {
      if (id) {
        resetFormWithUpdateValues();
      } else {
        form.reset(initialValues);
      }
      navigate(-1);
    };

    dispatch({
      type: "SET_ACTIONS",
      payload: [
        <React.Fragment key="CHANGE_LOG_BUTTON">
          {renderTooltip("Historial de cambios", "changelog")}
          {id && (
            <Icon
              name="book"
              className="changelog align-self-center text-primary"
              onClick={EventUtils.callEvent(changeLogDispatch, {
                type: "OPEN_DIALOG",
                payload: Number(id ?? 0),
              })}
            />
          )}
        </React.Fragment>,
        <AttachmentButton key="ATTACHMENT_BUTTON" />,
        <ButtonGroup key="FORM_BUTTONS">
          <CancelButton onClick={handleCancel} />
          {!id && (
            <ResetButton
              accept={() => form.reset(initialValues)}
              className={clsx(!id && "border-noround-right")}
            />
          )}
          {!id ? (
            <SplitButton
              size="small"
              label="Guardar"
              key="SAVE_BUTTON"
              onClick={handleSave}
              className="custom-split"
              model={[
                {
                  command: handleSaveAsDraft,
                  label: "Guardar como borrador",
                  icon: <Icon size={20} name="notes" className="mr-2" />,
                },
              ]}
            />
          ) : (
            <Button
              size="small"
              label="Actualizar"
              key="UPDATE_BUTTON"
              onClick={handleUpdate}
            />
          )}
        </ButtonGroup>,
      ],
    });

    return () => dispatch({ type: "CLEAR_ACTIONS" });
  }, [
    dispatch,
    form,
    id,
    initialValues,
    navigate,
    onSubmit,
    resetFormWithUpdateValues,
  ]);

  React.useEffect(() => {
    dispatch({
      type: "SET_VARIABLES",
      payload: { sec: updateFormValues.data?.Secuencia ?? "" },
    });
    return () => dispatch({ type: "CLEAR_VARIABLES" });
  }, [dispatch, updateFormValues.data?.Secuencia]);

  return (
    <FormContainer>
      <Form form={form} className="flex-1 overflow-hidden">
        {renderTooltip(
          "Desliza para ver más",
          "layout-splitter",
          "mouse",
          disableTooltip,
        )}
        <SplitterLayout
          vertical
          secondaryInitialSize={secondarySize}
          customClassName="relative hide-scrollbar"
          onDragEnd={() => setDisableTooltip(false)}
          onDragStart={() => setDisableTooltip(true)}
        >
          <div className="flex flex-column">
            <FormRow>
              <Calendar
                name="Fecha"
                label="Fecha"
                mask="99/99/9999"
                showButtonBar={true}
                dateFormat="dd/mm/yy"
                className="p-inputtext-sm"
                // disabledInput={openMonth.isFetching || isAccountingMovementPending}
                disabledInput={isAccountingMovementPending}
                rules={{
                  validate: validateAccountingDate,
                  required: "Debe seleccionar una fecha",
                }}
              />
              <InputText
                name="Referencia"
                label="Referencia"
                className="p-inputtext-sm"
                fieldClassName="md:w-16rem"
                placeholder="Número o código de referencia"
                rules={{ required: "Este campo es obligatorio" }}
              />
              <Dropdown
                name="CatalogoId"
                fieldClassName="md:w-3"
                placeholder="Seleccione"
                className="p-inputtext-sm"
                label="Catálogo de cuentas"
                options={accountCatalogsOptions}
                filter={accountCatalogsOptions.length > 5}
                rules={{ required: "Este campo es obligatorio" }}
                loading={
                  createFormValues.isFetching || isAccountingMovementPending
                }
                onChange={(e) => {
                  form.setValue("CatalogoId", e.value);
                  resetAccountIds();
                }}
              />
              <div
                style={{ marginTop: 26 }}
                className="hidden xl:block mb-3 align-self-start flex-1"
              >
                <Message
                  className="justify-content-start h-full bg-primary-100 text-primary-700"
                  content={
                    <div className="flex align-items-center">
                      <Icon filled touchable={false} name="info-circle" />
                      <div className="ml-2 white-space-nowrap hidden xl:block ">
                        Desliza la barra de abajo para ver más
                      </div>
                    </div>
                  }
                />
              </div>
            </FormRow>
            <div>
              {!!id && (
                <FormRow>
                  <InputText
                    readOnly
                    label="Creado por"
                    name="CreadoPorNombre"
                    fieldClassName="flex-1"
                    className="p-inputtext-sm"
                    defaultValue={updateFormValues.data?.CreadoPorNombre}
                  />
                  <InputText
                    readOnly
                    name="FechaCreacion"
                    fieldClassName="flex-1"
                    label="Fecha de creación"
                    className="p-inputtext-sm"
                    defaultValue={capitalize(
                      Datejs(updateFormValues.data?.FechaCreacion).format(
                        "dddd, MMMM D, YYYY h:mm A",
                      ),
                    )}
                  />
                  <Dropdown
                    label="Sucursal"
                    name="SucursalId"
                    fieldClassName="flex-1"
                    placeholder="Seleccione"
                    options={branchesOptions}
                    className="p-inputtext-sm"
                    filter={branchesOptions.length > 5}
                    rules={{ required: "Este campo es obligatorio" }}
                    loading={
                      createFormValues.isFetching || isAccountingMovementPending
                    }
                  />
                </FormRow>
              )}
              <FormRow>
                {!id && (
                  <Dropdown
                    label="Sucursal"
                    name="SucursalId"
                    fieldClassName="flex-1"
                    placeholder="Seleccione"
                    options={branchesOptions}
                    className="p-inputtext-sm"
                    filter={branchesOptions.length > 5}
                    rules={{ required: "Este campo es obligatorio" }}
                    loading={
                      createFormValues.isFetching || isAccountingMovementPending
                    }
                  />
                )}
                <Dropdown
                  label="Moneda"
                  name="MonedaId"
                  fieldClassName="flex-1"
                  placeholder="Seleccione"
                  className="p-inputtext-sm"
                  options={currenciesOptions}
                  filter={currenciesOptions.length > 5}
                  rules={{ required: "Este campo es obligatorio" }}
                  loading={
                    createFormValues.isFetching || isAccountingMovementPending
                  }
                />
                <InputNumber
                  name="Tasa"
                  label="Tasa"
                  mode="currency"
                  disabledInput={true}
                  minFractionDigits={2}
                  fieldClassName="flex-1"
                  className="p-inputtext-sm"
                  locale={selectedCurrency?.Locale ?? "es-DO"}
                  currency={selectedCurrency?.NombreISO ?? "DOP"}
                  rules={{ required: "Este campo es obligatorio" }}
                />
                <Calendar
                  mask="99/99/9999"
                  showButtonBar={true}
                  disabledInput={true}
                  dateFormat="dd/mm/yy"
                  name="TasaCambioFecha"
                  fieldClassName="flex-1"
                  label="Fecha de la tasa"
                  className="p-inputtext-sm"
                  rules={{
                    required: "Debe seleccionar una fecha",
                  }}
                />
              </FormRow>
            </div>
          </div>
          <AccountingMovementsFormTabs
            summary={accountingSummary}
            isFormLoading={isAccountingMovementPending}
            fileProps={{ archivos: archivos, setArchivos: setArchivos }}
          />
        </SplitterLayout>
      </Form>
      <ChangeLogDialog
        entityId={changeLogState.id}
        visible={changeLogState.open}
        entityName={LogEntities.ACCOUNTING_MOVEMENT}
        onHide={EventUtils.callEvent(changeLogDispatch, {
          type: "CLOSE_DIALOG",
        })}
      />
    </FormContainer>
  );
};

export default AccountingMovementsForm;
