import { sleep } from "radash";
import { Messages } from "@interfaces";

import { BaseApi } from "./base-api";

export class AuthApi extends BaseApi {
  protected static messages: Messages<typeof AuthApi> = {
    login: {
      loading: "Iniciando sesión...",
      success: "Sesión iniciada correctamente.",
      error: (error) => `Hubo un error al iniciar sesión: ${error}`,
    },
    forgotPassword: {
      loading: "Enviando solicitud...",
      success: "Solicitud enviada correctamente.",
      error: (error) => `Hubo un error al enviar la solicitud: ${error}`,
    },
  };

  public static async login(_props: { email: string; password: string }) {
    await sleep(10000);
    return "login";
  }

  public static async forgotPassword(_props: { email: string }) {
    await sleep(2000);
    return "forgotPassword";
  }

  public static register() {}
}
