import React from "react";
import {
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart as LineChartRe,
} from "recharts";

export interface ILineChartProps {
  legendTitle?: string;
  data: { label: string; value: string | number; color?: string }[];
}

const LineChart = ({ data }: ILineChartProps) => {
  // const documentStyle = getComputedStyle(document.documentElement);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChartRe data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="label" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          dataKey="value"
          type="monotone"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
        {/* <Line dataKey="value" type="monotone" stroke="#82ca9d" /> */}
      </LineChartRe>
    </ResponsiveContainer>
  );
};

export default LineChart;
