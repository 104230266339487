import clsx from "clsx";
import React from "react";
import { select } from "radash";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { StatusTag } from "@components";
import { Button } from "primereact/button";
import { EventUtils } from "@nubeteck/utils";
import { useParams } from "react-router-dom";
import { TreeNode } from "primereact/treenode";
import { ColumnProps } from "primereact/column";
import { CardTable, ConfirmDialog, confirmDialog } from "@nubeteck/prime";

import { filterNodes } from "../../utils";
import { IAccountCatalogNode } from "../../interfaces";
import { useLedgerQuery, useLedgerMutation } from "../../hooks";
import {
  ConsolidateTableDialog,
  EquivalenceTableDialog,
  AccountCatalogFormDialog,
} from "../../components";

enum AccountStatus {
  Active = 1,
  Inactive = 2,
}

const AccountCatalogConsolidatePage = () => {
  const { id } = useParams();

  const [searchText, setSearchText] = React.useState("");
  const [selectedNodeKey, setSelectedNodeKey] = React.useState<null | string>();
  const [showEquivalence, setShowEquivalence] = React.useState(false);
  const [showConsolidate, setShowConsolidate] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [accountCatalogState, accountCatalogDispatch] = React.useReducer(
    Reducers.DialogReducer,
    {
      id: 0,
      open: false,
    },
  );

  const equivalencias = useLedgerQuery("AccountCatalog", "getEquivalencias");
  const accountCatalogs = useLedgerQuery("AccountCatalog", "getAllCatalog");
  const changeStatusCuentaDetalle = useLedgerMutation(
    "AccountCatalog",
    "changeStateDetalle",
  );

  const columns = React.useMemo<ColumnProps[]>(() => {
    return [
      {
        expander: true,
        sortable: true,
        field: "Cuenta",
        header: "Cuenta",
      },
      {
        sortable: true,
        field: "Nombre",
        header: "Nombre",
      },
      {
        sortable: true,
        field: "Descripcion",
        header: "Descripción",
      },
      {
        header: "Estado",
        field: "EstadoId",
        body: ({ data }: { data: IAccountCatalogNode["data"] }) => {
          const EstadoNombre =
            data.EstadoId === AccountStatus.Active ? "Activo" : "Inactivo";
          return <StatusTag status={EstadoNombre} />;
        },
      },
    ];
  }, []);

  const data = React.useMemo(() => {
    const targetCatalog = (accountCatalogs.data ?? []).find(
      (catalogo) => catalogo.CatalogoId === Number(id),
    );
    if (!targetCatalog || !targetCatalog.CatalogosCuentasDetalles) {
      return [];
    }

    const nodesMap = new Map();

    targetCatalog.CatalogosCuentasDetalles.forEach((detalle) => {
      const key = detalle.CuentaId ? detalle.CuentaId.toString() : "";

      nodesMap.set(key, {
        key,
        children: [],
        data: {
          Cuenta: detalle.Cuenta,
          CuentaId: detalle.CuentaId,
          EstadoId: detalle.EstadoId,
          Nombre: detalle.CuentaNombre,
          Descripcion: detalle.Descripcion,
          CuentaPadreId: detalle.CuentaPadreId,
        },
      });
    });

    const roots: IAccountCatalogNode[] = [];

    nodesMap.forEach((node) => {
      const parentId = node.data.CuentaPadreId?.toString();

      if (parentId && nodesMap.has(parentId)) {
        nodesMap.get(parentId).children.push(node);
      } else {
        roots.push(node);
      }
    });

    return roots;
  }, [accountCatalogs.data, id]);

  const filteredEquivalencias = select(
    equivalencias.data ?? [],
    (eq) => eq.CuentaContableHastaId,
    (eq) => eq.CuentaContableDesdeId === Number(selectedNodeKey),
  );

  const filteredValue = select(
    equivalencias.data ?? [],
    (item) => ({
      codigoCatalogoConsolidado: item.CatalogoId,
      cuentaCatalogoSimple: item.CuentaSimpleNombre,
      codigoCuentaCatalogoSimple: item.CuentaSimple,
      nombreCatalogoConsolidado: item.NombreCatalogo,
      nombreCatalogoSimple: item.NombreCatalogoSimple,
      cuentaCatalogoConsolidado: item.CuentaNombreDeConsolidado,
      codigoCuentaCatalogoConsolidado: item.CuentaDeConsolidado,
    }),
    (item) => item.CatalogoId === Number(id),
  );

  const tableActions = React.useCallback(
    (rowData: TreeNode) => {
      const isActive = rowData.data.EstadoId === AccountStatus.Active;

      const commonActions = [
        {
          icon: "plus",
          label: "Agregar cuenta",
          onClick: EventUtils.callEvent(accountCatalogDispatch, {
            type: "OPEN_DIALOG",
            payload: Number(rowData.key),
          }),
        },
      ];

      if (rowData.data.CuentaPadreId === null) {
        return commonActions;
      }

      return [
        ...commonActions,
        {
          icon: "pencil",
          label: "Editar",
          onClick: () => {
            setEdit(true);
            accountCatalogDispatch({
              type: "OPEN_DIALOG",
              payload: Number(rowData.key),
            });
          },
        },
        {
          icon: isActive ? "circle-x" : "plug",
          label: isActive ? "Anular" : "Activar",
          iconClassName: isActive ? "text-red-600" : "text-green-600",
          onClick: () => {
            confirmDialog({
              rejectLabel: "Cancelar",
              message: "Esta acción no podrá deshacerse.",
              acceptLabel: isActive ? "Anular" : "Activar",
              header: `¿Estás seguro(a) de ${isActive ? "anular" : "activar"} la cuenta ${rowData.data.Nombre}?`,
              acceptClassName: clsx(
                isActive ? "p-button-danger" : "p-button-primary",
                "p-button-sm",
              ),
              accept: () =>
                changeStatusCuentaDetalle.mutateAsync({
                  CuentaId: Number(rowData.data.CuentaId),
                  EstadoId: isActive
                    ? AccountStatus.Inactive
                    : AccountStatus.Active,
                }),
            });
          },
        },
      ];
    },
    [changeStatusCuentaDetalle],
  );

  return (
    <>
      <CardTable
        type="tree"
        hideSelectFilter
        columns={columns}
        hideFilterSettingsIcon
        selectionMode="single"
        tableActions={tableActions}
        selectionKeys={selectedNodeKey}
        value={filterNodes(data, searchText)}
        onSearch={(value) => setSearchText(value)}
        onSelectionChange={(e) => setSelectedNodeKey(String(e.value))}
        title={
          accountCatalogs.data?.find((item) => item.CatalogoId === Number(id))
            ?.Nombre
        }
        headActions={[
          <Button
            outlined
            size="small"
            key="BTN_EQUIVALENCE_WINDOW"
            label="Ventana de equivalencia"
            onClick={() => setShowEquivalence(true)}
            icon={<Icon size={20} className="mr-1" name="file-spreadsheet" />}
          />,
          <Button
            size="small"
            label="Consolidar"
            key="BTN_CONSOLIDATE"
            disabled={!selectedNodeKey}
            onClick={() => setShowConsolidate(true)}
            icon={<Icon size={20} name="stack" className="mr-1" />}
          />,
        ]}
      />
      <AccountCatalogFormDialog
        edit={edit}
        open={accountCatalogState.open}
        cuentaPadreId={accountCatalogState.id}
        onClose={() => {
          setEdit(false);
          accountCatalogDispatch({ type: "CLOSE_DIALOG" });
        }}
      />
      <ConsolidateTableDialog
        columns={columns}
        visible={showConsolidate}
        selectedNodeKey={selectedNodeKey}
        accountCatalogs={accountCatalogs.data ?? []}
        filteredEquivalencias={filteredEquivalencias}
        onHide={() => {
          setShowConsolidate(false);
          setSelectedNodeKey("");
        }}
      />
      <EquivalenceTableDialog
        value={filteredValue}
        visible={showEquivalence}
        onHide={() => setShowEquivalence(false)}
      />
      <ConfirmDialog />
    </>
  );
};

export default AccountCatalogConsolidatePage;
