import React from "react";
import { Card } from "@nubeteck/prime";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

const SettingsPage = () => {
  return (
    <Card
      title="Configuraciones"
      subtitle="Configuraciones generales de la aplicación"
    >
      <div className="grid formgrid p-fluid">
        <div className="field col-12">
          <label htmlFor="nickname1" className="font-medium text-900">
            Nombre
          </label>
          <InputText type="text" id="nickname1" className="p-inputtext-sm" />
        </div>
        <div className="field col-12 md:col-12">
          <label htmlFor="email1" className="font-medium text-900">
            Email
          </label>
          <InputText id="email1" type="text" className="p-inputtext-sm" />
        </div>
        <div className="field col-12">
          <label htmlFor="privacy1" className="font-medium text-900">
            Privacy
          </label>
          <div className="flex align-items-center">
            <Checkbox
              // checked={check1}
              checked
              inputId="privacy1"
              // onChange={(e) => setCheck1(e.checked)}
            />
            <span className="ml-2 text-900">Share my data with contacts</span>
          </div>
        </div>
        <div className="col-12">
          <Button size="small" label="Save Changes" className="w-auto mt-3" />
        </div>
      </div>
    </Card>
  );
};

export default SettingsPage;
