import React from "react";

import AuthContext from "./auth-context";

const Provider = ({ children }: React.PropsWithChildren) => {
  const logout = () => {};
  const verifyToken = () => {};

  return (
    <AuthContext.Provider
      value={{ logout, user: null, verifyToken, isLogged: false }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default Provider;
