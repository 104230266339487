import React from "react";
import { Reducers } from "@core";
import { IFile } from "@interfaces";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { EventUtils } from "@nubeteck/utils";
import { DataTable } from "primereact/datatable";
import { IconFile, EmptyMessage } from "@components";
import { ConfirmPopup } from "primereact/confirmpopup";
import { FakeData } from "ClientApp/features/general-ledger/core";

export interface IFileTableProps {
  archivos: number[];
  setArchivos: React.Dispatch<React.SetStateAction<number[]>>;
}
const FileTable = ({ archivos, setArchivos }: IFileTableProps) => {
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });
  const button = React.useRef<Button>(null);
  const filesData: IFile[] = FakeData.files.filter((file) =>
    archivos.includes(file.FileId ?? 0),
  );

  const renderFiles: IFile[] = React.useMemo(
    () =>
      filesData.map((item) => ({
        Data: item.Data,
        FileId: item.FileId,
        FileName: item.FileName,
        Checksum: item.Checksum,
        FileSize: item.FileSize,
        DataString: item.DataString,
        ContentType: item.ContentType,
      })),
    [filesData],
  );

  const accept = React.useCallback(() => {
    setArchivos((prevArchivos) =>
      prevArchivos.filter((item) => item !== state.id),
    );
  }, [setArchivos, state.id]);

  const renderEmptyMessage = React.useCallback(() => {
    return <EmptyMessage message="No hay archivos" />;
  }, []);

  return (
    <>
      <DataTable
        rows={8}
        size="small"
        showGridlines
        scrollable={true}
        value={renderFiles}
        paginatorLeft={true}
        style={{ minHeight: 300 }}
        paginator={renderFiles.length > 8}
        emptyMessage={renderEmptyMessage()}
        rowsPerPageOptions={[8, 16, 32, 64]}
      >
        <Column
          header="Archivo"
          body={(rowData: IFile) => (
            <div className="flex align-items-center gap-2">
              <IconFile name={rowData.ContentType?.split("/")[1]} />
              {rowData.FileName}
            </div>
          )}
        ></Column>
        <Column
          header="Peso"
          body={(rowData: IFile) => {
            const fileSizeInMB = (rowData.FileSize ?? 0) / (1024 * 1024); // bytes to MB
            return fileSizeInMB.toFixed(2) + " MB";
          }}
        ></Column>
        <Column
          header="Acciones"
          body={(rowData: IFile) => (
            <div className="flex gap-3">
              <Button
                text
                type="button"
                severity="info"
                label="Descargar"
                className="p-1 w-fit"
                onClick={() => {
                  // eslint-disable-next-line no-console
                  console.log(rowData.FileId);
                  // getOneFile.mutateAsync(rowData.FileId, {
                  //   onSuccess: (file) => {
                  //     const linkSource = file.Data as string;
                  //     const downloadLink = document.createElement("a");
                  //     downloadLink.href = linkSource;
                  //     downloadLink.target = "_blank";
                  //     downloadLink.download = file.FileName;
                  //     downloadLink.click();
                  //   },
                  // }),
                }}
              />
              <Button
                text
                ref={button}
                type="button"
                label="Eliminar"
                severity="danger"
                className="p-1 w-fit"
                onClick={EventUtils.callEvent(dispatch, {
                  type: "OPEN_DIALOG",
                  payload: rowData.FileId ?? 0,
                })}
              />
            </div>
          )}
        ></Column>
      </DataTable>
      <ConfirmPopup
        accept={accept}
        visible={state.open}
        target={button.current as never}
        message="¿Confirma eliminar este archivo?"
        icon={<Icon color="#e74c3c" name="exclamation-circle" />}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
    </>
  );
};

export default FileTable;
