import { sum, omit } from "radash";
import { convertToDate } from "@utils";
import { Datejs } from "@nubeteck/utils";

import { IAccountingMovementForm } from "../interfaces";

interface Model<I, C = I> {
  to?: (data: C) => I | null;
  from?: (data: I) => C | null;
}

const ModelAccountingMovement: Model<
  IAccountingMovementForm,
  IAccountingMovementForm
> = {
  from: (data) => {
    if (!data) return null;

    return omit(
      {
        ...data,
        Tasa: 1, // Temporal hasta que gpt resuelva
        SucursalId: 3, // Temporal hasta que gpt resuelva
        TasaCambioFecha: new Date(), // Temporal hasta que gpt resuelva
        Fecha: convertToDate(data.Fecha),
      },
      ["SelectOptions"],
    );
  },
  to: (data) => {
    if (!data) return null;

    const TotalDebitos = sum(
      data.MovimientosContablesDetalles,
      (item) => item.Debito,
    );

    const TotalCreditos = sum(
      data.MovimientosContablesDetalles,
      (item) => item.Credito,
    );

    return {
      TotalDebitos,
      TotalCreditos,
      Tasa: data.Tasa,
      Fecha: data.Fecha,
      EstadoId: data.EstadoId,
      MonedaId: data.MonedaId,
      AsientoId: data.AsientoId,
      SucursalId: data.SucursalId,
      Referencia: data.Referencia,
      Observaciones: data.Observaciones,
      MovimientosContablesAdjuntos: data.MovimientosContablesAdjuntos,
      TasaCambioFecha: Datejs(data.TasaCambioFecha).format("YYYY-MM-DD"),
      MovimientosContablesDetalles: data.MovimientosContablesDetalles.map(
        (item) => ({
          Debito: item.Debito ?? 0,
          AsientoId: item.AsientoId,
          Credito: item.Credito ?? 0,
          EmpleadoId: item.EmpleadoId,
          ProyectoId: item.ProyectoId,
          Descripcion: item.Descripcion,
          CentroCostoId: item.CentroCostoId,
          CentroGananciaId: item.CentroGananciaId,
          CuentaContableId: item.CuentaContableId,
          DetalleAsientoId: item.DetalleAsientoId,
        }),
      ),
    };
  },
};

export default ModelAccountingMovement;
