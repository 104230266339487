import { StatusTag } from "@components";
import { Datejs } from "@nubeteck/utils";
import { Description } from "@nubeteck/prime";
import { Skeleton } from "primereact/skeleton";

import { IAccountingMovement } from "../../interfaces";

interface IAccountingMovementDescriptionProps {
  isSkeletonLoading?: boolean;
  accountingMovement?: IAccountingMovement;
}

const AccountingMovementDescription = ({
  isSkeletonLoading,
  accountingMovement,
}: IAccountingMovementDescriptionProps) => {
  return (
    <>
      {isSkeletonLoading && <Skeleton width="100%" height="10.5rem" />}
      {!isSkeletonLoading && (
        <Description
          title=""
          columns={3}
          items={[
            {
              label: "Fecha",
              value: Datejs(accountingMovement?.Fecha).format("DD/MM/YYYY"),
            },
            {
              label: "Referencia",
              value: accountingMovement?.Referencia ?? "",
            },
            {
              label: "Estado",
              value: (
                <StatusTag
                  key="STATUS"
                  status={accountingMovement?.EstadoNombre ?? "Activo"}
                />
              ) as never,
            },
            {
              label: "Moneda",
              value: accountingMovement?.MonedaNombre ?? "",
            },
            {
              label: "Tasa",
              value: accountingMovement?.Tasa ?? 0,
            },
            {
              label: "Fecha de la tasa",
              value: Datejs(accountingMovement?.TasaCambioFecha).format(
                "DD/MM/YYYY",
              ),
            },
            {
              label: "Observaciones",
              value: accountingMovement?.Observaciones ?? "",
            },
          ]}
        />
      )}
    </>
  );
};

export default AccountingMovementDescription;
