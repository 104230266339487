import React from "react";
import { Reducers } from "@core";
import { FormatterUtils } from "@utils";
import { CardTable } from "@nubeteck/prime";
import { EventUtils } from "@nubeteck/utils";
import { TreeNode } from "primereact/treenode";
import { ColumnProps } from "primereact/column";

import { FinancialStatementDivisionDrawer } from "../../components";

const FinancialStatementPage = () => {
  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const value: TreeNode[] = [
    {
      key: "0",
      data: { total: 1250000, name: "Cuentas" },
      children: [
        {
          key: "0-0",
          data: { total: 850000, name: "Gastos" },
        },
        {
          key: "0-1",
          data: { total: 2100000, name: "Ingresos" },
        },
        {
          key: "0-2",
          data: { total: 1250000, name: "Utilidad neta" },
        },
      ],
    },
    {
      key: "1",
      data: { total: 850000, name: "Gastos" },
      children: [
        {
          key: "1-0",
          data: { total: 450000, name: "Gastos de sueldo" },
        },
        {
          key: "1-1",
          data: { total: 75000, name: "Gastos energía" },
        },
        {
          key: "1-2",
          data: { total: 275000, name: "Gastos generales y administrativos" },
        },
        {
          key: "1-3",
          data: { total: 50000, name: "Viáticos" },
        },
      ],
    },
    {
      key: "2",
      data: { total: 2100000, name: "Ingresos" },
      children: [
        {
          key: "2-0",
          data: { total: 150000, name: "Ingresos extraordinarios" },
        },
        {
          key: "2-1",
          data: { total: 1200000, name: "Ingresos por alquiler" },
        },
        {
          key: "2-2",
          data: { total: 750000, name: "Ingresos por comision" },
        },
      ],
    },
  ];

  const columns: ColumnProps[] = [
    {
      field: "name",
      expander: true,
      header: "Nombre",
    },
    {
      field: "total",
      header: "Total",
      body: (row) =>
        FormatterUtils.currency(row.data.total ?? 0, {
          currencyIso: "DOP",
        }),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <CardTable
        type="tree"
        value={value}
        headActions={[]}
        tableActions={[]}
        columns={columns}
        title="Estados financieros"
        // loading
        emptyMessage="No hay estados financieros"
        // onRefresh={}
      />
      <FinancialStatementDivisionDrawer
        id={state.id}
        open={state.open}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
      {/* <FiltersDrawer
        onHide={function (): void {
          throw new Error("Function not implemented.");
        }}
      /> */}
    </div>
  );
};

export default FinancialStatementPage;
