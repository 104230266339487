import React from "react";
import { Reducers } from "@core";
import { Icon } from "@nubeteck/icons";
import { Button } from "primereact/button";
import { IExchangeRates } from "@interfaces";
import { useParams } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import { Datejs, EventUtils } from "@nubeteck/utils";
import { CardTable, IDataTableColumnsProps } from "@nubeteck/prime";

import { ExchangeRatesDialog } from "..";
import { useLedgerQuery } from "../../hooks";

const ExchangeRatesTab = () => {
  const { id } = useParams();

  const currencyId = parseInt(`${id}`, 10);

  const exchangesRates = useLedgerQuery(
    "Currency",
    "exchangesRates",
    currencyId,
    { enabled: !!currencyId },
  );

  const [state, dispatch] = React.useReducer(Reducers.DialogReducer, {
    id: 0,
    open: false,
  });

  const columns: IDataTableColumnsProps<IExchangeRates>[] = [
    {
      sortable: true,
      header: "Código",
      field: "TasaCambioId",
    },
    {
      filter: true,
      sortable: true,
      dataType: "numeric",
      field: "PrecioCompra",
      header: "Tasa de compra",
      showFilterMenuOptions: false,
      filterMenuClassName: "custom",
      filterMatchMode: FilterMatchMode.EQUALS,
    },
    {
      filter: true,
      sortable: true,
      dataType: "numeric",
      field: "PrecioVenta",
      header: "Tasa de venta",
      showFilterMenuOptions: false,
      filterMenuClassName: "custom",
      filterMatchMode: FilterMatchMode.EQUALS,
    },
    {
      field: "Fecha",
      header: "Fecha",
      body: (data) => Datejs(data.Fecha).format("L"),
    },
  ];

  return (
    <>
      <CardTable<IExchangeRates>
        type="data"
        removableSort
        hideSelectFilter
        columns={columns}
        hideFilterSettingsIcon
        dataKey="TasaCambioId"
        title="Tasas de cambio"
        value={exchangesRates.data ?? []}
        loading={exchangesRates.isPending}
        onRefresh={() => exchangesRates.refetch()}
        emptyMessage="No hay tasas de cambio disponibles."
        tableActions={(rowData) => [
          {
            icon: "pencil",
            label: "Editar",
            iconClassName: "text-primary",
            onClick: EventUtils.callEvent(dispatch, {
              type: "OPEN_DIALOG",
              payload: rowData.TasaCambioId,
            }),
          },
        ]}
        headActions={[
          <Button
            size="small"
            key="ButtonNewRate"
            label="Nueva tasa de cambio"
            icon={<Icon size={20} name="plus" className="mr-1" />}
            onClick={EventUtils.callEvent(dispatch, {
              payload: 0,
              type: "OPEN_DIALOG",
            })}
          />,
        ]}
      />
      <ExchangeRatesDialog
        visible={state.open}
        exchangeRateId={state.id}
        onHide={EventUtils.callEvent(dispatch, { type: "CLOSE_DIALOG" })}
      />
    </>
  );
};

export default ExchangeRatesTab;
