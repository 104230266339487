import React from "react";
import { NavLink } from "@nubeteck/prime";
import { Outlet } from "react-router-dom";
import { Divider } from "primereact/divider";

import { SettingsRoutes, SettingsRouteNames } from "../../routes";

const SettingsLayout = () => {
  const items = [
    {
      icon: "settings",
      title: "Configuraciones",
      to: SettingsRoutes[SettingsRouteNames.SETTINGS].create({}),
    },
    {
      icon: "settings",
      title: "Plantillas",
      to: `.${SettingsRoutes[SettingsRouteNames.TEMPLATES].create({})}`,
    },
  ];

  return (
    <div className="flex flex-column lg:flex-row gap-2 flex-1">
      <div className="flex w-full flex-column lg:w-18rem surface-card border-round shadow-2 select-none">
        <Divider className="mb-0 mt-1 z-0">
          <span className="text-sm font-semibold text-color">
            Menu de configuraciones
          </span>
        </Divider>
        <ul className="list-none w-full p-2 m-0 overflow-hidden">
          {items.map((item) => (
            <NavLink {...item} items={[]} key={item.title} />
          ))}
        </ul>
      </div>
      <div className="flex flex-1">
        <Outlet />
      </div>
    </div>
  );
};

export default SettingsLayout;
