type DialogState = { id: number; open: boolean };

type DialogAction =
  | { type: "CLOSE_DIALOG" }
  | { type: "OPEN_DIALOG"; payload: number };

export const DialogReducer = (
  state: DialogState,
  action: DialogAction,
): DialogState => {
  if (action.type === "OPEN_DIALOG")
    return { ...state, open: true, id: action.payload };
  if (action.type === "CLOSE_DIALOG") return { id: 0, open: false };

  return state;
};
