import React from "react";
import { toast } from "sonner";

const useToast = () => {
  const [id, setId] = React.useState<string | number>("");

  const loading = (
    message: string = "Espere mientras se completa la acción...",
  ) => {
    const notificationId = toast.loading(message);
    if (id !== notificationId) setId(notificationId);
    return notificationId;
  };

  const success = (message: string) =>
    toast.success(message, {
      id,
      dismissible: true,
      closeButton: true,
      style: { fontSize: 14 },
    });

  const error = (message: string) =>
    toast.error(message, {
      id,
      dismissible: true,
      closeButton: true,
      style: { fontSize: 14 },
    });

  return { error, loading, success };
};

export default useToast;
